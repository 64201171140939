import React, { useState, useEffect, useContext } from 'react';
import searchFilter from "../../assets/images/search_filter.svg";
import searchButton from "../../assets/images/Search-Button.svg";
import { filterRoles, getAllPRole, getAllRole } from '../../services/roleService';
import { getUser, updatePUser } from '../../services/userService';
import Spinner from "react-bootstrap/Spinner";
import config from "../../Config.json"
import { ContextData } from "../../components/appsession";

import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";

const Userrolefiltertable = (props) => {
    const { user, accessDisabled } = props
    const [allActiveRole, setActiveallRole] = useState([]);
    const [userRadioCheck, setUserCheck] = useState([]);
    const [userData, setuserData] = useState({});
    const [userContextData, setuserContextData] = useContext(ContextData);
    const [userPermission, setuserPermission] = useState({})
    const [isDataLoaded, setisDataLoaded] = useState(false)
    const [isDisabled, setIsDisabled] = useState(null)


    useEffect(() => {
        if (userContextData?.roles?.rolespermission?.length > 0) {
            const permission = userContextData?.roles?.rolespermission.find(userpermission =>
                userpermission?.permissiondetail.code === "Users")
            setuserPermission(permission)
        }

    }, [userContextData?.roles])



    useEffect(async () => {
        if (Object.keys(user)?.length > 0) {
            setuserData(user)
            const allrole = await getAllPRole();
            const activerole = allrole.filter(actv => actv.is_active === true);
            setActiveallRole(activerole);
            //setallRole(allrole);
            setisDataLoaded(true)
        }

    }, [Object.keys(user)?.length]);

    const handleChangeRole = (event) => {
        const roleId = event.target.id.split("_")[1];
        setUserCheck(roleId);
    }

    const updateUserData = async (event) => {
        user.roles = [userRadioCheck];
        const updateuserResponse = await updatePUser(user);
        if (updateuserResponse) {
            console.log("running")
            if(userContextData?.id==user?.id){

                const userNewData = await getUser(user.id)
                setuserContextData(userNewData[0])
            }

            

            NotificationManager.success("Assigned role updated successfully", "Success");

        }
    }

    return (
        <>

            <div className="col-xl-4">
                <div className="card card_shadow">
                    <div className="card-body special_card_padding">
                        <div className="access_header">
                            <h2 className="header-title">Roles</h2>
                            {userPermission?.can_edit ? (
                                <button
                                    type="button"
                                    className="btn_blue_sm btn-blue ml_10 access_save"
                                    onClick={(e) => updateUserData(e)}

                                >
                                    SAVE
                                </button>
                            ) :
                                (
                                    <button
                                        type="button"
                                        className="btn_blue_sm btn-blue ml_10 access_save"

                                        disabled

                                    >
                                        SAVE
                                    </button>
                                )
                            }

                        </div>

                        <div className="table-responsive Assigned_Roles_scroll">
                            <table className="table table-striped mb-0">
                                {
                                    isDataLoaded ?
                                        <tbody>
                                            <tr>
                                                <th className="w-73" scope="row">Role Name</th>
                                                <th scope="row">Assigned</th>

                                            </tr>
                                            <>
                                                {allActiveRole.map((item, i) => (
                                                    <tr key={i}>
                                                        <th id={item.id} scope="row" key={i}>{item.name}</th>
                                                        <td>

                                                            <div className="round" onChange={(e) => handleChangeRole(e)}>
                                                                {accessDisabled ?
                                                                    <>
                                                                        <input
                                                                            name="roleradio"
                                                                            id={"role_" + item.id}
                                                                            type="radio"
                                                                            // defaultChecked={userData.roles.find(it => (it.roleId === item.roleId)) !== undefined}
                                                                            defaultChecked={user.roles.id === item.id}
                                                                            disabled={true}
                                                                            style={{ cursor: "not-allowed" }}
                                                                        />
                                                                        <label name="roleradio" />
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <input
                                                                            name="roleradio"
                                                                            id={"role_" + item.id}
                                                                            type="radio"
                                                                            // defaultChecked={userData.roles.find(it => (it.roleId === item.roleId)) !== undefined}
                                                                            defaultChecked={user?.roles?.id === item?.id}
                                                                            disabled={isDisabled}
                                                                            style={{ border: isDisabled ? "1px solid #dddddd" : "" }}
                                                                        />
                                                                        <label name="roleradio" htmlFor={"role_" + item.id} />
                                                                    </>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </>
                                        </tbody>
                                        : <div className="loader_wrapper">
                                            <Spinner animation="border" variant="primary" />
                                        </div>
                                }
                            </table>
                            <NotificationContainer />
                        </div>


                    </div>

                </div>

            </div>

        </>
    )
}

export default Userrolefiltertable
