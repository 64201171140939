
import { BaseConfig } from "../baseConfig";
import * as fflate from 'fflate';
import { data } from "jquery";
import { DateTime } from "luxon";
// import Field from "../models/fieldsModel";
import axios from 'axios';


export const getAllDriverPayConfiguration = async (driverId) => {

    try {
        const url = BaseConfig.ApiEndPoint + "/getalldriverpayconfig"
        const driverPayConfigData = await axios.get(url);
        return driverPayConfigData.data.data
    } catch (err) {
        return [];
    }

}

export const addDriverPayConfig = async (payload) => {
    try {
        const url = BaseConfig.ApiEndPoint + "/createDriverPayConfig"
        const driverPayConfigData = await axios.post(url, payload);
        return driverPayConfigData
    } catch (err) {
        return err;
    }
}

export const updateDriverPayConfig = async (id, payload) => {

    try {
        const url = BaseConfig.ApiEndPoint + "/updatedriverpayconfig/" + id;
        const driverPayConfigData = await axios.put(url, payload);
        return driverPayConfigData;
    } catch (err) {
        return err;
    }

}

export const deleteDriverPayConfig = async (id) => {
    try {
        const url = `${BaseConfig.ApiEndPoint}/deletedriverpayconfig/${id}`
        const driverPayConfigData = await axios.delete(url);

        return driverPayConfigData
    } catch (error) {
        return error
    }
}

export const pendingAuhorizedDriverpay = async (terminal_id) => {
    try {
        const url = `${BaseConfig.ApiEndPoint}/getallneedauhorizeddriverpay`;
        const needApprovalDriverPayResponse = await axios.post(url, terminal_id);

        return needApprovalDriverPayResponse;
    } catch (error) {
        return error;
    }
};

export const allDriverpayRecords = async (payload) => {
    try {
        const url = `${BaseConfig.ApiEndPoint}/getallPayrecordBystatus`;
        const driverPayResponse = await axios.post(url, payload);

        if (driverPayResponse?.data && driverPayResponse.data.compressedbase64string) {
            const strData = atob(driverPayResponse.data.compressedbase64string);
            const charData = strData.split("").map((x) => x.charCodeAt(0));
            const binData = new Uint8Array(charData);

            let apiDataUnCompressed = fflate.unzlibSync(binData);
            let apiDataUnCompressedString = fflate.strFromU8(apiDataUnCompressed);
            const decompressedObject = JSON.parse(apiDataUnCompressedString);

            // Return the decompressed object
            console.log(decompressedObject)
            return decompressedObject;
        } else {
            throw new Error("No Data");
        }
    } catch (error) {
        return error;
    }
};

export const getOrderIdbyMovemnetId = async (payload) => {
    try {
        const url = `${BaseConfig.ApiEndPoint}/getOrderByMovement`;
        const driverPayResponse = await axios.post(url, payload);
        return driverPayResponse;
    } catch (error) {
        return error;
    }
};


export const allMovementDetailsForEdit = async (driver_id) => {
    try {
        const payload = {
            "driver_id": driver_id
        }
        const url = `${BaseConfig.ApiEndPoint}//getLatestMovements`;
        const driverPayResponse = await axios.post(url, payload);
        return driverPayResponse;
    } catch (error) {
        return error;
    }
};

export const needAuthorizeDriverPay = async (skip, page) => {
    try {
        const url = `${BaseConfig.ApiEndPoint}/needauhorizeddriverpay?page=${page}&size=${skip}`
        const needAuthorizedDriverResponse = await axios.get(url);
        //locationApiData = zlib.inflateSync(Buffer.from(locationApiData.data, 'base64')).toString();
        const strData = atob(needAuthorizedDriverResponse.data);
        // Convert binary string to character-number array
        const charData = strData.split("").map((x) => { return x.charCodeAt(0); });
        // Turn number array into byte-array
        const binData = new Uint8Array(charData);
        // Use this Code to Decode the String

        //Uncompress API Call//
        let driverApiDataUnCompressed = fflate.unzlibSync(binData);
        let driverApiDataUnCompressedString = fflate.strFromU8(driverApiDataUnCompressed)
        let driverIntermediateObject = JSON.parse(driverApiDataUnCompressedString)


        return driverIntermediateObject.data
    } catch (error) {
        return error
    }
}

export const needApprovalDriverPay = async (terminal_id) => {
    try {
        const url = `${BaseConfig.ApiEndPoint}/needapprovaldriverpay`;
        const needApprovalDriverPayResponse = await axios.post(url, terminal_id);

        return needApprovalDriverPayResponse;
    } catch (error) {
        return error;
    }
};

export const getWeekOptions = async () => {
    try {
        const url = `${BaseConfig.ApiEndPoint}/gettransferredPay`
        const getWeeks = await axios.get(url);
        return getWeeks
    } catch (error) {
        return error
    }
}


// export const approvedDriverPay = async (terminalId) => {
//     try {
//         const url = `${BaseConfig.ApiEndPoint}/approveddriverpay`
//         const payload = {
//             "terminal_id": terminalId
//         }
//         const needApprovalDriverPayResponse = await axios.post(url, payload);
//         return needApprovalDriverPayResponse
//     } catch (error) {
//         return await error
//     }
// }

export const rejectedDriverPay = async (payload) => {
    try {
        const url = `${BaseConfig.ApiEndPoint}/rejecteddriverpay`
        const rejecteddriverpaydata = await axios.post(url, payload);
        return rejecteddriverpaydata
    } catch (error) {
        return error
    }
}
export const transferredDriverPay = async () => {
    try {
        const url = `${BaseConfig.ApiEndPoint}/transferreddriverpay`
        const transferreddriverpaydata = await axios.get(url);
        return transferreddriverpaydata
    } catch (error) {
        return error
    }
}
export const paidDriverPay = async () => {
    try {
        const url = `${BaseConfig.ApiEndPoint}/paiddriverpay`
        const paiddriverpaydata = await axios.get(url);
        return paiddriverpaydata
    } catch (error) {
        return error
    }
}

export const updateDriverPayStatus = async (id, payload) => {
    try {
        const url = `${BaseConfig.ApiEndPoint}/updatedriverpaystatus/${id}`
        const response = await axios.put(url, payload);
        return response
    } catch (error) {
        return error
    }

}

export const driverPaySearch = async (searchTerms, page, size) => {
    let decompressedObject;
    let payload_data = [];
    const url = `${BaseConfig.ApiEndPoint}/searchDriverPay?page=${page}&size=${size}`;

    try {
        searchTerms.forEach((term) => {
            const { field, value, operator } = term;
            if (
                field === "transaction_date" ||
                field === "pay_date" ||
                field === "authorized_on" ||
                field === "approved_on" ||
                field === "transfer_on" ||
                field === "effective_date" ||
                field === "created_on" ||
                field === "updated_on"
            ) {
                const formattedDate = DateTime.fromJSDate(new Date(value)).toFormat("yyyy-MM-dd");
                payload_data.push({
                    [field]: formattedDate,
                    operator: operator
                });
            } else {
                payload_data.push({
                    [field]: value,
                    operator: operator
                });
            }
        });

        const response = await axios.post(url, payload_data);

        if (response?.data && response.data.compressedbase64string) {
            const strData = atob(response.data.compressedbase64string);
            const charData = strData.split("").map((x) => {
                return x.charCodeAt(0);
            });
            const binData = new Uint8Array(charData);

            let apiDataUnCompressed = fflate.unzlibSync(binData);
            let apiDataUnCompressedString = fflate.strFromU8(apiDataUnCompressed);
            decompressedObject = JSON.parse(apiDataUnCompressedString);
        } else {
            throw new Error("Invalid response format: response.data is undefined or null");
        }
    } catch (error) {
        console.error("An error occurred while performing driver pay search:", error);
        throw error;
    }
    return decompressedObject;
};

export const driverPayApproveSearch = async (searchTerms,sortData, page, size, id, selectedTab) => {
    let decompressedObject;
    let payload_data = {
        terminal_id: id,
        searchdata: [],
        shortData: sortData
    };

    searchTerms.forEach((term) => {
        const { field, value, operator } = term;
        if (
            field === "transaction_date" ||
            field === "pay_date" ||
            field === "authorized_on" ||
            field === "approved_on" ||
            field === "transfer_on" ||
            field === "effective_date" ||
            field === "created_on" ||
            field === "updated_on"
        ) {
            const formattedDate = DateTime.fromJSDate(new Date(value)).toFormat("yyyy-MM-dd");
            payload_data.searchdata.push({
                [field]: formattedDate,
                operator: operator,
            });
        } else {
            payload_data.searchdata.push({
                [field]: value,
                operator: operator,
            });
        }
    });

    
    if(selectedTab == 'Transferred') {
        payload_data.searchdata.push({
            transfer_on: null,
            operator: "neq"
        })
    }else{
        payload_data.searchdata.push({
            transfer_on: null,
            operator: "eq"
        }, {
            status: selectedTab,
            operator: "eq"

        })

    }
    const url = `${BaseConfig.ApiEndPoint}/searchApprovedDriverPay?page=${page}&size=${size}`;

    try {
        const response = await axios.post(url, payload_data);

        if (response?.data && response.data.compressedbase64string) {
            const strData = atob(response.data.compressedbase64string);
            const charData = strData.split("").map((x) => {
                return x.charCodeAt(0);
            });
            const binData = new Uint8Array(charData);

            let apiDataUnCompressed = fflate.unzlibSync(binData);
            let apiDataUnCompressedString = fflate.strFromU8(apiDataUnCompressed);
            decompressedObject = JSON.parse(apiDataUnCompressedString);
        } else {
            throw new Error("Invalid response format: response.data is undefined or null");
        }
    } catch (error) {
        console.error("An error occurred while performing driver pay search:", error);
        throw error;
    }
    return decompressedObject;
};



export const driverPayImport = async function (data) {
    try {
        const url = `${BaseConfig.ApiEndPoint}/importFileDriverpay`
        const response = await axios.post(url, data);

        return response.data;
    } catch (error) {
        return error
    }
}

export const driverPayExport = async function (date) {
    try {
        const url = `${BaseConfig.ApiEndPoint}/exportFileDriverpay/${date}`;
        const response = await axios.get(url);
        return response.data.res.data;
    } catch (error) {
        return error;
    }
}

export const createDriverPay = async (payload) => {

    try {
        const url = BaseConfig.ApiEndPoint + "/createdriverpay"
        const driverPayConfigData = await axios.post(url, payload);
        return driverPayConfigData
    } catch (err) {
        return [];
    }

}

export const getMovmentById = async (payload) => {

    try {
        const url = BaseConfig.ApiEndPoint + "/getmovementstopbyid"
        const moveInfo = await axios.post(url, payload);
        return moveInfo.data.data;
    } catch (err) {
        throw err;

    }

}
export const getPayRate = async (payload) => {

    try {
        const url = BaseConfig.ApiEndPoint + "/getpayrate"
        const payRateData = await axios.post(url, payload);
        return payRateData.data;
    } catch (err) {
        throw err;

    }

}



export const transferredDriverPayStatus = async (payload) => {
    try {
        const url = BaseConfig.ApiEndPoint + "/driverpayTransfer"
        const trasnferResponse = await axios.post(url, payload);
        return trasnferResponse;
    } catch (err) {
        throw err;

    }
}

export const getAllMovements = async (payload) => {
    try {
        const url = BaseConfig.ApiEndPoint + "/getLatestMovements"
        const trasnferResponse = await axios.post(url, payload);
        return trasnferResponse;
    } catch (err) {
        throw err;
    }
}

export const getAllDataByGenericAPI = async (tabname, payload) => {
    try {
        const url = `${BaseConfig.ApiEndPoint}/grcforgetall/${tabname}`;
        const response = await axios.post(url, payload);
        return response.data.message;
    }
    catch (err) {
        throw err
    }
}

export const DeleteDriverPayByException = async (tabname, id) => {
    try {
        const url = `${BaseConfig.ApiEndPoint}/grcfordelete/${tabname}/${id}`;
        const response = await api({
            method: 'delete',
            url: url
        })
        return response
    }
    catch (err) {
        throw err
    }
}

export const UpdateDriverPayByDistanceExceptionLaneOverride = async (tabname,payload, id) => {
    try {
        const url = `${BaseConfig.ApiEndPoint}/grcforupdate/${tabname}/${id}`;
        const response = await axios.put(url, payload);
        return response
    }
    catch (err) {
        throw err
    }
}

export const AddDriverPayByDistanceExceptionLaneOverride = async (tabname,payload) => {
    try {
        const url = `${BaseConfig.ApiEndPoint}/grcforadd/${tabname}`;
        const response = await axios.post(url, payload);
        return response
    }
    catch (err) {
        throw err
    }
}

export const GetAllTerminalWithName = async () => {
    try {
        const url = `${BaseConfig.ApiEndPoint}/terminals`;
        const response = await axios.get(url);
        return response.data.data
    }
    catch (err) {
        throw err
    }
}

export const GetAllCommodityWithName = async () => {
    try {
        const url = `${BaseConfig.ApiEndPoint}/getAllactivecommodities `;
        const response = await axios.get(url);
        return response.data.data
    }
    catch (err) {
        throw err
    }
}

export const getallordertype = async () => {
    try {
        const url = `${BaseConfig.ApiEndPoint}/getallordertype`;
        const response = await axios.get(url);
        return response.data.data
    }
    catch (err) {
        throw err
    }
}

export const getAllEquipmenttype = async () => {
    try {
        const url = `${BaseConfig.ApiEndPoint}/getAllEquipmenttype`;
        const response = await axios.get(url);
        return response.data.data
    }
    catch (err) {
        throw err
    }
}

export const searchDriverpayKendo = async (tableName,searchTerms,sortData,page,size) => {
    let decompressedObject;
    try {
        const url = `${BaseConfig.ApiEndPoint}/grcforsearch/${tableName}?page=${page}&size=${size}`
        let data={
            searchdata:searchTerms,
            shortData:sortData
        }
        const response = await axios.post(url,data);
        if (response?.data && response?.data?.compressedbase64string) {
            const strData = atob(response.data.compressedbase64string);
            const charData = strData.split("").map((x) => {
                return x.charCodeAt(0);
            });
            const binData = new Uint8Array(charData);

            let apiDataUnCompressed = fflate.unzlibSync(binData);
            let apiDataUnCompressedString = fflate.strFromU8(apiDataUnCompressed);
            decompressedObject = JSON.parse(apiDataUnCompressedString);
        } else {
            throw new Error("Invalid response format: response.data is undefined or null");
        }
        return decompressedObject?.data
    }
    catch (err) {
        throw err
    }
}
