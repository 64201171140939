import React, { useEffect, useState, useContext, useRef } from "react";
import { DateTime } from "luxon";
import { withStyles } from '@mui/styles';
import Menu from '@mui/material/Menu';
import TrimbleMaps from "@trimblemaps/trimblemaps-js";
import { getActivelocations } from "../../services/locationService";
import { ContextData } from "../../components/appsession";
import { getUser } from "../../services/userService";
import RvHookupIcon from '@mui/icons-material/RvHookup';
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import { DropContext } from "../../pages/planner/planner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import 'react-picky/dist/picky.css';
import Skeleton from '@mui/material/Skeleton';
import PlannerBoardTractorTooltip, {
  SamsaraPBToolTip,
  OrderTooltip,
  OrderPBToolTip,
  Order2PBToolTip,
  TractorPBToolTip,
  ShiftToolTip
} from "./plannerboardtractortooltip";

import { gettrailerstabbydate } from "../../services/trailerService";
import { useHistory, useLocation } from "react-router-dom";
import WarningModal from "../warningModal";
// Initial values
const DATE_FORMAT = "MM-dd-yyyy HH:mm a ZZZZ ";
const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",

  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",

    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",

    }}
    {...props}
  />
));

const PlannerBoardTractorsection = (props) => {
  const [userData] = useContext(ContextData);
  const context = useContext(DropContext);
  const {
    selectedTimezoneDate,
    hourArr,
    dragset,
    plannerDriverData,
    allDrivers,
    tractorViewData,
    settrailerViewPageNumber,
    trailerViewPageNumber,                               // trailerViewPageNumber={tractorPageNumber}
    allTractors,
    selectedPlanningProfile,
    isEditPermission,
    setSuccessResponseMssg,
    setFailureResponseMssg,
    plannerTimezone,
    realTimeofPlanningBoard
  } = props;

  const [tractorData, setTractorData] = useState([]);
  const [driverState, setDriverState] = useState([]);
  const [allimpact, setallimpact] = useState([]);
  const [page, setPage] = useState(context.tractorPageNumber);
  const [showCommentsOfBillingDept, setShowCommentsOfBillingDept] = useState(null);
  const dayToMillis = 86400000;
  const [actualeditdatamodal, setactualeditdatamodal] = useState({});
  const [tractorDOM, setTractorDOM] = useState(<></>);
  const [refreshComponent, setRefreshComponent] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isHovered, setIsHovered] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(true);
  const [orders, setorders] = useState([]);

  const location = useLocation();
  const history = useHistory();


  const handleMouseEnter = () => {
    if (isDragging) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const renderTooltip = (movement) => {
    if (isHovered && !isDragging) {
      return (
        <PlannerBoardTractorTooltip tooltip={<OrderPBToolTip it={movement} />}>
          <div className='tooltip_row_block'>{movement.type}</div>
        </PlannerBoardTractorTooltip>
      );
    } else if (isDragging) {
      return (
        <div className='tooltip_row_block'>{movement.type}</div>
      );
    } else {
      return (
        <PlannerBoardTractorTooltip tooltip={<OrderPBToolTip it={movement} />}>
          <div className='tooltip_row_block'>{movement.type}</div>
        </PlannerBoardTractorTooltip>
      );
    }
  };

  const handleLoadingBarOnDriver = (driverId, isLoad) => {
    setDriverState(driverState.map((el) => {
      if (el.driverId === driverId)
        return {
          ...el,
          isloading: isLoad,
        };
      return el;
    }));
  }


  useEffect(() => {
    var arrayOfObj = [];
    let newOrderArr = allimpact;

    if (newOrderArr && Object.keys(newOrderArr)) {
      arrayOfObj = Object.keys(newOrderArr).map((i) => newOrderArr[i]);
      let newDataValue = [];
      for (let index = 0; index < arrayOfObj.length; index++) {
        const temp = arrayOfObj[index];
        const duration = temp.pcmilerduration;

        if (!duration || isNaN(duration)) {
          temp.pcmilerduration = "";
        } else {
          const durationFormatted = Duration.fromObject({ hours: duration }).toFormat("hh:mm:ss");
          temp.pcmilerduration = durationFormatted;
        }

        if (
          temp.Delivery_Actual_Arrive === null ||
          isNaN(temp.Delivery_Actual_Arrive)
        ) {
          temp.Delivery_Actual_Arrive;
        } else {
          const millis_Delivery_Actual_Arrive = parseInt(
            temp.Delivery_Actual_Arrive * 1000
          );

          const Timezone_Delivery_Actual_Arrive = temp.Delivery_Timezone;

          temp.Delivery_Actual_Arrive = DateTime.fromMillis(
            millis_Delivery_Actual_Arrive,
            {
              zone: Timezone_Delivery_Actual_Arrive,
              setZone: "yes",
              locale: "en-US",
              outputCalendar: "gregory",
              numberingSystem: "buddhist",
            }
          ).toFormat(DATE_FORMAT);
        }

        if (
          temp.Delivery_Actual_Depart === null ||
          isNaN(temp.Delivery_Actual_Depart)
        ) {
          temp.Delivery_Actual_Depart;
        } else {
          const millis_Delivery_Actual_Depart = parseInt(
            temp.Delivery_Actual_Depart * 1000
          );
          const Timezone_Delivery_Actual_Depart = temp.Delivery_Timezone;

          temp.Delivery_Actual_Depart = DateTime.fromMillis(
            millis_Delivery_Actual_Depart,
            {
              zone: Timezone_Delivery_Actual_Depart,
              setZone: "yes",
              locale: "en-US",
              outputCalendar: "gregory",
              numberingSystem: "buddhist",
            }
          ).toFormat(DATE_FORMAT);
        }

        if (
          temp.Delivery_Planned_Arrive === null ||
          isNaN(temp.Delivery_Planned_Arrive)
        ) {
          temp.Delivery_Planned_Arrive;
        } else {
          const millis_Delivery_Planned_Arrive = parseInt(
            temp.Delivery_Planned_Arrive * 1000
          );
          const Timezone_Delivery_Planned_Arrive = temp.Delivery_Timezone;

          temp.Delivery_Planned_Arrive = DateTime.fromMillis(
            millis_Delivery_Planned_Arrive,
            {
              zone: Timezone_Delivery_Planned_Arrive,
              setZone: "yes",
              locale: "en-US",
              outputCalendar: "gregory",
              numberingSystem: "buddhist",
            }
          ).toFormat(DATE_FORMAT);
        }

        if (
          temp.Delivery_Planned_Depart === null ||
          isNaN(temp.Delivery_Planned_Depart)
        ) {
          temp.Delivery_Planned_Depart;
        } else {
          const millis_Delivery_Planned_Depart = parseInt(
            temp.Delivery_Planned_Depart * 1000
          );
          const Timezone_Delivery_Planned_Depart = temp.Delivery_Timezone;

          temp.Delivery_Planned_Depart = DateTime.fromMillis(
            millis_Delivery_Planned_Depart,
            {
              zone: Timezone_Delivery_Planned_Depart,
              setZone: "yes",
              locale: "en-US",
              outputCalendar: "gregory",
              numberingSystem: "buddhist",
            }
          ).toFormat(DATE_FORMAT);
        }

        if (
          temp.Early_Delivery_Appt === null ||
          isNaN(temp.Early_Delivery_Appt)
        ) {
          temp.Early_Delivery_Appt;
        } else {
          const millis_Early_Delivery_Appt = parseInt(
            temp.Early_Delivery_Appt * 1000
          );
          const Timezone_Early_Delivery_Appt = temp.Delivery_Timezone;

          temp.Early_Delivery_Appt = DateTime.fromMillis(
            millis_Early_Delivery_Appt,
            {
              zone: Timezone_Early_Delivery_Appt,
              setZone: "yes",
              locale: "en-US",
              outputCalendar: "gregory",
              numberingSystem: "buddhist",
            }
          ).toFormat(DATE_FORMAT);
        }

        if (temp.Early_Pickup_Appt === null || isNaN(temp.Early_Pickup_Appt)) {
          temp.Early_Pickup_Appt;
        } else {
          const millis_Early_Pickup_Appt = parseInt(
            temp.Early_Pickup_Appt * 1000
          );
          const Timezone_Early_Pickup_Appt = temp.Delivery_Timezone;

          temp.Early_Pickup_Appt = DateTime.fromMillis(
            millis_Early_Pickup_Appt,
            {
              zone: Timezone_Early_Pickup_Appt,
              setZone: "yes",
              locale: "en-US",
              outputCalendar: "gregory",
              numberingSystem: "buddhist",
            }
          ).toFormat(DATE_FORMAT);
        }

        if (
          temp.Late_Delivery_Appt === null ||
          isNaN(temp.Late_Delivery_Appt)
        ) {
          temp.Late_Delivery_Appt;
        } else {
          const millis_Late_Delivery_Appt = parseInt(
            temp.Late_Delivery_Appt * 1000
          );
          const Timezone_Late_Delivery_Appt = temp.Delivery_Timezone;

          temp.Late_Delivery_Appt = DateTime.fromMillis(
            millis_Late_Delivery_Appt,
            {
              zone: Timezone_Late_Delivery_Appt,
              setZone: "yes",
              locale: "en-US",
              outputCalendar: "gregory",
              numberingSystem: "buddhist",
            }
          ).toFormat(DATE_FORMAT);
        }

        if (temp.Late_Pickup_Appt === null || isNaN(temp.Late_Pickup_Appt)) {
          temp.Late_Pickup_Appt;
        } else {
          const millis_movementpickupactual_departure = parseInt(
            temp.movementpickupactual_departure * 1000
          );
          const Timezone_movementpickupactual_departure =
            temp.movementpickuptimezone;
          const millis_Late_Pickup_Appt = parseInt(
            temp.Late_Pickup_Appt * 1000
          );
          const Timezone_Late_Pickup_Appt = temp.Pickup_Timezone;

          temp.movementpickupactual_departure = DateTime.fromMillis(
            millis_movementpickupactual_departure,
            {
              zone: Timezone_movementpickupactual_departure,
              setZone: "yes",
              locale: "en-US",
              outputCalendar: "gregory",
              numberingSystem: "buddhist",
            }
          ).toFormat(DATE_FORMAT);

          temp.Late_Pickup_Appt = DateTime.fromMillis(millis_Late_Pickup_Appt, {
            zone: Timezone_Late_Pickup_Appt,
            setZone: "yes",
            locale: "en-US",
            outputCalendar: "gregory",
            numberingSystem: "buddhist",
          }).toFormat(DATE_FORMAT);
        }

        if (
          temp.movementpickupsched_arrive_early === null ||
          isNaN(temp.movementpickupsched_arrive_early)
        ) {
          temp.movementpickupsched_arrive_early;
        } else {
          const millis_movementpickupsched_arrive_early = parseInt(
            temp.movementpickupsched_arrive_early * 1000
          );
          const Timezone_movementpickupsched_arrive_early =
            temp.movementpickuptimezone;

          temp.movementpickupsched_arrive_early = DateTime.fromMillis(
            millis_movementpickupsched_arrive_early,
            {
              zone: Timezone_movementpickupsched_arrive_early,
              setZone: "yes",
              locale: "en-US",
              outputCalendar: "gregory",
              numberingSystem: "buddhist",
            }
          ).toFormat(DATE_FORMAT);
        }

        if (
          temp.Pickup_Actual_Arrive === null ||
          isNaN(temp.Pickup_Actual_Arrive)
        ) {
          temp.Pickup_Actual_Arrive;
        } else {
          const millis_Pickup_Actual_Arrive = parseInt(
            temp.Pickup_Actual_Arrive * 1000
          );
          const Timezone_Pickup_Actual_Arrive = temp.Pickup_Timezone;

          temp.Pickup_Actual_Arrive = DateTime.fromMillis(
            millis_Pickup_Actual_Arrive,
            {
              zone: Timezone_Pickup_Actual_Arrive,
              setZone: "yes",
              locale: "en-US",
              outputCalendar: "gregory",
              numberingSystem: "buddhist",
            }
          ).toFormat(DATE_FORMAT);
        }

        if (
          temp.Pickup_Actual_Depart === null ||
          isNaN(temp.Pickup_Actual_Depart)
        ) {
          temp.Pickup_Actual_Depart;
        } else {
          const millis_Pickup_Actual_Depart = parseInt(
            temp.Pickup_Actual_Depart * 1000
          );
          const Timezone_Pickup_Actual_Depart = temp.Pickup_Timezone;

          temp.Pickup_Actual_Depart = DateTime.fromMillis(
            millis_Pickup_Actual_Depart,
            {
              zone: Timezone_Pickup_Actual_Depart,
              setZone: "yes",
              locale: "en-US",
              outputCalendar: "gregory",
              numberingSystem: "buddhist",
            }
          ).toFormat(DATE_FORMAT);
        }

        if (temp.Pickup_ETA === null || isNaN(temp.Pickup_ETA)) {
          temp.Pickup_ETA;
        } else {
          const millis_Pickup_ETA = parseInt(temp.Pickup_ETA * 1000);
          const Timezone_Pickup_ETA = temp.Pickup_Timezone;

          temp.Pickup_ETA = DateTime.fromMillis(millis_Pickup_ETA, {
            zone: Timezone_Pickup_ETA,
            setZone: "yes",
            locale: "en-US",
            outputCalendar: "gregory",
            numberingSystem: "buddhist",
          }).toFormat(DATE_FORMAT);
        }

        if (temp.Delivery_ETA === null || isNaN(temp.Delivery_ETA)) {
          temp.Delivery_ETA;
        } else {
          const millis_Delivery_ETA = parseInt(temp.Delivery_ETA * 1000);
          const Timezone_Delivery_ETA = temp.Delivery_Timezone;

          temp.Delivery_ETA = DateTime.fromMillis(millis_Delivery_ETA, {
            zone: Timezone_Delivery_ETA,
            setZone: "yes",
            locale: "en-US",
            outputCalendar: "gregory",
            numberingSystem: "buddhist",
          }).toFormat(DATE_FORMAT);
        }

        if (
          temp.Pickup_Planned_Arrive === null ||
          isNaN(temp.Pickup_Planned_Arrive)
        ) {
          temp.Pickup_Planned_Arrive;
        } else {
          const millis_Pickup_Planned_Arrive = parseInt(
            temp.Pickup_Planned_Arrive * 1000
          );
          const Timezone_Pickup_Planned_Arrive = temp.Pickup_Timezone;

          temp.Pickup_Planned_Arrive = DateTime.fromMillis(
            millis_Pickup_Planned_Arrive,
            {
              zone: Timezone_Pickup_Planned_Arrive,
              setZone: "yes",
              locale: "en-US",
              outputCalendar: "gregory",
              numberingSystem: "buddhist",
            }
          ).toFormat(DATE_FORMAT);
        }

        if (
          temp.Pickup_Planned_Depart === null ||
          isNaN(temp.Pickup_Planned_Depart)
        ) {
          temp.Pickup_Planned_Depart;
        } else {
          const millis_Pickup_Planned_Depart = parseInt(
            temp.Pickup_Planned_Depart * 1000
          );
          const Timezone_Pickup_Planned_Depart = temp.Pickup_Timezone;

          temp.Pickup_Planned_Depart = DateTime.fromMillis(
            millis_Pickup_Planned_Depart,
            {
              zone: Timezone_Pickup_Planned_Depart,
              setZone: "yes",
              locale: "en-US",
              outputCalendar: "gregory",
              numberingSystem: "buddhist",
            }
          ).toFormat(DATE_FORMAT);
        }

        newDataValue.push(temp);
      }
      setorders(newDataValue);
      // setIsDataLoaded(true);
    }
  }, [allimpact]);

  useEffect(() => setTractorData(plannerDriverData), [plannerDriverData]);

  useEffect(() => {
    setDriverState(
      tractorViewData
    );
  }, [tractorViewData, page]);

  const handeTractorViewPagination = async (event, value) => {
    context.setTractorPageNumber(value);
    setPage(value);
  };

  const handleRightClickTrailerRow = (e, driver, trailer) => {
    e.preventDefault();
    const movements = driver?.movements?.find((o) => o?.orderId2 === trailer?.order_id2 && o.stopData === null);

  };



  const handleRightClickorderRow = async (e, order, driver) => {
    e.preventDefault();
    (order)
    if (showCommentsOfBillingDept) {
      setShowCommentsOfBillingDept(null);
    } else {
      setShowCommentsOfBillingDept(e.currentTarget);
    }
  }

  //Tractor DOM
  useEffect(async () => {
    setTractorDOM(await Promise.all(driverState?.map(async (driver, idx) => {

      return (
        <div key={idx} class='planner_main_container_outer'>
          <div className='planner_main_container_outer_image_container_right_text_wrapper'>
            <div class='planner_main_container_outer_image_container'
              aria-controls='customized-menu'
              aria-haspopup='true'
              variant='contained'
              color='primary'
            >
              <div className='plannerflex'>

              </div>
              <PlannerBoardTractorTooltip
                      tooltip={
                        <TractorPBToolTip it={driver} />
                      }
                    >
              <RvHookupIcon style={{ color: "#3b3838", cursor: "pointer" }} />
              </PlannerBoardTractorTooltip>

              {/* </PlannerBoardTooltip> */}

              <p className='planner_main_container_outer_image_container_icon_text'>
                {driver.tractor_id}
              </p>
            </div>
            <div className='planner_main_container_outer_image_container_right_text'>
              <p>Order</p>
              <p>Driver</p>
              <p>Segment</p>
            </div>
          </div>

          <div class='planner_dragable_container'>
            {
              realTimeofPlanningBoard >= selectedTimezoneDate && realTimeofPlanningBoard < (selectedTimezoneDate + dayToMillis) &&
              <div className="plannerboard_realtime_bar" style={{ left: (((realTimeofPlanningBoard - (DateTime.now().setZone(plannerTimezone).startOf("day").toMillis())) / dayToMillis) * 100 + "%") }}></div>
            }
            {driver.isloading ? (
              <Stack spacing={1}>
                <div className='loader_skeleton'>
                  <Skeleton variant='rectangular' />
                </div>
              </Stack>
            ) : (
              <></>
            )}
            {driver?.schedule?.offduty

              ?.map((log, index) => {
                if (
                  log.logEndTime >= selectedTimezoneDate ||
                  log.logStartTime <
                  selectedTimezoneDate + dayToMillis
                ) {
                  const parentWidth = 100;

                  return (
                    <div
                      key={index}
                      class='planner_hos_log1'
                      style={{
                        position: "absolute",
                        left:
                          log.logStartTime >= selectedTimezoneDate
                            ? ((log.logStartTime - selectedTimezoneDate) /
                              dayToMillis) *
                            100 +
                            "%"
                            : "0%",
                        width: `${(((log.logEndTime <
                          selectedTimezoneDate + dayToMillis
                          ? log.logEndTime
                          : selectedTimezoneDate + dayToMillis) -
                          (log.logStartTime >= selectedTimezoneDate
                            ? log.logStartTime
                            : selectedTimezoneDate)) /
                          dayToMillis) *
                          100
                          }%`,
                        display:
                          (((log.logEndTime <
                            selectedTimezoneDate + dayToMillis
                            ? log.logEndTime
                            : selectedTimezoneDate + dayToMillis) -
                            (log.logStartTime >= selectedTimezoneDate
                              ? log.logStartTime
                              : selectedTimezoneDate)) /
                            dayToMillis) *
                            100 >
                            0
                            ? "block"
                            : "none",
                        backgroundColor: '#545b71'
                      }}
                    ></div>
                  )
                }
                return null
              }
              )}
            <div className='planner_tick_container'>
              {dragset.map((it2, index) => (
                <div
                  key={index}
                  onDrop={(e) => {
                    setTimeout(() => {
                      const divlist = document.getElementsByClassName('planner_dragable_inner');
                      for (let j = 0; j < divlist.length; j++) {
                        if (divlist[j].classList.contains('pointerevent')) {
                          divlist[j].classList.remove('pointerevent');
                        }
                      }

                    }, 10);

                    if (!(context.dragData.dragFrom === "orders" && context.plannedOrUnplanned === "left")) {
                      handleLoadingBarOnDriver(driver.driverId, true);
                    }

                    // driver.isloading = true;
                    if (context.dragData.dragFrom == ("movements" || "Shift"))
                      driverState.filter(
                        (dri) =>
                          dri.driverId ===
                          context.dragData.dragDataItem.driverId
                      )[0].isloading = true;
                    e.preventDefault();
                    e.stopPropagation();
                    context.setDropData({
                      drop_to: "planner_tick_container",
                      driver_id: driver.driverId,
                      index: index,
                    });

                    setTimeout(() => {
                      const divlist = document.getElementsByClassName('planner_dragable_inner');
                      for (let j = 0; j < divlist.length; j++) {
                        divlist[j].classList.remove('pointerevent');
                      }

                    }, 10);
                  }}
                  onDragOver={(e) => {
                    e.preventDefault();
                  }}
                  className='planner_dragable_inner'
                ></div>
              ))}
            </div>

            <div class='planner_dragable_main_block'>
              {driver?.shifts
                ?.filter(
                  (shift) =>
                    shift.shiftEndTime >= selectedTimezoneDate ||
                    shift.shiftStartTime <
                    selectedTimezoneDate + dayToMillis
                )
                .map((shift, index) => (
                  <div
                    key={index}
                    class={
                      shift.shiftType === "start" && shift.movementData.pickup.location.code === "LAYOVER"
                        ? "planner_shift_color"
                        : shift.shiftType === "end" && shift.movementData.delivery.location.code === "LAYOVER"
                          ? "planner_shift_color"
                          : "planner_shift"
                    }
                    style={{
                      position: "absolute",
                      left:
                        shift.shiftStartTime >= selectedTimezoneDate
                          ? ((shift.shiftStartTime -
                            selectedTimezoneDate) /
                            dayToMillis) *
                          100 +
                          "%"
                          : "0%",
                      width: `${(((shift.shiftEndTime <
                        selectedTimezoneDate + dayToMillis
                        ? shift.shiftEndTime
                        : selectedTimezoneDate + dayToMillis) -
                        (shift.shiftStartTime >=
                          selectedTimezoneDate
                          ? shift.shiftStartTime
                          : selectedTimezoneDate)) /
                        dayToMillis) *
                        100
                        }%`,
                      display:
                        (((shift.shiftEndTime <
                          selectedTimezoneDate + dayToMillis
                          ? shift.shiftEndTime
                          : selectedTimezoneDate + dayToMillis) -
                          (shift.shiftStartTime >=
                            selectedTimezoneDate
                            ? shift.shiftStartTime
                            : selectedTimezoneDate)) /
                          dayToMillis) *
                          100 >
                          0
                          ? "block"
                          : "none",
                      border: `${shift?.movementData?.pickup?.actual_departure_utc !== 0 ? "3px solid #FFFFFF" : "1px solid #444444"}`,
                    }}



                  >

                    {shift.shiftType == "start" ||
                      shift.shiftType == "end" ? (
                      <PlannerBoardTractorTooltip
                        tooltip={
                          <ShiftToolTip it={shift} />
                        }
                      >
                        <div className="tooltip_row_block">
                          {shift.shiftType == "start" ? "SS" : "ES"}
                        </div>
                      </PlannerBoardTractorTooltip>


                    ) : (
                      ""
                    )}
                  </div>
                ))}

              <div class='planner_dragable_inner_main_block'>
                <div class='planner_draggable_main_block_row'>
                  {driver?.orders
                    ?.map((order, index) => {
                      if (
                        order.orderEndTime >= selectedTimezoneDate ||
                        order.orderStartTime <
                        selectedTimezoneDate + dayToMillis
                      ) {
                        let next
                        if (index < driver?.orders.length - 1) {
                          next = driver?.orders[index + 1];
                        }
                        let endTime
                        if (next != undefined) {
                          endTime = Math.min(order?.orderEndTime, next?.orderStartTime)
                        }
                        else {
                          endTime = order?.orderEndTime
                        }
                        return (

                          <div
                            key={index}
                            style={{
                              ...order.style,
                              position: "absolute",
                              left:
                                order.orderStartTime >=
                                  selectedTimezoneDate
                                  ? ((order.orderStartTime -
                                    selectedTimezoneDate) /
                                    dayToMillis) *
                                  100 +
                                  "%"
                                  : "0%",
                              width: `${(((endTime <
                                selectedTimezoneDate + dayToMillis
                                ? endTime
                                : selectedTimezoneDate +
                                dayToMillis) -
                                (order.orderStartTime >=
                                  selectedTimezoneDate
                                  ? order.orderStartTime
                                  : selectedTimezoneDate)) /
                                dayToMillis) *
                                100
                                }%`,
                              display:
                                (((endTime <
                                  selectedTimezoneDate + dayToMillis
                                  ? endTime
                                  : selectedTimezoneDate +
                                  dayToMillis) -
                                  (order.orderStartTime >=
                                    selectedTimezoneDate
                                    ? order.orderStartTime
                                    : selectedTimezoneDate)) /
                                  dayToMillis) *
                                  100 >
                                  0
                                  ? "block"
                                  : "none",
                              pointerEvents: (context.dragData ? "all" : context.dragData.dragFrom != "Shift" ? "all" : "")
                            }}
                            onContextMenu={(e) => handleRightClickorderRow(e, order, driver)}
                            className='planner_draggable_main_block_row_block planner_draggable_main_block_top_row_block'
                          >
                            <PlannerBoardTractorTooltip
                              tooltip={<OrderTooltip it={driver?.movements?.find(o => o.orderId2 === order.orderId && o.type == "L") ?? driver?.movements?.find(o => o.orderId2 === order.orderId)} />}
                            >
                              <div className='tooltip_row_block'>
                                {order.orderId}
                              </div>
                            </PlannerBoardTractorTooltip>
                          </div>
                        );
                      }
                      return null
                    })}


                </div>

                <div class='planner_draggable_main_block_row planner_draggable_main_block_middle_row'>
                  {
                    // driver.driverId === "4879" ?
                    driver?.drivers
                      ?.map((trailer, index) => {
                        if (
                          trailer.driverEndTime >
                          selectedTimezoneDate ||
                          trailer.driverStartTime <
                          selectedTimezoneDate + dayToMillis
                        ) {
                          let next
                          if (index < driver?.drivers.length - 1) {
                            next = driver?.drivers[index + 1];
                          }
                          let endTime
                          if (next != undefined) {
                            endTime = Math.min(trailer?.driverEndTime, next?.driverEndTime)
                          }
                          else {
                            endTime = trailer?.driverEndTime
                          }
                          return (
                            <div
                              key={index}
                              style={{
                                position: "absolute",
                                left:
                                  trailer.driverStartTime >=
                                    selectedTimezoneDate
                                    ? ((trailer.driverStartTime -
                                      selectedTimezoneDate) /
                                      dayToMillis) *
                                    100 +
                                    "%"
                                    : "0%",
                                width: `${(((endTime <
                                  selectedTimezoneDate + dayToMillis
                                  ? endTime
                                  : selectedTimezoneDate +
                                  dayToMillis) -
                                  (trailer.driverStartTime >=
                                    selectedTimezoneDate
                                    ? trailer.driverStartTime
                                    : selectedTimezoneDate)) /
                                  dayToMillis) *
                                  100
                                  }%`,
                                display:
                                  (((endTime <
                                    selectedTimezoneDate + dayToMillis
                                    ? endTime
                                    : selectedTimezoneDate +
                                    dayToMillis) -
                                    (trailer.driverStartTime >=
                                      selectedTimezoneDate
                                      ? trailer.driverStartTime
                                      : selectedTimezoneDate)) /
                                    dayToMillis) *
                                    100 >
                                    0
                                    ? "block"
                                    : "none",
                                pointerEvents: (context.dragData.dragFrom != "Shift" ? "all" : "")
                              }}
                              onContextMenu={(e) => handleRightClickTrailerRow(e, driver, trailer)}
                              className='planner_draggable_main_block_row_block planner_draggable_main_block_middle_row_block'

                            >
                              <PlannerBoardTractorTooltip
                                tooltip={<TractorPBToolTip it={driver} />}
                              >
                                <div className='tooltip_row_block'>
                                  {trailer?.driverId}

                                </div>
                              </PlannerBoardTractorTooltip>
                            </div>
                          );

                        }
                        return null

                      })
                    // : ""
                  }

                  {
                    driver?.newEmptyMoveByPlanner?.map((movement, index) => {
                      if (
                        movement.endTime >= selectedTimezoneDate ||
                        movement.startTime <
                        selectedTimezoneDate + dayToMillis
                      ) {
                        let next
                        if (index < driver?.newEmptyMoveByPlanner.length - 1) {
                          next = driver?.newEmptyMoveByPlanner[index + 1];
                        }
                        let endTime
                        if (next != undefined) {
                          endTime = Math.min(movement?.endTime, next?.startTime)
                        }
                        else {
                          endTime = movement?.endTime
                        }
                        return <div
                          key={index}
                          style={{
                            position: "absolute",
                            left: movement.startTime >=
                              selectedTimezoneDate
                              ? ((movement.startTime -
                                selectedTimezoneDate) /
                                dayToMillis) *
                              100 +
                              "%"
                              : "0%",
                            width: `${(((endTime <
                              selectedTimezoneDate + dayToMillis
                              ? endTime
                              : selectedTimezoneDate +
                              dayToMillis) -
                              (movement.startTime >=
                                selectedTimezoneDate
                                ? movement.startTime
                                : selectedTimezoneDate)) /
                              dayToMillis) *
                              100
                              }%`,
                            display:
                              (((endTime <
                                selectedTimezoneDate + dayToMillis
                                ? endTime
                                : selectedTimezoneDate +
                                dayToMillis) -
                                (movement.startTime >=
                                  selectedTimezoneDate
                                  ? movement.startTime
                                  : selectedTimezoneDate)) /
                                dayToMillis) *
                                100 >
                                0
                                ? "block"
                                : "none",
                          }}
                          className='planner_draggable_main_block_row_block_cross planner_draggable_main_block_top_row_block_cross'
                        >
                        </div>

                      }
                      return null

                    })
                  }
                </div>

                <div class='planner_draggable_main_block_row'>
                  {driver?.movements

                    ?.map((movement, index) => {
                      if (
                        movement.endTime >= selectedTimezoneDate ||
                        movement.startTime <
                        selectedTimezoneDate + dayToMillis
                      ) {
                        let next
                        if (index < driver?.movements.length - 1) {
                          next = driver?.movements[index + 1];
                        }
                        let endTime
                        if (next != undefined) {
                          endTime = Math.min(movement?.endTime, next?.startTime)
                        }
                        else {
                          endTime = movement?.endTime
                        }
                        return (
                          <div
                            key={index}
                            style={{
                              ...movement.style,
                              position: "absolute",
                              left:
                                movement.startTime >=
                                  selectedTimezoneDate
                                  ? ((movement.startTime -
                                    selectedTimezoneDate) /
                                    dayToMillis) *
                                  100 +
                                  "%"
                                  : "0%",
                              width: `${(((endTime <
                                selectedTimezoneDate + dayToMillis
                                ? endTime
                                : selectedTimezoneDate +
                                dayToMillis) -
                                (movement.startTime >=
                                  selectedTimezoneDate
                                  ? movement.startTime
                                  : selectedTimezoneDate)) /
                                dayToMillis) *
                                100
                                }%`,
                              border: `${['L', 'E', 'BT'].includes(movement.type) && movement?.movementData?.delivery?.actual_arrival_utc !== 0 ? "3px solid #FFFFFF" :
                                ['DEL', 'PU', 'SP', 'SD', 'DT', 'DH', 'HT', 'RET'].includes(movement.type) && movement?.movementData?.pickup?.actual_departure_utc !== 0 ? "3px solid #FFFFFF"
                                  : "0px solid #444444"}`,
                              display:
                                (((endTime <
                                  selectedTimezoneDate + dayToMillis
                                  ? endTime
                                  : selectedTimezoneDate +
                                  dayToMillis) -
                                  (movement.startTime >=
                                    selectedTimezoneDate
                                    ? movement.startTime
                                    : selectedTimezoneDate)) /
                                  dayToMillis) *
                                  100 >
                                  0
                                  ? "block"
                                  : "none",
                            }}
                            className='planner_draggable_main_block_row_block planner_draggable_main_block_bottom_row_block'
                            aria-controls='customized-menu'
                            aria-haspopup='true'
                            variant='contained'
                            color='primary'
                            draggable


                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}

                          >
                            {movement.type === "L" ||
                              movement.type === "E" || movement.type === "BT" ? (
                              <PlannerBoardTractorTooltip
                                tooltip={
                                  <Order2PBToolTip it={movement} />
                                }
                              >
                                <div className='tooltip_row_block'>
                                  {movement.type}
                                </div>
                              </PlannerBoardTractorTooltip>
                            ) : (
                              ""
                            )}

                            {movement.type === "DEL" ||
                              movement.type === "DT" ||
                              movement.type === "DH" ||
                              movement.type === "HT" ||
                              movement.type === "RET" ||
                              movement.type === "SD" ||
                              movement.type === "PU" ||
                              movement.type === "SP" ? (
                              renderTooltip(movement)
                            ) : (
                              null
                            )
                            }
                          </div>
                        );
                      }
                      return null

                    })}
                </div>
              </div>
            </div>
          </div>
          <div class='planner_pm_section forum-icon-container'>

            <div>
              <div className={driver?.messages?.filter((el) => ((el.is_read_by_planner == false || el.is_read_by_planner == null) && el.source != "BPA")).length > 0 && "planner_message_alert"} >
                {
                  driver?.messages?.filter((el) => ((el.is_read_by_planner == false || el.is_read_by_planner == null) && el.source != "BPA")).length > 0 && driver?.messages?.filter((el) => ((el.is_read_by_planner == false || el.is_read_by_planner == null) && el.source != "BPA")).length
                }
              </div>
            </div>
          </div>
        </div>
      )
    })));
  }, [driverState, selectedTimezoneDate, actualeditdatamodal, context.dragData, showCommentsOfBillingDept
  ]);

  return (
    <>
      {/* <ToastContainer position='top-right' autoClose={2000} theme='colored' /> */}
      <div key={refreshComponent ? "main_planner_wrapper_refresh" : "main_planner_wrapper"}>
        <div class='planner_main_container_outer'>
          <div className='planner_am_section'>HRS</div>
          <div className='planner_main_container'>
            {hourArr.map((item, key) => (
              <div key={key} className='planner_main_inner_container'>
                <div className='hrs_wrapper'>
                  <p className='hrs_wrapper_time_text'>
                    {item == "0"
                      ? `12  A.M`
                      : item == "12"
                        ? `12  P.M`
                        : item > 11
                          ? `${item - 12}  P.M`
                          : `${item}  A.M`}
                  </p>
                  <div className='planner_day_time_inner'>
                    <div className='vl_plannertankwash'></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div class='planner_pm_section'>HRS</div>
        </div>
        <div className='scroll_plannerboard_inner'>
          {tractorDOM}
        </div>

        <div className="pagination_adjust_driver">
          {tractorData.length >
            0 ? (
            <Stack spacing={2}>
              <Pagination
                onChange={handeTractorViewPagination}
                count={Math.ceil(
                  allTractors?.length / 25
                )}
                page={context.tractorPageNumber}
                color='primary'
              />
            </Stack>
          ) : (
            ""
          )}
        </div>
      </div>

    </>
  );
};

export default PlannerBoardTractorsection;