import { useState, useEffect, useContext } from 'react';
import { toast, ToastContainer } from "react-toastify";
import { requestForToken, onMessageListener } from '../../firebase';
import { ContextData } from "../../components/appsession";
import { updateFirebaseToken } from "../../services/userService.js";


const PushNotification = () => {
  const [userData, setuserData, getUserInfoMSGraph, userUniqueId] = useContext(ContextData);
  const [notification, setNotification] = useState({ title: '', body: '', url: '' });
  const [totalAssignedPlanningProfiles, setTotalAssignedPlanningProfiles] = useState({});

  const handleClick = () => {
    window.location.href = notification?.url;
  };

  const notify = () => toast.info(<ToastDisplay />, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    icon: false,
    onClick: handleClick
  });

  function ToastDisplay() {
    return (
      <>
        <div style={{fontSize: "20px", fontWeight: "bold", marginBottom: "10px"}}>{notification?.title}</div>
        <div style={{fontSize: "16px"}}>{notification?.body}</div>
      </>
    );
  };

  useEffect(() => {
    if (notification?.title) {
      notify();
    }
  }, [notification]);

  useEffect(() => {
    if (Object.keys(userData).length > 0) {
      setTotalAssignedPlanningProfiles(userData?.planningprofiles);
    }
  }, [userData]);

  useEffect(() => {
    (async () => {
      try {
        if (Object.keys(userData).length > 0 && Object.keys(totalAssignedPlanningProfiles).length > 0) {
          // Getting firebase client token
          const token = await requestForToken();
          
          if (totalAssignedPlanningProfiles.length !== userData.planningprofiles.length || token !== userData?.push_notification_token?.fbClientToken) {
            const payload = {
              fbClientToken: token,
              planningProfiles: userData.planningprofiles
            };

            await updateFirebaseToken(userData?.id, payload);
          }
        }
      } catch (err) {
        toast.error("An error occurred while storing token.");
      }
    })();


  }, [userData, totalAssignedPlanningProfiles]);

  const fetchMessages = async () => {
    try {
      const payload = await onMessageListener();
      setNotification({ title: payload?.data?.title, body: payload?.data?.body, url: payload.data.url });
    } catch (err) {
      toast.error("An error occurred while retrieving messages.")
    }
  };

  fetchMessages();

  return (
    <>
      {/* <ToastContainer /> */}
    </>
  )
}

export default PushNotification;
