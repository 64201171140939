
import { BaseConfig } from "../baseConfig";

import axios from 'axios';


export const getalldriverspaybase = async () => {
    let driverPayCodesMasterObj = [];

    try {
        const url = BaseConfig.ApiEndPoint + "/getallactivedriverspaybase";
        const driverPayCodesMasterData = await axios.get(url);
        driverPayCodesMasterObj = driverPayCodesMasterData.data.data;
    } catch (err) {
        return Promise.reject(
            "There is a problem on retrieving driver pay codes master data. Please try again!"
        );
    }

    return Promise.resolve(driverPayCodesMasterObj);
};

export const getallactivedriverspaybase = async () => {
    let driverPayCodesMasterObj = [];

    try {
        const url = BaseConfig.ApiEndPoint + "/getallactivedriverspaybase";
        const driverPayCodesMasterData = await axios.get(url);
        driverPayCodesMasterObj = driverPayCodesMasterData.data.data;
    } catch (err) {
        return Promise.reject(
            "There is a problem on retrieving driver pay codes master data. Please try again!"
        );
    }

    return Promise.resolve(driverPayCodesMasterObj);
};


export const createdriverspay = async (data) => {
    let driverPayCodesMasterObj = [];
    try {
        const url = BaseConfig.ApiEndPoint + "/createdriverspay";
        const driverPayCodesMasterData = await axios.post(url, data);
        driverPayCodesMasterObj = driverPayCodesMasterData.data.data;
    } catch (error) {
        return Promise.reject("Error: Unable to add the Terminal Contact.");
    }
    return Promise.resolve(driverPayCodesMasterObj);
};



export const updateDriverPayCodesMaster = async (id, payload) => {
    let driverPayCodesMasterObj = []; 
    try {
        const url = BaseConfig.ApiEndPoint + "/updatedriverbasepay/" + id;
        const driverPayCodesMasterData = await axios.put(url, payload);
        driverPayCodesMasterObj = driverPayCodesMasterData.data.data;
    } catch (error) {
        return Promise.reject("Error: Unable to update fast pay.");
    }
    return Promise.resolve(driverPayCodesMasterObj);
};

export const deleteDriverPayCodesMaster = async (id) => {
    let driverPayCodesMasterObj; 
    try {
        const url = BaseConfig.ApiEndPoint + "/deleteriverbasepay/" + id;
        const driverPayCodesMasterData = await axios.delete(url);
        driverPayCodesMasterObj = driverPayCodesMasterData;
    } catch (error) {
        return Promise.reject("Error: Unable to delete fast pay");
    }
    return Promise.resolve(driverPayCodesMasterObj);
};

