import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import GoogleMapReact from "google-map-react";
import Map from "./Map";
import { Tooltip } from "@mui/material";
import TrimbleMaps from "@trimblemaps/trimblemaps-js";
import { getTrailerHistorybyTrailerid } from "../../services/trailerService";
import Skeleton from '@mui/material/Skeleton';
import { BaseConfig } from "../../baseConfig";

const TrailerHeaderInfo = (props) => {
  const { trailerById, convertDateTime,isLoading, trailerLastLocation  } = props;

  const [modalShow, setModalShow] = useState(false);
 
  let trailerStatusClass = null;
  if(!trailerById.is_active) {
    trailerStatusClass = "offline_sign";
  }
  else if((trailerById.is_active === true) && (trailerById.eqstat === "In Shop")) {
    trailerStatusClass = "in_shop_circle";
  }
  else {
    trailerStatusClass = "online_sign";
  }

  const openTrailerModal = (e) => {
    setModalShow(true);
  };
  // useEffect(() => {
  //   if (trailerById.longitude || trailerById.latitude) {
  //     let obj = {
  //       lat: Number(trailerById.latitude),
  //       lng: Number(trailerById.longitude),
  //     };
  //     setloc([obj]);
  //   }
  // }, [Object.keys(trailerById).length > 0]);
  // useEffect(async()=>{
  //  setloc(await getTrailerHistorybyTrailerid(
  //       trailerById.bpatrailerterminal?.[0]?.trailer_id
  //     ))
   
  
  // },[])

  useEffect(() => {
   
    if(modalShow){
      TrimbleMaps.APIKey = BaseConfig.Mapkey;
      const map = new TrimbleMaps.Map({
        container: 'map', // container id
        style: TrimbleMaps.Common.Style.TRANSPORTATION, //hosted style id
        
        center:  new TrimbleMaps.LngLat( trailerLastLocation.lng,trailerLastLocation.lat), // starting position
        zoom: 12 // starting zoom

      });
      
      const marker = new TrimbleMaps.Marker({
        draggable: true
    }).setLngLat([trailerLastLocation.lng,trailerLastLocation.lat]).addTo(map);
       map.addControl(new TrimbleMaps.NavigationControl());

       const scale = new TrimbleMaps.ScaleControl({
         maxWidth: 80,
         unit: 'imperial'
       });
       map.addControl(scale);

    } 

  }, [modalShow])
 
  return (
    <>
      <div className="tab">
        <div className="row blue_header_twosidespace">
          <div className="col-md-4">
            <p className="profile_left_text_trailers">
              {trailerById?.trailer_id ? trailerById?.trailer_id : "No data"} - {trailerById?.commoditygroup?.description ? trailerById?.commoditygroup?.description : "No data"} ({trailerById?.commoditygroup?.code ? trailerById?.commoditygroup?.code : "No data"})
            </p>
          </div>
          <div className="col-md-4">
            <div className="profile_top_left_text1">
              Terminal:
              <text className="text_color_white">
                
                  {`${trailerById.terminal.terminal_id} - ${trailerById.terminal.name}`
                  ?? "No Data"}
              </text>
            </div>
          </div>
          <div className="col-md-4 d-flex justify-content-sm-between">
            <div className="profile_top_left_text1">
              Region:
              <text className="text_color_white">
                {trailerById.region ? trailerById.region : "No Data"}
              </text>
            </div>
            <Tooltip title={trailerById.eqstat}>
              <div className={trailerStatusClass}></div>
            </Tooltip>
          </div>
        </div>

        <div className="row blue_header_twosidespace">
          <div className="col-md-4">
            <div className="title_for_trailer">
              <span>
                License Plate:{" "}
                <text className="text_color_white">
                  {trailerById.eqlicenseplate
                    ? trailerById.eqlicenseplate
                    : "No Data"}
                </text>
              </span>
              <span>
                License State:{" "}
                <text className="text_color_white">
                  {trailerById.eqlicensestate
                    ? trailerById.eqlicensestate
                    : "No Data"}
                </text>
              </span>
              <span>
                Next PM Date:{" "}
                <text className="text_color_white">
                  {trailerById.pm_due_date_utc
                    ? convertDateTime(trailerById.pm_due_date_utc)
                    : "No Data"}
                </text>
              </span>
            </div>
          </div>
          <div className="col-md-4">
            <div className="profile_top_left_text1">
              Current Planner:{" "}
              <text className="text_color_white">
                {trailerById.planner === null ||
                trailerById.planner === undefined
                  ? "No Data"
                  : trailerById.planner}
              </text>
            </div>
          </div>
          <div className="col-md-4">
            <div>
              <p className="profile_bottom_drescription_heading">
                Current Location
              </p>
              {trailerLastLocation.lat>0 ?(
              <p className="text_color_white">
                {`${trailerLastLocation?.lat},${trailerLastLocation?.lng}`}
               
                
                 {/* {trailerById?.latitude ? trailerById?.latitude : "No Data"}
                 ,&nbsp;
                 {trailerById?.longitude ? trailerById?.longitude : "No Data"}  */}
                <span className="icon_for_location">
                  <i
                      className="fa fa-map-marker ml_10 cp"
                    aria-hidden="true"
                    onClick={() => openTrailerModal(true)}
                  ></i>
                </span>
              </p>):isLoading?(
                <Skeleton variant="rectangular" className="skeleton_loader"/>
              ):<p>No Data</p>
              }
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            CURRENT LOCATION
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div id="map" style={{ height: "400px", width: "100%", }}></div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={(e) => setModalShow(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TrailerHeaderInfo;
