import React, { useState, useEffect, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { makeStyles } from "@mui/styles";
import ProgressBar from "react-bootstrap/ProgressBar";
import Button from "react-bootstrap/Button";
import TextField from "@mui/material/TextField";
import {getAllTerminals} from "../../services/terminalService";
import { Autocomplete, TreeView, TreeItem } from "@mui/lab";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {getAllPlanningProfiles, planningProfileByTerminalId, createPlanningProfile} from "../../services/userService";
import PlanningProfileList from "../planningProfileList/planningProfileList";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import { ContextData } from "../../components/appsession";
import { Checkbox, FormControlLabel } from "@mui/material"
import { Box } from "@mui/material"

const PlanningProfile = (props) => {
  const [userData, setUserData] = useContext(ContextData);
  const planningdata = useContext(ContextData);
    const [modalOpen, setModalOpen] = useState(false)
    const [allTerminal, setAllTerminal] = useState([])
    const [terminal_id, setTerminal_id] = useState()
    const [planningDataCg, setPlanningDataCg] = useState([])
    const [planningDataShippers, setPlanningDataShippers] = useState([])
    const [planningProfile, setPlanningProfile] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const [selected, setSelected] = useState([]);
    const [shipperSelected, setShipperSelected] = useState([])
    const [planningprofileName, setPlanningprofileName] = useState();
    const [tablerefreshdata, settablerefreshdata] = useState([]);
    const [defaultPlanerChecked, setdefaultPlanerChecked] = useState(false);
    const [isIndeterminate, setIsIndeterminate] = useState(false);
    const [planningprofilePermission,setplanningprofilePermission] = useState({})
    
//   const permission = userData?.roles?.rolespermission.find(planningpermission =>
//     planningpermission?.permissiondetail.code === "PlanningProfile")
const isNodeSelected = React.useMemo(() => {
    const selectedMap = {};
    shipperSelected.forEach((id) => {
      selectedMap[id] = true;
    });

    const traverseSelected = (node) => {
      if (!Array.isArray(node.children)) {
        return selectedMap[node.id] || false;
      }

      return (
        node.children.every((child) => traverseSelected(child)) &&
        selectedMap[node.id]
      );
    };

    return traverseSelected;
  }, [shipperSelected]);
useEffect(() => {
    if (userData?.roles?.rolespermission) {
        const planningprofile_permission = userData?.roles?.rolespermission?.find(planningpermission =>
            planningpermission?.permissiondetail?.code === "PlanningProfile")
            setplanningprofilePermission(planningprofile_permission)
    }

}, [userData?.roles])

    useEffect(() => {
        const terminal = getAllTerminals().then(res => setAllTerminal(res))
        getAllPlanningProfiles().then(res => setPlanningProfile(res));
    }, [planningProfile.length])

    const handleProfileNameChange = (event) => {
        setPlanningprofileName(event.target.value);
    }
	const handledefaultplanner =(event)=>{
		setdefaultPlanerChecked(event.target.checked);
	}
    const handleChange =async (e, value) => {
        if(value !== null){
            const response = await planningProfileByTerminalId(value.code)
            const commodity_groups = response.data.data.commodity_groups;
            const shippers = response.data.data.shippers.slice(0, 100);
            setTerminal_id(value.code);
            setPlanningDataCg(commodity_groups);
            setPlanningDataShippers(shippers);
            setModalOpen(true)
            let terminalId = planningProfile.map(profile => profile.terminal_id)
                if(terminalId.includes(value.code)){
                    setdefaultPlanerChecked(false)
             }
                 else{
                    setdefaultPlanerChecked(true)
                }
        }else{
            setdefaultPlanerChecked(false)
        }
    }

    const savePlanningProfile = async () => {
        try {
            if (!planningprofileName) {
                NotificationManager.error("Enter Planning Profile Name", "Error");
            }

            else {
                // let cg_to_save = selected.filter(x => x.includes("cgel_"));
                // cg_to_save = cg_to_save.map(x => x.replace("cgel_", ""));
                let cg_to_save = selected;
                let planningprofile_json = {
                    "terminal_id": terminal_id,
                    "commodity_groups": cg_to_save,
                    "shippers": shipperSelected
                }
                let request_data = {
                    "name": planningprofileName,
                    "terminal_id": terminal_id,
                    "planningprofile_json": JSON.stringify(planningprofile_json),
                    "created_by": planningdata.userId,
                    "is_default_planner":defaultPlanerChecked
                }
                let response = await createPlanningProfile(request_data);
                if (response?.response?.status==500 ||response?.response?.status==409) {
                    setModalOpen(false);
                    if(response?.response?.status==500){
                        NotificationManager.error("something went wrong", "Error");
                    }
                    if(response?.response?.status==409){
                        NotificationManager.error(response.response.data.message, "Error");
                    }
                    getAllPlanningProfiles().then(res => props.parentcallback(res));            
                   
                    setPlanningDataCg([]);
                    setPlanningDataShippers([]); 
                    setPlanningprofileName();
                    setExpanded([]);
                    setSelected([]);
                    setShipperSelected([]);
                    setdefaultPlanerChecked(false)
                    // const terminal = getAllTerminals().then(res => setAllTerminal(res))
                    getAllPlanningProfiles().then(res => setPlanningProfile(res));
                    return;
                }
                if (response.data.data.id) {
                    setModalOpen(false);
                    NotificationManager.success("Planning Profile Added", "Success");
                    getAllPlanningProfiles().then(res => props.parentcallback(res));            
                   
                    setPlanningDataCg([]);
                    setPlanningDataShippers([]); 
                    setPlanningprofileName();
                    setExpanded([]);
                    setSelected([]);
                    setShipperSelected([]);
                    setdefaultPlanerChecked(false)
                    // const terminal = getAllTerminals().then(res => setAllTerminal(res))
                    getAllPlanningProfiles().then(res => setPlanningProfile(res));
                }
            }
        }
       catch(error){

    NotificationManager.error(error, "Error");

}
       
        
    }
    const openAddModal=()=>{
		setModalOpen(true)
        setdefaultPlanerChecked(false)
                setPlanningDataCg([])
                setPlanningDataShippers([])
                setPlanningprofileName()
                setExpanded([])
                setSelected([])
                setShipperSelected([])
			}
            const commodityData = {
                id: "cg_top",
                name: "Commodity",
                children: planningDataCg.map((cg_item) => {
                    return {
                        id: cg_item.code,
                        name: cg_item.description,
                    }
                }),
            }
            function getChildById(node, id) {
                let array = []
                function getAllChildCommodity(nodes) {
                    if (nodes === null) return []
                    array.push(nodes.id)
                    if (Array.isArray(nodes.children)) {
                        nodes.children.forEach((node) => {
                            array = [...array, ...getAllChildCommodity(node)]
                            array = array.filter((v, i) => array.indexOf(v) === i)
                        })
                    }
                    return array
                }
                function getNodeById(nodes, id) {
                    if (nodes.id === id) {
                        return nodes
                    } else if (Array.isArray(nodes.children)) {
                        let result = null
                        nodes.children.forEach((node) => {
                            if (!!getNodeById(node, id)) {
                                result = getNodeById(node, id)
                            }
                        })
                        return result
                    }
        
                    return null
                }
        
                return getAllChildCommodity(getNodeById(node, id))
            }
            function getOnChange(checked, nodes,length) {
                const allNode = getChildById(commodityData, nodes.id)
                let array = checked
                ? [...selected, ...allNode]
                : selected.filter((value) => !allNode.includes(value))
                array = array.filter((v, i) => {
                    return array.indexOf(v) === i
                })
                if(nodes.id != 'cg_top' && !checked){
                    array = array.filter(el => !(el=='cg_top'))
                }
                if(!(array.includes('cg_top'))){
                    if(array.length == length){
                        array.push('cg_top')
                    }
                }
                setSelected(array)
                
            }
            const renderCommodityTree = (nodes,length) => (
                <TreeItem
                    key={nodes.id}
                    nodeId={nodes.id}
                    label={
                        <FormControlLabel
                            control={
                                <Checkbox
                                    size="small"
                                    checked={selected.some((item) => item === nodes.id)}
                                    onChange={(event) =>{getOnChange(event.currentTarget.checked, nodes,length)}
                                    }
                                    onClick={(e) => e.stopPropagation()}
                                    />
                            }
                            label={<>{nodes.name}</>}
                            key={nodes.id}
                        />
                    }
                    >
                    {Array.isArray(nodes.children)
                        ? nodes.children.map((node) => renderCommodityTree(node,nodes.children.length))
                        : null}
                </TreeItem>
            )
            const shipperData = {
                id: "shipper_top",
                name: "Shipper",
                children: planningDataShippers.map((item) => {
        
                    const obj = {
            
                        id: item.company_location_id,
            
                        name: item.location_name,
            
                        children: [
                            {
                                id: item.company_location_id+"_"+"COMMODITY",
                                name: "Commodity Group",
                                children: planningDataCg.map(cg_item=>{
                                    return {
                                        id: item.company_location_id+"_"+"COMMODITY"+"-" +cg_item.code,
                                        name: cg_item.description,
                                    }
                                })
                            }
                            
                        ]
                    }
                    return obj;
            
                }),
            }
        
            function getShipperChildById(node, id) {
                const uniqueIds = new Set();
              
                function getAllChildShipper(nodes) {
                  if (nodes === null) return;
              
                  uniqueIds.add(nodes.id);
              
                  if (Array.isArray(nodes.children)) {
                    nodes.children.forEach((child) => {
                      getAllChildShipper(child);
                    });
                  }
                }
              
                function getNodeById(nodes, id) {
                  if (nodes.id === id) {
                    return nodes;
                  }
              
                  if (Array.isArray(nodes.children)) {
                    let result = null;
              
                    for (const child of nodes.children) {
                      result = getNodeById(child, id);
              
                      if (result !== null) {
                        return result;
                      }
                    }
                  }
              
                  return null;
                }
              
                const targetNode = getNodeById(node, id);
                if (targetNode !== null) {
                  getAllChildShipper(targetNode);
                }
              
                return Array.from(uniqueIds);
              }
              
        
            function getOnChangeShipper(checked, nodes, length) {
                let commodityParent;
                let shipperChild;
                const allNode = getShipperChildById(shipperData, nodes.id)
                let array = checked
                    ? [...shipperSelected, ...allNode]
                    : shipperSelected.filter((value) => !allNode.includes(value))
                array = array.filter((v, i) => array.indexOf(v) === i)
                commodityParent = nodes.id.split("-")[0]
                shipperChild = commodityParent.split("_")[0]
                if(nodes.id != shipperChild && !checked){
                    array = array.filter(el => !(el == shipperChild))
                    if(nodes.id != commodityParent && !checked){
                        array = array.filter(el => !(el == commodityParent))
                    }
                }
                let newArr = [] 
                array.map(el => {
                    if(el.includes(commodityParent + '-')){
                        newArr.push(el)
                    }
                })
                if(newArr?.length == length){
                    array = [...array,commodityParent,shipperChild]
                }
                if(array.includes(commodityParent)){
                    array.push(shipperChild)
                }
                setShipperSelected(array)
            }
           
            const renderShipperTree = (nodes, length) => (
                
                <TreeItem
                    key={nodes.id}
                    nodeId={nodes.id}
                    label={
                        <FormControlLabel
                            control={
                                <Checkbox
                                    size="small"
                                    checked={isNodeSelected(nodes)}
                                    onChange={(event) =>
                                        getOnChangeShipper(event.currentTarget.checked, nodes, length)
                                    }
                                    onClick={(e) => e.stopPropagation()}
                                />
                            }
                            label={<>{nodes.name}</>}
                            key={nodes.id}
                        />
                    }>
                        {Array.isArray(nodes.children)
                        ? nodes.children.map((node) => renderShipperTree(node, nodes.children.length))
                        : null}
                </TreeItem>
            )
    return (
        <>
           
            <div className="row">
                <div className="col-xl-12 ml_12">
                    <div className="card card_shadow1">
                        <div className="card-body1 ">
                            <div className="table-responsive">
                                <div className="addbutton role_add_adjust mr_20">
                                    {planningprofilePermission?.can_edit?(
                                    <button
                                    type="button"
                                    className="btn_blue btn-blue ml_10" 
                                    
                                    onClick={() => openAddModal()} >
                                    ADD
                                </button>
                                    ):(
                                        <button
                                        type="button"
                                        className="btn_blue btn-blue ml_10" 
                                        disabled
                                         >
                                        ADD
                                    </button>
                                    )
                                }
                                    

                                </div>
                                {/* <PlanningProfileList user={userData} updateUser={setUserData} planningProfile={planningProfile} /> */}
                            </div>
                        </div>
                        <NotificationContainer />
                    </div>
                </div>
            </div>
            <Modal
                show={modalOpen}
                onHide={() => setModalOpen(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton >
                    <Modal.Title id="contained-modal-title-vcenter">
                        Create Planning Profile
                    </Modal.Title>
                </Modal.Header>



                <Modal.Body>
                    <label for="exampleFormControlInput1">Terminal</label>
                    <div className="meterial_autocomplete">
                        <Autocomplete
                            disablePortal
                            id="terminals"
                            options={allTerminal}
                            getOptionLabel={(options) => `${options.full_terminal_name}`}
                            sx={{ width: 300 }}
                            onChange={handleChange}
                            renderInput={(params) => <TextField {...params} variant="outlined"
                                fullWidht={true} placeholder="Select a Terminal" />}
                        />
                    </div>
                    {/* <div className="planning_profile_popup_continue">
                        <Button onClick={() => populateAccessTree(terminal_id)}>Continue</Button>
                    </div> */}


                    <label for="exampleFormControlInput1">Planning Profile Name</label>

                    <div className="planning_profile_popup_textbox">
                        <div className="meterial_autocomplete">
                            <TextField id="outlined-basic"
                                placeholder="Enter Planning Profile Name"
                                variant="outlined"
                                value={planningprofileName}
                                onChange={handleProfileNameChange}
                            />
                        </div>
                    </div>
                    <div className="mt_10 yard_chk">
                        <label for="typeHeadEditIsConfirmation">Default Planning Profile</label>
                        <input type="checkbox" className=' ml_6'
                            onChange={handledefaultplanner}
                            checked={defaultPlanerChecked}
                        />
                    </div>

                    <div className="plnning_treeview_section">
                        <TreeView
                            defaultCollapseIcon={<ExpandMoreIcon />}
                            defaultExpandIcon={<ChevronRightIcon />}
                        >
                            <>
                                {planningDataCg && planningDataCg.length > 0 ? (
                                    <TreeView
                                        defaultCollapseIcon={<ExpandMoreIcon />}
                                        defaultExpandIcon={<ChevronRightIcon />}
                                    >
                                        {renderCommodityTree(commodityData)}
                                    </TreeView>
                                ) : null}
                                {planningDataShippers && planningDataShippers.length > 0 ? (
                                    <TreeView
                                        defaultCollapseIcon={<ExpandMoreIcon />}
                                        defaultExpandIcon={<ChevronRightIcon />}>
                                        {renderShipperTree(shipperData)}
                                    </TreeView>
                                ) : null}
                            </>
                        </TreeView>
                    </div>
                    <div className="planning_profile_popup_continue">
                        <button
                            type="button"
                            className="btn_blue btn-blue ml_10" onClick={savePlanningProfile} >
                            Save
                        </button>
                    </div>
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>
        </>
    );
};

export default PlanningProfile;
