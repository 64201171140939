import '../../assets/css/icons.min.css'
import '../../assets/css/app.min.css'
import list_icon from '../../assets/images/List_icon.svg'
import planning from '../../assets/images/planning blck.svg'
import driverPayIcon from '../../assets/images/driver-pay.svg'
import notification from '../../assets/images/notification.svg'
import setting from '../../assets/images/setting.svg'
import chat from '../../assets/images/chat.svg'
import tankwash from "../../assets/images/tankwash_img.png"
import user_main from '../../assets/images/user_main.svg'
import React, { useState, useEffect, useContext, useRef, useMemo } from "react";
import { ContextData } from "../../components/appsession";
import { useHistory,Link } from "react-router-dom";
import { useMsal, useAccount } from "@azure/msal-react";
import LocalCarWashIcon from '@mui/icons-material/LocalCarWash';

function AppBar(props) {
  const [userData, setuserData] = useContext(ContextData);
  const { instance, accounts } = useMsal();
  const { unreadCount, parentCallbackHandler }= props;
  const [yardCheckEnabled, setyardCheckEnabled] = useState(false)
  const [planningCheckEnabled, setplanningCheckEnabled] = useState(false)
  const [tankwashEnabled, settankwashEnabled] = useState(false)
  const history = useHistory();
  const [itemSelected, setitemSelected] = useState("")
  const [driverpay, setdriverpay] = useState(false)

 

  const clickHandler = (e, href) => {
    if (e.metaKey || e.ctrlKey) {
      // Allow normal link behavior if Ctrl or Meta key is pressed
      return;
    }
    e.preventDefault();
    history.push(href);
    parentCallbackHandler(href);   
  };
 
  useEffect(() => {
    try {
      
      console.log("🚀 ~ file: index.js:42 ~ useEffect ~ userData:", userData)
      if (userData?.roles?.rolespermission) {
        
        let permissionList = userData?.roles?.rolespermission;
        permissionList.forEach(permission => {
          if (permission.permissiondetail.code === "YardCheck") {
              setyardCheckEnabled(permission.can_view)
          }
          if (permission.permissiondetail.code === "TankWash") {
              settankwashEnabled(permission.can_view)
          }
          if (permission.permissiondetail.code === "Planning") {
              setplanningCheckEnabled(permission.can_view)
          }
          if (permission.permissiondetail.code === "DriverPay") {
              setdriverpay(permission.can_view);
          }
        })

      }
    }
    catch (err) {

    }
    
  
  }, [userData])
  return (
    <div className="sidebar-icon-menu h-100" data-simplebar="">
  <nav className="nav flex-column" style={{marginTop:"47px"}}>
    <div>
      {planningCheckEnabled && (
        <Link
          to="/planner"
          className={`nav-link cp ${itemSelected === "/planner" ? "svgactive" : ""}`} 
          title="Planning Board"
          
        >
          <img
            src={list_icon}
            className="svg_icon_size"
            onClick={(e) => clickHandler(e, '/planner')}
            alt="Planning Board Icon"
          />
        </Link>
      )}

      {yardCheckEnabled && (
        <Link
          to="/yardcheck"
          className={`nav-link cp ${itemSelected === "/yardcheck" ? "svgactive" : ""}`}
          title="Yard Check"
        >
          <img
            src={planning}
            className="svg_icon_size"
            onClick={(e) => clickHandler(e, '/yardcheck')}
            alt="Yard Check Icon"
          />
        </Link>
      )}
      
     
      {tankwashEnabled && (
        <Link
          to="/tankwash"        
          title="Tank Wash"
        >
        <LocalCarWashIcon 
         sx={{
          color: "black",
          cursor: "pointer",
          width: "44px",
          height: "44px",
          marginLeft:"12px",
          padding: "10px",
          backgroundColor: "transparent", 
          '&:hover': {
            backgroundColor: "#1886E7", 
            borderRadius: "50%", 
          },
        }}
        onClick={(e) => clickHandler(e, '/tankwash')}
        />
        </Link>
      )}

      {driverpay && (
        <Link
          exact
          to="/driverpay"
          className={`nav-link cp ${itemSelected === "/" || itemSelected === "/driverpay" ? "svgactive" : ""}`}
          title="Driver pay"
        >
          <img
            src={driverPayIcon}
            className="svg_icon_size_driver"
            onClick={(e) => clickHandler(e, '/driverpay')}
           
            alt="Driver Pay Icon"
          />
        </Link>
      )}
    </div>

    <div className="mt_100 side_bottom">
      <Link to="#dashboard" className="nav-link" title="Dashboard">
        <img src={chat} className="svg_icon_size" alt="Dashboard Icon" />
      </Link>

      <Link
        to="/alerts"
        className={`nav-link pr cp ${itemSelected === "/alerts" ? "svgactive" : ""}`}
        title="Alerts"
      >
        <span className="badge alertsbadge badge-pill badge-danger">{unreadCount}</span>
        <img
          src={notification}
          className="svg_icon_size"
          onClick={(e) => clickHandler(e, '/alerts')}
          alt="Alerts Icon"
        />
      </Link>

      <Link
         to="/users"
        className={`nav-link cp ${itemSelected === "/users" ? "svgactive" : ""}`}
        title="Settings"
      >
        <img
          src={setting}
          className="svg_icon_size"
          onClick={(e) => clickHandler(e, '/users')}
          alt="Settings Icon"
        />
      </Link>

      <Link
        to="/profile"
        className={`nav-link cp ${itemSelected === "/profile" ? "svgactive" : ""}`}
        title="Profile"
      >
        <img
          src={user_main}
          className="svg_icon_size"
          onClick={(e) => clickHandler(e, '/profile')}
          
          alt="Profile Icon"
        />
      </Link>
      <div className="signoutsection" title='Log Out'> 
                <button type="button" onClick={() => instance.logout()} className="btns_signout"><i className="fa fa-sign-out log_out_icon"></i></button>
              </div>
    </div>
  </nav>
</div>

  );
}

export default AppBar;
