

// Application Specific
import User from '../models/userModel';
import Driver from '../models/driverModel'
import Trailer from '../models/trailerModel'
import { BaseConfig } from "../baseConfig";
// import axios from "../utils/axiosInterceptor"
import axios from 'axios';


    export const getUser = async(userId) => { 
        const user = new User();
        let userObject = {}
        // Call API using Await
        const url = BaseConfig.ApiEndPoint + "/getpusers/" + userId;
        const userApiData = await axios.get(url);
        userObject = userApiData.data.data
        return Promise.resolve(userObject);
    }

    export const getAllUsers = async() => {
        const user = new User();

        // Generate the model using map method.
        let userObject = []
        try {
            const url = BaseConfig.ApiEndPoint + "/users";
            const userApiData = await axios.get(url);
            userObject = userApiData?.data?.data;
        } catch (err) {
            
            return Promise.reject("Failure: Unable to retrieve user list. Please try refreshing again!");
        }

        return Promise.resolve(userObject);
    }

    export const getUsersByTerminal = async(terminalId) => {
        const user = new User();

        // Generate the model using map method.
        let userObject = []
        try {
            const url = BaseConfig.ApiEndPoint + "/getusersbyterminal";
            const data = {
                terminalid: terminalId
            };
            const userApiData = await axios.post(url, data);
            userObject = userApiData.data.data.map(data => user.parseApiUserObject(data));
        } catch (err) {
            return Promise.reject("Failure: Unable to retrieve user list. Please try refreshing again!");
        }

        return Promise.resolve(userApiData);
    }

    export const getPlannersByTerminal = async(terminalId, search) => {
        const user = new User();
        // Generate the model using map method.
        let userObject = []
        try {
            const url = BaseConfig.ApiEndPoint + "/getplannersbyterminal";
            const data = {
                terminalid: terminalId,
                search: search
            };

            const userApiData = await axios.post(url, data);
            userObject = userApiData.data.data.map(data => user.parseApiUserObject(data));
        } catch (err) {
            return Promise.reject("Failure: Unable to retrieve user list. Please try refreshing again!");
        }
        return Promise.resolve(userObject);
    }

    export const createUser = async(usr) => {
        const user = new User();
        // Generate the model using map method.
        let userObject = []
        try {
            const url = BaseConfig.ApiEndPoint + "/createpusers";
            const userApiData = await axios.post(url, usr);
            userObject = userApiData.data.data;
            return userObject
        } catch (err) {
            return Promise.reject("There is an error on adding the user. Please validate the data and try again!")
        }
        return Promise.resolve(userObject);
    }

    export const updateUser = async(usr) => {
        // Pass User Model to API using Await
        const user = new User();
        let roles = [];
        if (typeof usr.roles[0] === 'object' && usr.roles[0] !== null) {
            roles = usr.roles.map(role => role.roleId);
        } else {
            roles = usr.roles
        }

       
        // Generate the model using map method.
        let userObject = []
        try {
            const userObj = {
                "id": usr.userId,
                "ad_id": usr.AdId,
                "name": usr.userName,
                "address": usr.Address,
                "email": usr.Email,
                "phone": usr.Phone,
                "profilepictureurl": "profilepictureurl",
                "profilepicture": "profilepicture",
                "plannerId": usr.Planner,
                "tractorId": "",
                "terminalId": usr.Terminal,
                'isActive': usr.isActive,
                "roles": roles, //.map(it => it),
                "accessProfiles": usr.AccessProfiles,
                "drivers": usr.drivers,
                "trailers": usr.trailers,
                "terminals": usr.terminals,
                "defaultplanners": usr.DefaultPlanners,
                // "orderColumns": (usr.hasOwnProperty("orderColumns") ? usr.orderColumns : null),
                // "orderDataState": (usr.hasOwnProperty("orderDataState") ? usr.orderDataState : null),
                "orderColumns":  null,
                "orderDataState":null,
                "trailerColumns": (usr.hasOwnProperty("trailerColumns") ? usr.trailerColumns : null),
                "trailerDataState": (usr.hasOwnProperty("trailerDataState") ? usr.trailerDataState : null),
            }

            
            const url = BaseConfig.ApiEndPoint + "/updatepusers/" + usr.id;
            const userApiData = await axios.put(url, userObj);
            userObject = user.parseApiUserObject(userApiData.data.data);
        } catch (err) {
            
            return Promise.reject("There is an error on adding the user. Please validate the data and try again!")
        }

        return Promise.resolve(userObject);
    }

    export const getAssignedDrivers = async(userId, terminalIds) => {
        let allDrivers = []
        const driver=new Driver()
        try {
            let data = {
                user_id: userId,
                "terminalIds": terminalIds,                   
            }
            const url = BaseConfig.ApiEndPoint + "/getassigneddrivers";
            const assignedDrivers = await axios.post(url, data);
            
            allDrivers = assignedDrivers.data.data.map(data => driver.parseApiDriverObject(data));
        } catch (error) {
            return Promise.resolve("There is a problem on retrieving driver data. Please try again later!");
        }
        return Promise.resolve(allDrivers);
    }

    export const unassignDrivers = async(requestBody) =>{
        let allDrivers = []
        const driver=new Driver()
        try {
            const url = BaseConfig.ApiEndPoint + "/unassigndriversfromuser";
            const assignedDrivers = await axios.post(url, requestBody);            
            allDrivers = assignedDrivers.data.data.map(data => driver.parseApiDriverObject(data));
        } catch (error) {
            return Promise.resolve("There is a problem on retrieving driver data. Please try again later!");
        }
        return Promise.resolve(allDrivers);
    }

    export const filterTrailerByTerminalID = async(filterData) => {
        
        const trailer = new Trailer();
        let trailerObject = []
         try {
            const url = BaseConfig.ApiEndPoint + "/getusertrailers";
            let data = {
               "terminalIds":filterData,
           }
            const trailerApiData = await axios.post(url, data);
            trailerObject = trailerApiData.data.data.map(data => trailer.parseApiTrailerObject(data));
         } catch (err){
             return Promise.resolve("There is a problem on retrieving driver data. Please try again!");
         }       
         return Promise.resolve(trailerObject);
     }
     export const getDriversbyTerminalId = async(terminalId) => { 
        const driver = new Driver()
        let assignedDriversObj = [];
      try{
          const url = BaseConfig.ApiEndPoint + "/getDriversbyTerminalId";
          const data = {
              "terminalId": terminalId,
          }
          const driverApiData = await axios.post(url, data);
          assignedDriversObj = driverApiData.data.data.map(data => driver.parseApiDriverObject(data));
          
      }catch (err) {
          return Promise.reject("There is a problem on retrieving Assigned data. Please try again!");
      }
      return Promise.resolve(assignedDriversObj);
  }

    export const updateDefaultPlannerDrivers = async(requestBody) => {
        let updateUserDriversResponse;
        try {
            const url = BaseConfig.ApiEndPoint + "/updatedefaultplannerdrivers";
            const data = requestBody
            updateUserDriversResponse = await axios.post(url, data);
        } catch (err) {
            return Promise.reject("There is a problem on retrieving Assigned data. Please try again!");
        }
        return Promise.resolve(updateUserDriversResponse);
    }

    export const updateUserDriverPlanning = async(requestBody) => {
        let updateUserDriversResponse;
        try {
            const url = BaseConfig.ApiEndPoint + "/updateuserdriversplanning";
            const data = requestBody
            updateUserDriversResponse = await axios.post(url, data);
            
        } catch (err) {
            return Promise.reject("There is a problem on retrieving Assigned data. Please try again!");
        }
        return Promise.resolve(updateUserDriversResponse);
    }

    export const updateAssigneddriversforplanningprofile = async(requestBody) => {
        let updateUserDriversResponse;
        try {
            const url = BaseConfig.ApiEndPoint + "/updateassigneddriversforplanningprofile";
            const data = requestBody
            updateUserDriversResponse = await axios.post(url, data);
            
        } catch (err) {
            return Promise.reject("There is a problem on retrieving Assigned data. Please try again!");
        }
        return Promise.resolve(updateUserDriversResponse);
    }

    export const planningProfileByTerminalId = async (id) => {
        let planningProfileData;
        try{
            const url = BaseConfig.ApiEndPoint + "/populateplanningprofilebyterminalid"
            const payload = {
                terminal_id : id
            }
            planningProfileData = await axios.post(url, payload)
            
        } catch (err) {
            return Promise.reject("There is a problem on retrieving Assigned data. Please try again!");
        }
        return Promise.resolve(planningProfileData);
    }

    export const getAllPlanningProfiles = async() => {
        let allPlanningProfile = [];
        try{
            const url = BaseConfig.ApiEndPoint + "/getallplanningprofiles";
            const apiData = await axios.get(url);
            allPlanningProfile = apiData.data.data;
        } catch (err) {
        }
        return (Promise.resolve(allPlanningProfile));
    }
    export const getAllplanningprofileUsers = async() => {
        let allPlanningProfile = [];
        try{
            const url = BaseConfig.ApiEndPoint + "/getallplanningprofileusers";
            const apiData = await axios.get(url);
            allPlanningProfile = apiData.data.data;
        } catch (err) {
        }
        return (Promise.resolve(allPlanningProfile));
    }

    export const getplanningprofilebyid = async(id) => {
        let getplanningprofile = []
        try{
            
                const payload = {
                    id : id
                }
                const url = BaseConfig.ApiEndPoint + "/getplanningprofilebyid";
                const addApiData = await axios.post(url, payload)
                
                getplanningprofile = addApiData.data.data;
            
        } catch (err) {
        }
        return Promise.resolve(getplanningprofile);

    }

    export const createPlanningProfile = async(payload) => {
        let response;
        try{
            const url = BaseConfig.ApiEndPoint + "/createplanningprofile";
            response = await axios.post(url, payload);
        } catch (err) {
            response=err;
            return response
        }
        return (Promise.resolve(response));
    }

    export const updateplanningprofile = async(payload) => {
        let response;
        try{
            const url = BaseConfig.ApiEndPoint + "/updateplanningprofile";
            response = await axios.post(url, payload);
        } catch (err) {
            return err;
        }
        return (Promise.resolve(response));
    }

    export const updatePlanningProfileUsers = async(data) => {
        let response;
        try{
            let payload = {
                user_id : data.user_id,
                planningprofiles : data.planningprofiles
            }
            const url = BaseConfig.ApiEndPoint + "/updateplanningprofileusers";
            response = await axios.post(url, payload);
        } catch (err) {
        }
        return (Promise.resolve(response));
    }

    export const getPlanningProfileById = async(id) => {
        let planningProfile = []
        try{
            let payload = {
                user_id : id
            }
            const url = BaseConfig.ApiEndPoint + "/getplanningprofilebyuserid";
            const apiData = await axios.post(url, payload);
            planningProfile = apiData.data.data;
        } catch (err) {
        }

        return Promise.resolve(planningProfile)
    }
	export const deletePlanningProfileById = async (id) => {
		let deletePlanningprofileResponse
		try {
			let payload = {
				id: id
			}
			const url = BaseConfig.ApiEndPoint + "/deleteplanningprofile"
			
			deletePlanningprofileResponse = await axios.post(url, payload)
			
		} catch (err) {
			deletePlanningprofileResponse=err
		}
		return Promise.resolve(deletePlanningprofileResponse)
	}

    export const getusersfromadbyid = async (id) => {
        let userResponse;
        try{
            const url = BaseConfig.ApiEndPoint + "/getusersfromadbyid/" + id;
            userResponse = await axios.get(url)
        } catch (err){
        }
        return Promise.resolve(userResponse);
    }

    export const getallpuser = async() => {
        const user = new User();

        // Generate the model using map method.
        let userObject = []
        try {
                const url = BaseConfig.ApiEndPoint + "/getallpuser";
                const userApiData = await axios.get(url);
                userObject = userApiData?.data?.data;
        } catch (err) {
            
            return Promise.reject("Failure: Unable to retrieve user list. Please try refreshing again!");
        }

        return Promise.resolve(userObject);
    }

    export const updatePUser = async(usr) => {
        // Pass User Model to API using Await
       
        const user = new User();
       
        // Generate the model using map method.
        let userObject = [];
        let orderstabsequence = usr?.orderstabsequence;
        let trailertabsequence = usr?.trailertabsequence;
        let userpreferences = usr?.userpreferences
        let usertrailertabsequence = usr?.userpreferences?.usertrailertabsequence
        let userorderstabsequence = usr?.userpreferences?.orderstabsequence
        try {
            let userObj = {
                "id": usr.id,
                "role_id" : usr.roles[0],
                "userpreferences":{}
            }
                if(orderstabsequence && trailertabsequence ){
                    userObj.userpreferences = {orderstabsequence,trailertabsequence}
                }else if( trailertabsequence){
                    userObj.userpreferences = {userorderstabsequence,trailertabsequence}
                }else if(orderstabsequence){
                    userObj.userpreferences = {orderstabsequence, usertrailertabsequence}
                }else{
                    userObj.userpreferences = usr.userpreferences
                }
                const url = BaseConfig.ApiEndPoint + "/updatepusers/" + usr.id;
                const userApiData = await axios.put(url, userObj);
                userObject = userApiData.data.data;
            }
            catch (err) {
            
            return Promise.reject("There is an error on adding the user. Please validate the data and try again!")
        }

        return Promise.resolve(userObject);
    }


export const updateSelectedPlanningProfileOfUser = async (payload) => {
    try {
        const url = BaseConfig.ApiEndPoint + "/updatepusers/" + payload.id;
        const responseData = await axios.put(url, payload);
        return Promise.resolve(responseData.data.data);
    }
    catch (err) {
        return Promise.reject("There is an error on updating user's plannung profile. Please validate the data and try again!");
    }
};


    export const getAuditLogs = async (payload) => {
        let auditLogsData = [];
        try {
            const url = BaseConfig.ApiEndPoint + "/getauditlogs"; 
            
    
            const response = await axios.post(url, payload);
            auditLogsData = response;
        } catch (err) {
            return Promise.reject("There is a problem on retrieving audit logs data. Please try again!");
        }
        return auditLogsData;
    };


    export const userpreference = async (payload) => {
        let userPref = [];
        try {
            const url = BaseConfig.ApiEndPoint + "/userpreference"; 
           
    
            const response = await axios.post(url, payload);
            userPref = response;
        } catch (err) {
            return Promise.reject("There is a problem on retrieving audit logs data. Please try again!");
        }
        return userPref;
    };
    

export const updateFirebaseToken = async (userId, payload) => {
    try {
        const url = BaseConfig.ApiEndPoint + "/updatefirebaseclientid/" + userId;
        const responseData = await axios.patch(url, payload);
        return responseData.data.data;
    }
    catch (err) {
        throw "There is an error on updating user's plannung profile. Please validate the data and try again!";
    }
};

export const sendPushNotification = async (payload) => {
    try {
        const url = BaseConfig.ApiEndPoint + "/sendnotification";
        const responseData = await axios.post(url, payload);
        return responseData;
    }
    catch (err) {
        throw err;
    }
};

