import React, { useContext, useEffect, useState, useRef, useCallback } from "react";
import {
  Grid,
  GridColumn
} from "@progress/kendo-react-grid";
import ProgressBar from "react-bootstrap/ProgressBar";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { DateTime } from "luxon";
import { process } from "@progress/kendo-data-query";
import { updateDriverPayStatus, allDriverpayRecords, allMovementDetailsForEdit, getMovmentById, driverPayApproveSearch } from "../../services/driverPayServer";
import { ContextData } from "../appsession";
import { ToastContainer, toast } from "react-toastify";
import TextField from "@mui/material/TextField";
import { Autocomplete } from "@mui/lab";
import { BaseConfig } from "../../baseConfig";
import debounce from 'lodash/debounce';
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import DriverPayModalEditAuthrization from "./DriverPayModalEditAuthrization";
import AuditLogModalComponent from "./auditLogModal";
import filterOperators from "../../utils/filterOperators";


const DriverPayAuthorizeTable = (props) => {
  const myElementRef = useRef(null);
  const { refresh, canAuthorize, showDriverPay, isCreated, tabSelected, selectedTerminalIDs,statusCode } = props;
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [userData, setuserData] = useContext(ContextData);
  const [driverPageData, setDriverPageData] = useState([]);
  const [authorizeData, setAuthorizeData] = useState({});
  const [itemSelected, setitemSelected] = useState("");
  const [DriverPay, setDriverPay] = useState(false);
  const [isInfoModalShow, setIsInfoModalShow] = useState(false);
  const modalRef = useRef(null);
  const [isloading, setisloading] = useState(false);
  const [imageSrc, setimageSrc] = useState('');
  const [isUpdated, setIsUpdated] = useState(false);
  const [payRateValue, setPayRateValue] = useState("");
  const [unitsValue, setUnitsValue] = useState("");
  const [amountValue, setAmountValue] = useState(0);
  const [payUomValue, setPayUomValue] = useState("");
  const [payUomEditable, setPayUomEditable] = useState(false);
  const [payRateEditable, setPayRateEditable] = useState(false);

  // movements edit state
  const [showMovementModal, setshowMovementModal] = useState(false);
  const [isdataMovementLoading, setisdataMovementLoading] = useState(false);

  const [origin, setOrigin] = useState({
    actual_arrive_origin: null,
    actual_depart_origin: null,
    duration_at_origin_stop: null,
    drive_time: null,
    pickuptimezone: null
  });
  const [destination, setDestination] = useState({
    actual_arrive_destination: null,
    actual_depart_destination: null,
    duration_at_destination_stop: null,
    total_time: null,
    deliverytimezone: null
  });
  const [EditClicked, setEditClicked] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [movementId, Setmovementid] = useState("");
  const [MovementData, setMovementData] = useState([]);
  const [isPayinfoedited, setIsPayinfoedited] = useState(false);
  const [ismovementEdited, setidMovementEdited] = useState(false);
  const [ismovementRequired, setisMovementrequired] = useState(true);
  const [isEditedbuttonclicked, setisEditedbuttonclicked] = useState(false);
  const [isSaveDisbaled,setisSaveDisbaled]=useState(false);
  const [isFormTouched,setFormTouched]=useState(false);

  const [takeNumberOfResult, setTakeNumberOfResult] = useState(25);
  const [skipNumberOfResult, setSkipNumberOfResult] = useState(0)
  const [pageNumber, setPageNumber] = useState(1);
  const [searchTerms, setSearchTerms] = useState([]);
  const [sortData,setSortData] = useState([]);
  const [totalResult, setTotalResult] = useState(0);


  const fieldsToUpdate = [
    "transaction_date",
    "pay_date",
    "authorized_on",
    "approved_on",
    "transfer_on",
    "effective_date",
    "created_on",
    "updated_on"
  ];
  const fieldsToField = [
    "id",
    "driver_full_name",
    "pay_code",
    "pay_uom",
    "units",
    "pay_rate",
    "units",
    "authorized_by",
    "approved_by",
    "order_id",
    "amount"
  ];

  const [dataState, setDataState] = useState({
    skip: skipNumberOfResult,
    take: takeNumberOfResult,
    filter: {
      logic: "and",
      filters: [
        {
          field: "isActive",
          operator: "eq",
        }
      ],
    },
    sort: [],
  });

  const [dataResult, setDataResult] = useState([]);

  // this function runs on every filter chnage in kendo table 
  const dataStateChange = useCallback((event) => {
    let hasUpdatedOn = false;
    const filters = event?.dataState?.filter?.filters || [];
    const sortTerms=event?.dataState?.sort || [];
    
    const terms = filters && filters.length > 0 ? filters.map(({ field, value, operator }) => ({ field, value, operator })) : [];
    const sorts= sortTerms && sortTerms.length>0 ? sortTerms.map(({ field, dir })=>({ field, dir })) : [];
    setSearchTerms(terms);
    setSortData(sorts);
    const take = event?.dataState?.take;
    const skip = event?.dataState?.skip;
    const page = Math.floor(skip / take) + 1;
    setTakeNumberOfResult(take);
    setSkipNumberOfResult(skip);
    setPageNumber(page);
    setDataState(event.dataState);
    // setisplanningProfileChange(false);
  }, []);

 // this is api calling for approved data 
  const debouncedSearchForFilters = useCallback(
    debounce(async (searchTerms, sortData, page, size, terminalName = selectedTerminalIDs, statusName = statusCode) => {
      setIsPageLoading(true);
      const search = await driverPayApproveSearch(searchTerms, sortData, page, size, terminalName, statusName);
      if (search.data.data.length <= 0) {
        setDriverPageData(search.data.data);
        setTotalResult(search.data.count);
      }
      setIsPageLoading(false);
      if (search.data.data.length > 0) {
        console.log("asbsdasdkjadkm",search.data.data)
        setDriverPageData(search.data.data);
      }
      setTotalResult(search.data.count);
    }, 1000),
    [selectedTerminalIDs, statusCode]
  );


  // this useeffect will run on adding something in filter on removing in filter
  useEffect(() => {
    // it will run when there is no filter in kendo
    if (searchTerms.length <= 0 && sortData.length<=0) {
      let newsearchTerms = [];
      let newSort=[];
      debouncedSearchForFilters(newsearchTerms, newSort, pageNumber, takeNumberOfResult, selectedTerminalIDs);
    }else if(searchTerms.length <= 0 && sortData.length>0){
      let newsearchTerms = [];
      debouncedSearchForFilters(newsearchTerms, sortData, pageNumber, takeNumberOfResult, selectedTerminalIDs);
    }
    // it will run when there is filter in kendo
    else if (searchTerms.length > 0) {
      let searchTerm;
      // remove null undefined values of fields
      searchTerm = searchTerms.filter(obj => {
        if (
          fieldsToField.includes(obj.field) &&
          (obj.value === null || obj.value === undefined || obj.value === "")
        ) {
          return false; // remove the object
        }
        return true; // keep the object
      });
      // remove null undefined values of date fields
      searchTerm = searchTerm.filter(obj => {
        if (
          fieldsToUpdate.includes(obj.field) &&
          (obj.value === null || obj.value === undefined || obj.value === "")
        ) {
          return false; // remove the object
        }
        return true; // keep the object
      });

      debouncedSearchForFilters(searchTerm, sortData, pageNumber, takeNumberOfResult, selectedTerminalIDs);
    }
    
  }, [searchTerms, pageNumber, takeNumberOfResult, selectedTerminalIDs, isUpdated, statusCode,sortData]);



// this useEffect will run on refresh and terminal change
useEffect(() => {
  let newsearchTerms = [];
  let newSort=[];
  debouncedSearchForFilters(newsearchTerms, newSort, pageNumber, takeNumberOfResult);
  // after refresh filter must be clean 
  setDataState({
    skip: 0,
    take: takeNumberOfResult,
    filter: {
      logic: "and",
      filters: newsearchTerms
    },
    sort: newSort,
  })
}, [refresh, selectedTerminalIDs, statusCode]);


  // Create a ref to hold the child component instance
  const auditLogComponentRef = useRef();

  // Audit log modal open handler
  const auditLogModalOpenHandler = (props) => {
    // Access the child component's function using the ref
    auditLogComponentRef.current.fetchAllAuditLogData(props.id, "driver_pay");
  };

  const convertdatetime = (duration) => {
    if (duration) {
      let date;
      if (isNaN(duration)) {
        date = Date.parse(duration);
      } else {
        date = parseInt(duration);
      }
      return DateTime.fromMillis(date).toFormat("MM/dd/yyyy").toString();
    } else {
      return "No Data";
    }
  };

  const convertDate=(duration)=>{
    if(duration){
      return DateTime.fromISO(duration).toFormat("MM/dd/yyyy").toString();
    }else{
      return "No Data";
    }
  }

  
  useEffect(() => {
    if (!isEditedbuttonclicked) {
      setPayRateValue(authorizeData?.pay_rate);
      if (authorizeData?.pay_uom == "FLAT") {
        setUnitsValue("1");
      }
      else {
        setUnitsValue(authorizeData?.units);
      }
      setAmountValue(authorizeData?.amount);
      setPayUomValue(authorizeData?.pay_uom);
    }

    setPayUomEditable(authorizeData?.driverpaybase?.pay_uom_editable);
    setPayRateEditable(authorizeData?.driverpaybase?.pay_rate_editable);
    setisMovementrequired(authorizeData?.driverpaybase?.movement_required)

    if (EditClicked) {

    }
    else {
      setOrigin({
        actual_arrive_origin: authorizeData?.movement?.actual_arrive_origin,
        actual_depart_origin: authorizeData?.movement?.actual_depart_origin,
        duration_at_origin_stop: authorizeData?.movement?.duration_at_origin_stop,
        drive_time: authorizeData?.movement?.drive_time,
        pickuptimezone: authorizeData?.movement?.pickuptimezone
      });
      setDestination({
        actual_arrive_destination: authorizeData?.movement?.actual_arrive_destination,
        actual_depart_destination: authorizeData?.movement?.actual_depart_destination,
        duration_at_destination_stop: authorizeData?.movement?.duration_at_destination_stop,
        total_time: authorizeData?.movement?.total_time,
        deliverytimezone: authorizeData?.movement?.deliverytimezone
      });
      setOrderId(authorizeData.order_id)
      Setmovementid(authorizeData.movement_id)
    }
  }, [modalShow, authorizeData, authorizeData?.pay_uom]);


  useEffect(() => {
    if (driverPageData?.length > 0 && driverPageData[0]?.driver?.driver_id && driverPageData[0]?.driver?.driver_name) {
      const updatedDriverPayData = driverPageData.map(item => {
        item.driverFullName = `${item.driver.driver_id} - ${item.driver.driver_name}`;
        item.payCodeDesc = `${item.pay_code} - ${item.pay_description}`;
        return item;
      });
      setDataResult(updatedDriverPayData, dataState);
    }else{
    setDataResult(driverPageData, dataState);
    }
  }, [driverPageData, selectedTerminalIDs]);

  useEffect(() => {
    try {
      if (userData?.roles?.rolespermission) {
        let permissionList = userData?.roles?.rolespermission
        permissionList.forEach(permission => {

          if (permission.permissiondetail.code.match("DriverPay")) {
            if (permission.can_edit === true) {
              setDriverPay(true)
            }
          }
        })

      }
    }
    catch (err) {

    }

    setitemSelected(window.location.pathname)
  }, [userData])

  let rowClickHandler = (item) => {
    setIsUpdated(false);
    setIsPayinfoedited(false);
    setidMovementEdited(false);
    setAuthorizeData((prevState) => {
      return { ...prevState, ...item }
    })
    setModalShow(true);

  };

  function convertMsToHms(milliseconds) {
    if (milliseconds) {
      let seconds = Math.floor(milliseconds / 1000);
      let minutes = Math.floor(seconds / 60);
      let hours = Math.floor(minutes / 60);

      seconds = seconds % 60;
      minutes = minutes % 60;

      let hrs = hours > 1 ? `${hours} hrs` : hours === 0 ? "" : `${hours} hr`;
      let mins = minutes > 1 ? `${minutes} mins` : minutes === 0 ? "" : `${minutes} mins`;
      let secs = seconds > 1 ? `${seconds} secs` : seconds === 0 ? "" : `${seconds} secs`;
      return `${hrs} ${mins} ${secs}`;
      // return `${hrs}${hours && minutes ?  "," : ""} ${mins} ${minutes && seconds ? "and" : ""} ${secs}`;
    } else {
      return "No Data";
    }
  }

  const authorizeCommentChangeHandler = (e) => {
    setFormTouched(true)
    setAuthorizeData(prevState => {
      return { ...prevState, authorized_comment: e.target.value }
    })
  };

  const authorizedHandler = async (authorized, type) => {
    if(isFormTouched){
    await  handleAuthorizeSave()
    }

    if (type == "authorize" && ismovementRequired && !movementId) {
      toast.error("Movement id is required", "Error");
      return;
    }
    if (type == "authorize" && amountValue == 0) {
      toast.error("Amount can not be zero", "Error");
      return;
    }
    if (!authorized) {
      if ((authorizeData.authorized_comment == '') || (authorizeData.authorized_comment == null) || (authorizeData.authorized_comment == undefined)) {
        return toast.error("Please add comment before declining driver pay.", "Error")
      }
    }

    let authorize_payload = {
      authorized_comment: type == "decline" ? `Declined : ${authorizeData.authorized_comment}` : `Authorized : ${authorizeData.authorized_comment}`,
      authorized_by: userData?.email?.split('@')[0],
      authorized_on: DateTime.now(),
      updated_on: DateTime.now(),
      status: "Pending Approval",
      updated_by: userData?.email?.split('@')[0]
    }

    const response = await updateDriverPayStatus(authorizeData.id, authorize_payload);

    if (response.status == 200) {
      setModalShow(false);
      setIsUpdated(true);
      return toast.success("Driver pay has been updated!", "Success")
    } else {
      toast.error("Something went wrong, Please try again later.", "Error");
    }
  };

  const viewInfo = (e, data) => {
    setThumbsSwiper(null)

    if (data.additional_details) {
      setimageSrc(data.additional_details.image_url)

    }

    setIsInfoModalShow(true);
  }

  const clearState = () => {
    setUnitsValue("");
    setPayRateValue("");
    setPayUomValue("");
    setAmountValue("");
    setAuthorizeData({});
    setEditClicked(false);
  }


  const formatDate = (epoch_date, tzone) => {
    
    if (!tzone || tzone === "Eastern Standard Time" || tzone === "EST" || tzone === "EDT" || tzone === "America/New_York") {
      tzone = "America/New_York"
    }
    if (!tzone || tzone === "Central Standard Time" || tzone === "CDT" || tzone === "CST" || tzone === "America/Chicago") {
      tzone = "America/Chicago"
    }
    if (epoch_date > 0) {
      return DateTime.fromMillis(parseInt(epoch_date)).setZone(tzone).toFormat("MM-dd-yyyy HH:mm ZZZZ").toString()
    }
    else {
      return "No Data"
    }

  }

  const calculateAmount = (units, rate, uom) => {
    let amount = 0;
    if (uom === "FLAT") {
      amount = parseFloat(rate);
    } else if (uom === "MILE" || uom === "HOUR") {
      if (units == "" || rate == "") {
        amount = 0
      }
      else {
        amount = parseFloat(units) * parseFloat(rate);
      }
    }
    setAmountValue(amount);
  };

  const handlePayUomChange = (event, newValue) => {
    setFormTouched(true)
    if (newValue == "FLAT") {
      setUnitsValue("1")
    }
    setIsPayinfoedited(true)
    setPayUomValue(newValue);
    calculateAmount(unitsValue, payRateValue, newValue);
  };

  const handlePayRateChange = (event) => {
    setFormTouched(true)
    setIsPayinfoedited(true)
    const newPayRateValue = event.target.value;
    setPayRateValue(newPayRateValue);
    calculateAmount(unitsValue, newPayRateValue, payUomValue);
  };

  const handleUnitsChange = (event) => {
    setFormTouched(true)
    setIsPayinfoedited(true)
    const newUnitsValue = event.target.value;
    setUnitsValue(newUnitsValue);
    calculateAmount(newUnitsValue, payRateValue, payUomValue);
  };

  const handleCloseModal = () => {
    setEditClicked(false);
    setModalShow(false);
    clearState()
    setAuthorizeData({});
    setOrigin({
      actual_arrive_origin: null,
      actual_depart_origin: null,
      duration_at_origin_stop: null,
      drive_time: null,
      deliverytimezone: null
    });
    setDestination({
      actual_arrive_destination: null,
      actual_depart_destination: null,
      duration_at_destination_stop: null,
      total_time: null,
      deliverytimezone: null
    });
    setOrderId("");
    Setmovementid("");
  };

  const handleCloseEditModal = () => {
    setModalShow(true);
    setshowMovementModal(false)
    setMovementData([])
  };

  // movement function calls

  const chooseMovementIdHandler = async () => {
    setFormTouched(true)
    setIsPayinfoedited(true)
    setisEditedbuttonclicked(true);
    setUnitsValue(unitsValue)
    setAmountValue(amountValue);
    setPayUomValue(payUomValue);
    setPayRateValue(payRateValue)
    setModalShow(false);
    setshowMovementModal(true)
    // api calling with driverid
    setisdataMovementLoading(true)

    let response = await allMovementDetailsForEdit(authorizeData?.driver_id);
    let updateddata = []
    let responses = response.data.data
    for (let i = 0; i < responses.length; i++) {
      let item = responses[i];
      item.origin = `${item.pickup.origin_location}`;
      item.bol1 = item?.order?.BOL;
      item.order_type_id = item?.order?.order_type_id;
      item.destination = `${item.delivery.origin_location}`;
      item.origin_actual_arrival = formatDate(item.actual_arrive_origin, item.pickuptimezone)
      item.origin_actual_depart = formatDate(item.actual_depart_origin, item.pickuptimezone)
      item.destination_actual_arrival = formatDate(item.actual_arrive_destination, item.deliverytimezone)
      item.destination_actual_depart = formatDate(item.actual_depart_destination, item.deliverytimezone)
      updateddata.push(item)
    }

    setMovementData(updateddata)
    setisdataMovementLoading(false)
    // const response;
  };

  // all logics of driverpay save button will be there will be there
  const handleAuthorizeSave = async () => {
    if (amountValue == 0) {
      toast.error("Amount can not be zero", "Error");
      return;
     } 
     if(ismovementRequired && !movementId){
      toast.error("Movement id is required", "Error");
      return;
     }
       let payload={};
       if(isPayinfoedited){
          payload = {
            amount: amountValue,
            units: unitsValue,
            pay_uom: payUomValue,
            pay_rate: payRateValue
          };
       }
       if(ismovementEdited){
          payload= {
            movement_id: movementId,
            order_id:orderId,
          };
       }
       if(isPayinfoedited && ismovementEdited){
          payload={
            amount: amountValue,
            units: unitsValue,
            pay_uom: payUomValue,
            pay_rate: payRateValue,
            movement_id: movementId,
            order_id:orderId,
            updated_by: userData?.email?.split('@')[0]
          }
      }
      try {
        const response = await updateDriverPayStatus(authorizeData.id, payload);
        const terminal_payload = {
          terminal_id: selectedTerminalIDs,
          status: "Pending Authorization"
        };
        const needAuthorizeDriverPayResponse = await allDriverpayRecords(terminal_payload);
  
        setDriverPageData(needAuthorizeDriverPayResponse?.data?.data||[]);
        // setIsPageLoading(false);
  
        if (response.status === 200) {
          toast.success("Driver pay has been updated!", "Success");
          setFormTouched(false)
        } else {
          toast.error("Something went wrong. Please try again later.", "Error");
        }
      } catch (error) {
        toast.error("Something went wrong. Please try again later.", "Error");
      }

    setIsPayinfoedited(false);
    setidMovementEdited(false);
  }

  const debouncedSearch = useCallback(
    debounce(async (movementid) => {
     
      if(movementid==""||movementid==null||movementid==undefined){
        setOrderId("")
        return;
      }
      try {
        const payload = {
          id: movementid
        }

        const response = await getMovmentById(payload)
       
         let updateddata = []
        if (response.length > 0) {
         
          setOrderId(response[0].order_id)
          setisSaveDisbaled(false);
          setOrigin({
            actual_arrive_origin: response[0]?.pickup.actual_arrival_utc,
            actual_depart_origin: response[0]?.pickup.actual_departure_utc,
            duration_at_origin_stop: response[0]?.pickup.actual_departure_utc - response[0]?.pickup.actual_arrival_utc,
            drive_time: response[0]?.delivery.actual_arrival_utc && response[0]?.pickup.actual_departure_utc? (response[0]?.delivery.actual_arrival_utc - response[0]?.pickup.actual_departure_utc):0 ,
            pickuptimezone: response[0]?.pickup.pickup_timezone
          });
          setDestination({           
            actual_arrive_destination: response[0]?.delivery.actual_arrival_utc,
            actual_depart_destination: response[0]?.delivery.actual_departure_utc,
            duration_at_destination_stop:response[0]?.delivery.actual_departure_utc- response[0]?.delivery.actual_arrival_utc,
            total_time:(response[0].delivery.actual_departure_utc>0&&response[0].pickup.actual_arrival_utc)?( response[0]?.delivery.actual_departure_utc-response[0].pickup.actual_arrival_utc):0,
            deliverytimezone: response[0]?.delivery.delivery_timezone
          });
        }        
        else {
          setOrderId("")
          // setTransactionDate(DateTime.now())
          toast.error("Please Enter Correct Movement Id", "Error");
        }

      } catch (error) {
        return error;
      }
    }, 1000),
    []
  );

  const movementIdHandler =async (event) => {
    setFormTouched(true)
    setIsPayinfoedited(true)
      const value = event.target.value ?? '';
      if (value !== '') {
        setisSaveDisbaled(true);
      } else {
        setisSaveDisbaled(false);
      }
      debouncedSearch(event.target.value)
      Setmovementid(event.target.value);
  
  };

  const handleEditMovement = () => {
    setFormTouched(true)
    setidMovementEdited(true);
  };

  return (
    <>
      <ToastContainer position='top-right' theme='colored' />
      <div className='table-responsive '>
      <div className="addbutton">
        {isPageLoading?(
                    <div>
                      <ProgressBar animated now={100} />
                      <div className="middle loader--text1"> </div>
                    </div>
                  ) :
            <Grid
              nodeRef={myElementRef}
              filter={dataState.filter}
              sort={dataState.sort}
              filterable={true}
              sortable={true}
              filterOperators={filterOperators}
              expandField="expanded"
              pageable={{
                  pageSizes: [25, 50, 100],
                  info: true,
                  previousNext: true,
                  buttonCount: 10,
              }}
              total={totalResult}
              resizable={true}
              skip={dataState.skip}
              take={dataState.take}
              data={dataResult}
              onDataStateChange={dataStateChange}
              onRowClick={(e) => rowClickHandler(e.dataItem)}
            >
              <GridColumn
                field='id'
                title='Id'
                filterable={true}
                filter="text"
                width="200px"
                cell={(e) => {
                  return (
                    <td>
                      {e.dataItem.id ? parseInt(e.dataItem.id) : "No Data"}
                    </td>
                  );
                }}
              />
              <GridColumn
                field="terminal_fullName"
                title='Terminal'
                filterable={true}
                width="250px"
                cell={(e) => {
                  return (
                    <td>
                      {e.dataItem.terminal.terminal_fullName ? e.dataItem.terminal.terminal_fullName : "No Data"}
                    </td>
                  );

                }}
              />

              <GridColumn
                field="driver_full_name"
                title='Driver'
                filterable={true}
                width="250px"
                cell={(e) => {
                  const driverFullName = `${e.dataItem.driver_id ? e.dataItem.driver_id : "No Data"} - ${e.dataItem.driver.driver_name ? e.dataItem.driver.driver_name : "No Data"}`;
                  return (
                    <td>
                      {driverFullName}
                    </td>
                  );
                }}
              />
              <GridColumn
                field='pay_code'
                title='Pay Code'
                width="220px"
                filterable={true}
                cell={(e) => {
                  const payCodeDesc = `${e.dataItem.pay_code ? e.dataItem.pay_code : "No Data"} - ${e.dataItem.pay_description ? e.dataItem.pay_description.toUpperCase() : "No Data"}`
                  return (
                    <td>
                      {payCodeDesc}
                    </td>
                  );
                }}
              />
              <GridColumn
                field='driver_comment'
                title='Driver Comment'
                width="220px"
                filterable={true}
                cell={(e) => {
                  return (
                    <td>
                      {e.dataItem.driver_comment ? e.dataItem.driver_comment : "No Data"}
                    </td>
                  );

                }}
              />
              <GridColumn
                field='pay_uom'
                title='Unit of Measure'
                width="150px"
                filterable={true}
                cell={(e) => {
                  return (
                    <td>
                      {e.dataItem.pay_uom ? e.dataItem.pay_uom : "No Data"}
                    </td>
                  );
                }}
              />
              <GridColumn
                field='units'
                title='Units'
                filterable={true}
                width="150px"
                cell={(e) => {
                  return (
                    <td>
                      {e.dataItem.units ? Number(e.dataItem.units).toFixed(2) : "No Data"}
                    </td>
                  );
                }}
              />
              <GridColumn
                field='pay_rate'
                title='Rate'
                width="150px"
                filterable={true}
                cell={(e) => {
                  return (
                    <td>
                      {e.dataItem.pay_rate ? parseFloat(e.dataItem.pay_rate).toFixed(2) : "No Data"}
                    </td>
                  );
                }}
              />
              <GridColumn
                field='amount'
                title='Amount'
                filterable={true}
                width="150px"
                cell={(e) => {
                  return (
                    <td>
                      {e.dataItem.amount ? parseFloat(e.dataItem.amount).toFixed(2) : "No Data"}
                    </td>
                  );
                }}
              />
              <GridColumn
                field='transaction_date'
                title='Transaction Date'
                filter='date'
                format="{0:d}"
                width="250px"
                cell={(e) => {
                  return (
                    <td>
                      {e.dataItem.transaction_date ? convertDate(e.dataItem.transaction_date) : "No Data"}
                    </td>
                  );
                }}
              />
              <GridColumn
                field='order_id'
                title='Order ID'
                width="150px"
                filterable={true}
                cell={(e) => {
                  return (
                    <td>
                      {e.dataItem.order_id ? e.dataItem.order_id : "No Data"}
                    </td>
                  );
                }}
              />
              <GridColumn
                field='movement_id'
                title='Movement ID'
                width="150px"
                filterable={true}
                cell={(e) => {
                  return (
                    <td>
                      {e.dataItem.movement_id ? e.dataItem.movement_id : "No Data"}
                    </td>
                  );
                }}
              />
              <GridColumn
                field='transfer_on'
                title='Transferred Date'
                width="250px"
                filter='date'
                format="{0:d}"
                cell={(e) => {
                  return (
                    <td>
                      {e.dataItem.transfer_on ? convertdatetime(e.dataItem.transfer_on) : "No Data"}
                    </td>
                  );
                }}
              />
              <GridColumn
                field='pay_date'
                title='Pay Date'
                filter='date'
                format="{0:d}"
                width="250px"
                cell={(e) => {
                  return (
                    <td>
                      {e.dataItem.pay_date ?  convertDate(e.dataItem.pay_date) : "No Data"}
                    </td>
                  );
                }}
              />
              <GridColumn
                field='approved_on'
                title='Approved Date'
                filter='date'
                format="{0:d}"
                width="250px"
                cell={(e) => {
                  return (
                    <td>
                      {e.dataItem.approved_on ? convertdatetime(e.dataItem.approved_on) : "No Data"}
                    </td>
                  );
                }}
              />
              <GridColumn
                field='approved_by'
                title='Approved By'
                width="250px"
                filterable={true}
                cell={(e) => {
                  return (
                    <td>
                      {e.dataItem.approved_by ? e.dataItem.approved_by : "No Data"}
                    </td>
                  );
                }}
              />
              <GridColumn
                field='authorized_on'
                title='Authorized Date'
                width="250px"
                filter='date'
                format="{0:d}"
                cell={(e) => {
                  return (
                    <td>
                      {e.dataItem.authorized_on ? convertdatetime(e.dataItem.authorized_on) : "No Data"}
                    </td>
                  );
                }}
              />
              <GridColumn
                field='authorized_by'
                title='Authorized By'
                filterable={true}
                width="250px"
                cell={(e) => {
                  return (
                    <td>
                      {e.dataItem.authorized_by ? e.dataItem.authorized_by : "No Data"}
                    </td>
                  );
                }}
              />
              <GridColumn
                field='created_on'
                title='Created On'
                filter='date'
                format="{0:d}"
                width="250px"
                cell={(e) => {
                  return (
                    <td>
                      {e.dataItem.created_on ? convertdatetime(e.dataItem.created_on) : "No Data"}
                    </td>
                  );
                }}
              />
              <GridColumn
                field='created_by'
                title='Created By'
                width="250px"
                cell={(e) => {
                  return (
                    <td>
                      {e.dataItem.created_by ? e.dataItem.created_by : "No Data"}
                    </td>
                  );
                }}
              />
              <GridColumn
              field='Action'
              title='Action'
              width='150px'
              cell={(e) => {
                return(
                  <td>
                    <button
                      type="button"
                      className="btn-blue_schedule "
                      onClick={(i) => auditLogModalOpenHandler(e.dataItem)}
                    >
                      Audit Log
                    </button>
                  </td>
                );
              }}
             />
            </Grid>
           } 
          </div>
      </div>

      {/* Driver Authorized Pay */}

      <Modal
        show={modalShow}
        onHide={() => {
          setModalShow(false)
          clearState()
          setisEditedbuttonclicked(false);
        }}
        onClose={handleCloseModal}
        dialogClassName="Authorize_Driver_Pay_width"
        aria-labelledby='contained-modal-title-vcenter'
        centered
        ref={modalRef}
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>Authorize Driver Pay</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="authorize_pay">
            <div className="driverpay_info_general">
              <div className="driverpay_info_genera_right">
                <p className="driverpay_info_item2">
                  <span className="general_info_label">Id :</span>
                  <span className="general_info_value">{authorizeData.id ? authorizeData.id : "No Data"}</span>
                </p>
                <p className="driverpay_info_item2">
                  <span className="general_info_label">Driver :</span>
                  <span className="general_info_value">{authorizeData.driver_id ? authorizeData.driver_id + " - " + authorizeData.driver.driver_name : "No Data"}</span>
                </p>
                <p className="driverpay_info_item2">
                  <span className="general_info_label">Created On :</span>
                  <span className="general_info_value">{authorizeData?.created_on ? DateTime.fromMillis(authorizeData.created_on).toFormat("MM-dd-yyyy hh:mm") : "No Data"}</span>
                </p>
              </div>
              <div className="driverpay_info_genera_left">
                <p className="driverpay_info_item2">
                  <span className="general_info_label">Terminal :</span>
                  <span className="general_info_value">{authorizeData.terminal_id ? authorizeData.terminal.terminal_fullName : "No Data"}</span>
                </p>

                <p className="driverpay_info_item2">
                  <span className="general_info_label">Created By :</span>
                  <span className="general_info_value">{authorizeData?.created_by ? authorizeData.created_by : "No Data"}</span>
                </p>
              </div>
            </div>

            <div className="driverpay_info_move">
              <div className="move_info_header"><p>Pay Info </p></div>
              <div className="move_info_content">
                <p className="move_info_item">
                  <span className="general_info_label">Pay Description :</span>
                  <span className="general_info_value">{authorizeData.pay_description ? authorizeData.pay_code + " - " + authorizeData.pay_description : "No Data"}</span>
                </p>
                <p className="move_info_item">

                  <span className="general_info_label">Status :</span>

                  <span className="general_info_value">{authorizeData.status ? authorizeData.status : "No Data"}</span>

                </p>
                <p className="move_info_item1">

                  <span className="general_info_label">Units:</span>

                  {showDriverPay && payUomEditable ? (
                    <>
                      <div className="meterial_autocomplete11 meterial_autocomplete">
                        <TextField
                          id="outlined-number"
                          type="number"
                          variant="outlined"
                          value={unitsValue}
                          disabled={!showDriverPay && !payUomEditable || payUomValue == "FLAT"}
                          onChange={handleUnitsChange}
                          style={{ width: '100px' }}
                        />
                      </div>
                    </>
                  ) :
                    <>
                      <span className="general_info_value">{unitsValue}</span>
                    </>
                  }

                </p>
                <p className="move_info_item1">

                  <span className="general_info_label">Units Of Measure:</span>
                  {showDriverPay && payUomEditable ? (
                    <>
                      <div className="meterial_autocomplete2">
                        <Autocomplete
                          value={payUomValue}
                          options={["FLAT", "MILE", "HOUR"]}
                          onChange={handlePayUomChange}
                          disabled={!showDriverPay && !payUomEditable}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              style={{ width: '100px' }}
                            />
                          )}
                        />
                      </div>
                    </>
                  ) : (
                    <span className="general_info_value">{payUomValue}</span>
                  )}
                </p>


                <p className="move_info_item1">
                  <span className="general_info_label">Rate :</span>
                  <div className="meterial_autocomplete11 meterial_autocomplete">
                    {showDriverPay && payRateEditable ?
                      (<>
                        <TextField
                          id="outlined-number"
                          type="number"
                          variant="outlined"
                          value={payRateValue}
                          disabled={!showDriverPay && !payRateEditable}
                          onChange={handlePayRateChange}
                          style={{ width: '100px' }}
                        />
                      </>
                      ) :
                      <>
                        <span className="general_info_value">{payRateValue}</span>
                      </>
                    }
                  </div>
                </p>

                <p className="move_info_item1">
                  <span className="general_info_label">Amount :</span>
                  <span className="general_info_value"> {amountValue ? Number(amountValue).toFixed(2) : 0} </span>
                </p>
                {
                  authorizeData?.additional_details?.image_url &&
                  <p className="move_info_item view_image" onClick={(e) => viewInfo(e, authorizeData)}>View Image</p>
                }

              </div>
            </div>
            <div className="driverpay_info_move">
              <div className="move_info_header"><p>Move Info</p></div>
              <div className="move_info_content">
                <p className="move_info_item">
                  <span className="general_info_label">Order:</span>
                  <span className="general_info_value">{orderId ? orderId : "No Data"}</span>
                </p>



                <p className="move_info_item1">
                  <span className="general_info_label">Movement:</span>
                  <div className="meterial_autocomplete11 meterial_autocomplete">
                    {!ismovementEdited && <span className="general_info_value">{movementId ? movementId : 'No Data'}</span>}

                    {ismovementEdited &&
                      (
                        <input
                          id="outlined-number"
                          type="number"
                          variant="outlined"
                          value={movementId}
                          onChange={movementIdHandler}
                          style={{ width: '120px' }}                         
                        />
                      )                    
                    }
                  </div>
                  {ismovementEdited && <Button 
                  className="btn-blue_schedule"
                    onClick={chooseMovementIdHandler}
                    style={{
                      marginLeft: '10px',
                    }}
                  >
                    Choose Movement
                  </Button>}
                  {!ismovementEdited && <span className="general_info_label">
                    <Button
                    className="btn-blue_schedule"
                      onClick={handleEditMovement}
                      disabled={!canAuthorize}
                      style={{
                        marginLeft: '17px',
                        width: '87%'
                      }}
                    >
                      Edit
                    </Button>
                  </span>}
                </p>



                <div className="origin_destination_section">

                  <div className="origin_destination_section_text">
                    Origin
                    <div className="line"></div>
                  </div>

                  <div className="origin_destination_section_text">
                    Destination
                    <div className="line"></div>
                  </div>
                </div>
                <p className="move_info_item">
                  <span className="general_info_label">Stop Type:</span>
                  <span className="general_info_value">{authorizeData?.movement?.pickup?.stop_type ? authorizeData?.movement?.pickup?.stop_type:'No Data'}</span>
                </p>

                <p className="move_info_item">
                  <span className="general_info_label">Stop Type:</span>
                  <span className="general_info_value">{authorizeData?.movement?.delivery?.stop_type ? authorizeData?.movement?.delivery?.stop_type:'No Data'}</span>
                </p>
                <p className="move_info_item">
                  <span className="general_info_label">Address:</span>
                  {authorizeData?.movement?.pickup?.location?.code ?
                  <span className="general_info_value">
                    {authorizeData?.movement?.pickup?.location?.code ?
                      (`${authorizeData?.movement?.pickup?.location?.code ? `${authorizeData?.movement?.pickup?.location?.code} -` : ""}  ${authorizeData?.movement?.pickup?.location?.location_name ? authorizeData?.movement?.pickup?.location?.location_name : ""}`)
                      : ""
                    }
                    <br />
                    {authorizeData?.movement?.pickup?.location?.address1 ?
                      (`${authorizeData?.movement?.pickup?.location?.address1 ? `${authorizeData?.movement?.pickup?.location?.address1}` : ""}`)
                      : ""
                    }
                    <br />
                    {authorizeData?.movement?.pickup?.location?.city ?
                      (`${authorizeData?.movement?.pickup?.location?.city ? `${authorizeData?.movement?.pickup?.location?.city},` : ""} ${authorizeData?.movement?.pickup?.location?.state ? `${authorizeData?.movement?.pickup?.location?.state},` : ""} ${authorizeData?.movement?.pickup?.location?.zip ? `${authorizeData?.movement?.pickup?.location?.zip}` : ""} `)
                      : ""
                    }
                  </span>
                  :<span className="general_info_value">
                    No Data</span>}
                </p>

                <p className="move_info_item">
                  <span className="general_info_label">Address:</span>
                  {authorizeData?.movement?.delivery?.location?.code ?
                  <span className="general_info_value">
                    {authorizeData?.movement?.delivery?.location?.code ?
                      (`${authorizeData?.movement?.delivery?.location?.code ? `${authorizeData?.movement?.delivery?.location?.code} -` : ""}  ${authorizeData?.movement?.delivery?.location?.location_name ? authorizeData?.movement?.delivery?.location?.location_name : ""}`)
                      : ""
                    }
                    <br />
                    {authorizeData?.movement?.delivery?.location?.address1 ?
                      (`${authorizeData?.movement?.delivery?.location?.address1 ? `${authorizeData?.movement?.delivery?.location?.address1}` : ""}`)
                      : ""
                    }
                    <br />
                    {authorizeData?.movement?.delivery?.location?.city ?
                      (`${authorizeData?.movement?.delivery?.location?.city ? `${authorizeData?.movement?.delivery?.location?.city},` : ""} ${authorizeData?.movement?.delivery?.location?.state ? `${authorizeData?.movement?.delivery?.location?.state},` : ""} ${authorizeData?.movement?.delivery?.location?.zip ? `${authorizeData?.movement?.delivery?.location?.zip}` : ""} `)
                      : ""
                    }
                  </span>
                  :<span className="general_info_value">
                    No Data</span>}
                </p>
                <p className="move_info_item">
                  <span className="general_info_label">Arrive:</span>
                  <span className="general_info_value">{formatDate(origin?.actual_arrive_origin, origin?.pickuptimezone)}</span>
                </p>
                <p className="move_info_item">
                  <span className="general_info_label">Arrive:</span>
                  <span className="general_info_value">{formatDate(origin?.actual_arrive_origin, origin?.pickuptimezone)}</span>
                </p>
                <p className="move_info_item">
                  <span className="general_info_label">Depart:</span>
                  <span className="general_info_value">{formatDate(origin?.actual_depart_origin, origin?.pickuptimezone)}</span>
                </p>

                <p className="move_info_item">
                  <span className="general_info_label">Depart:</span>
                  <span className="general_info_value">{formatDate(destination?.actual_depart_destination, destination?.deliverytimezone)}</span>
                </p>


                <p className="move_info_item">
                  <span className="general_info_label">Duration (hrs):</span>
                  <span className="general_info_value">{convertMsToHms(Math.abs(origin?.duration_at_origin_stop))}</span>
                </p>
                <p className="move_info_item">
                  <span className="general_info_label">Duration (hrs):</span>
                  <span className="general_info_value">{convertMsToHms(Math.abs(destination?.duration_at_destination_stop))}</span>
                </p>
                <p className="move_info_item">
                  <span className="general_info_label">Drive Time (hrs):</span>
                  <span className="general_info_value">{convertMsToHms(Math.abs(origin?.drive_time))}</span>
                </p>
                <p className="move_info_item">
                  <span className="general_info_label">Total Time (hrs):</span>
                  <span className="general_info_value">{convertMsToHms(Math.abs(destination?.total_time))}</span>
                </p>
                <p className="move_info_item">
                  <span className="general_info_label">Samsara Link:</span>
                  <a href={`${BaseConfig.SamsaraDriverPayLink}${authorizeData?.driver?.samsara_id}`} target="_blank">
                    <i className="fa fa-external-link"></i>{" "} <u className="external_link">View Samsara HOS Details</u>
                  </a>
                </p>
              </div>
            </div>


            {authorizeData?.additional_details?.formatted ?
              <>
                <div className="driverpay_info_move">
                  <div className="move_info_header"><p>Macro Info</p></div>
                  <div className="move_info_content">
                    {Object.entries(authorizeData?.additional_details?.formatted).map(([label, value]) => (
                      <p className="move_info_item" key={label}>
                        <span className="general_info_label">{label}:</span>
                        <span className="general_info_value">{value}</span>
                      </p>
                    ))}
                  </div>
                </div>
              </> : <></>
            }

            <div className="driverpay_authorize">
              <label htmlFor="authorize_comment" className="authorize_label">Comments</label>
              <textarea id="authorize_comment" className="authorize_comment" rows={5} value={authorizeData.authorized_comment} disabled={!canAuthorize} onChange={authorizeCommentChangeHandler}></textarea>
            </div>

          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" disabled={!isFormTouched || !canAuthorize} onClick={handleAuthorizeSave}>
            Save
          </Button>
          <Button variant="primary" disabled={!canAuthorize} onClick={() => { authorizedHandler(true, "authorize") }}>
            Authorize
          </Button>
          <Button variant="primary" disabled={!canAuthorize} onClick={() => { authorizedHandler(false, "decline") }}>
            Decline
          </Button>
        </Modal.Footer>

      </Modal>
      {/* image view modal */}
      <Modal
        show={isInfoModalShow}
        onHide={() => {
          setIsInfoModalShow(false)
          setimageSrc('')
        }}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered

      >
        <div className="info_modal">
          <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title-vcenter'></Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <>
              <div className="driverpay_info_general">
                <div className="imageDiv">
                  <img src={imageSrc} />
                </div>
              </div>

            </>
          </Modal.Body>
        </div>
        <Modal.Footer>

          <Button variant="primary" onClick={() => {
            setIsInfoModalShow(false)
            setimageSrc('')
          }}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      
      {/* Audit log compponent */}
      <AuditLogModalComponent ref={auditLogComponentRef} />

      {/* modal for editmovementid */}
      <DriverPayModalEditAuthrization
        setidMovementEdited={setidMovementEdited}
        setEditClicked={setEditClicked}
        setOrderId={setOrderId}
        Setmovementid={Setmovementid}
        setOrigin={setOrigin}
        setDestination={setDestination}
        setshowMovementModal={setshowMovementModal}
        MovementData={MovementData}
        handleCloseEditModal={handleCloseEditModal}
        showMovementModal={showMovementModal}
        isdataMovementLoading={isdataMovementLoading}
      />
    </>
  );
};



export default DriverPayAuthorizeTable;
