import React, { useState } from 'react';
import { getter } from "@progress/kendo-react-common";
import { Modal as MUIModal } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from "@mui/material";
import ProgressBar from "react-bootstrap/ProgressBar";
import {
  Grid,
  GridColumn,
  getSelectedState,
  getSelectedStateFromKeyDown
} from "@progress/kendo-react-grid";
import { getAuditLogs } from '../../services/userService';
import { process } from "@progress/kendo-data-query";
import { DateTime } from "luxon";

// Component definition
const AuditLogModal = React.forwardRef((props, ref) => {
  // State variables
  const [showAuditModal, setShowAuditModal] = useState(false);
  const [auditLogData, setAuditLogData] = useState({ isLoading: true, data: [] });
  const [selectedState, setSelectedState] = React.useState({});
  const selectionModes = [
    {
      value: "multiple",
      label: "Multiple selection mode"
    },
    {
      value: "single",
      label: "Single selection mode"
    },
  ];

  const [dataStateForAudit, setDataStateForAudit] = useState({
    skip: 0,
    take: 25,
    filter: {
      logic: "and",
      filters: [],
    },
    sort: [
      {
        field: "",
        dir: "desc",
      },
    ],
  });

  const [selectionMode, setSelectionMode] = React.useState(selectionModes[1].value);
  const [dataResultForAudit, setDataResultForAudit] = useState(process(auditLogData.data, dataStateForAudit));
  const [selectedAuditLog, setSelectedAuditLog] = useState({});

  const DATA_ITEM_KEY = "mtime";
  const SELECTED_FIELD = "selected";
  const idGetter = getter(DATA_ITEM_KEY);

  const MUIstyle = {
    position: 'absolute',
    overflowY: "auto",
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "70%",
    height: "800px",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 8,
  };

  const dataStateChangeForAudit = (event) => {
    setDataResultForAudit(process(auditLogData.data, event.dataState));

    setDataStateForAudit(event.dataState);
    if (event.dataState.filter?.filters.length > 0) {
      setSelectedAuditLog({});
    } else {
      setSelectedState({ [auditLogData.data[0].mtime]: true });
      setSelectedAuditLog(auditLogData.data[0]);
    }
  };

  const onSelectionChange = (event) => {
    const newSelectedState = getSelectedState({
      event,
      selectedState: selectedState,
      dataItemKey: DATA_ITEM_KEY
    });
    setSelectedState(newSelectedState);
  };

  const onKeyDown = (event) => {
    const newSelectedState = getSelectedStateFromKeyDown({
      event,
      selectedState: selectedState,
      dataItemKey: DATA_ITEM_KEY
    });
    setSelectedState(newSelectedState);
  };

  const fetchAllAuditLogData = (data_id, table_name) => {
    setShowAuditModal(true);

    // Get audit logs data
    (async () => {
      try {
        const payload = {
          "primary_key_value": data_id,
          "table_name": table_name
        };

        const response = await getAuditLogs(payload);

        if (response.data.length > 0) {
          const data = response.data;

          const transformedData = [];

          data.forEach((item) => {
            const newData = {
              mtime: item.mtime,
              mtime_modified: DateTime.fromMillis(
                item.mtime,
                {
                  locale: "en-US",
                  outputCalendar: "gregory",
                  numberingSystem: "buddhist",
                }
              ).toFormat("MM/dd/yyyy HH:mm a ZZZZ"),

              action: item.action === "I" ? "Insert" : item.action === "U" ? "Update" : "Delete",
              username: item.username,
              table_name: item.table_name,
              row_data: item.row_data,
              primary_key_value: item.primary_key_value,
            };

            transformedData.push(newData);
          });

          setSelectedState({ [data[0].mtime]: true });
          setSelectedAuditLog(transformedData[0]);

          setAuditLogData({ isLoading: false, data: transformedData });
          setDataResultForAudit(process(transformedData, dataStateForAudit));
        } else {
          setAuditLogData({ isLoading: false, data: [] });
          setDataResultForAudit(process([], dataStateForAudit));
        }
      } catch (err) {
        //NotificationManager.error("There is a problem on retrieving audit logs data. Please try again!");
        setAuditLogData({ isLoading: false, data: [] });
        setDataResultForAudit(process([], dataStateForAudit));
      }
    })();
  };

  // Expose the childFunction through the ref
  React.useImperativeHandle(ref, () => ({
    fetchAllAuditLogData,
  }));

  const convertDateTimeFromISO = (input) => {
    if (!input) {
      return "No Data";
    }
    // Regular expression to match ISO 8601 date-time strings
    const isoDatetimeRegex = /^(?:\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{3}Z)?)/;
    const isoDateRegex=/^([0-9]{4})-?(1[0-2]|0[1-9])-?(3[01]|0[1-9]|[12][0-9])$/

    // Check if the input matches an ISO 8601 date-time string
    if (isoDatetimeRegex.test(input)) {
      // Attempt to parse the input as a Luxon DateTime object
      const luxonDateTime = DateTime.fromISO(input, {
        // zone: context.plannerTimezone,
        // setZone: "yes",
        locale: "en-US",
        outputCalendar: "gregory",
        numberingSystem: "buddhist",
      }
      );

      // Check if the Luxon DateTime object is valid
      if (luxonDateTime.isValid) {
        // If it's a valid date, return the formatted date
        return luxonDateTime.toFormat("MM/dd/yyyy HH:mm a ZZZZ");
      }
    }else if(isoDateRegex.test(input)){
       // Attempt to parse the input as a Luxon DateTime object
       const luxonDateTime = DateTime.fromISO(input, {
        // zone: context.plannerTimezone,
        // setZone: "yes",
        locale: "en-US",
        outputCalendar: "gregory",
        numberingSystem: "buddhist",
      }
      );

      // Check if the Luxon DateTime object is valid
      if (luxonDateTime.isValid) {
        // If it's a valid date, return the formatted date
        return luxonDateTime.toFormat("MM/dd/yyyy");
      }
    }

    // If it's not a valid date, return the input as is
    return input;
  };


  // This function will be called when clicking on the auding log row.
  const auditrowClickHandler = (item) => {
    setSelectedAuditLog(item);
  };

  // Audit log modal close handler
  const auditLogModalCloseHandler = () => {
    setShowAuditModal(false);
    setAuditLogData({ isLoading: true, data: [] });
    setSelectedAuditLog({});
    // setIsAuditSelected(false);
    setDataStateForAudit({
      skip: 0,
      take: 25,
      filter: {
        logic: "and",
        filters: [],
      },
      sort: [
        {
          field: "",
          dir: "desc",
        },
      ],
    });
  };

  return (
    <div>
      <MUIModal
        open={showAuditModal}
        onClose={() => auditLogModalCloseHandler()}
        aria-labelledby="modal-modal-title"
        className="settlement_modal"
        aria-describedby="modal-modal-description"
      >
        <Box sx={MUIstyle} className="audit_log_modal_height">
          <div className="audit_close_sec">
            <h3 class="modal-title" id="contained-modal-title-vcenter">Audit Log</h3>
            <IconButton sx={{ ml: 'auto', display: "flex", justifyContent: "flex-end" }}>
              <CloseIcon onClick={() => auditLogModalCloseHandler()} />
            </IconButton>
          </div>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div className="kendo_container">
              {
                !auditLogData.isLoading ?
                  (<>
                    <div className="audit_log_container_first">
                      <Grid
                        filter={dataStateForAudit.filter}
                        sort={dataStateForAudit.sort}
                        skip={dataStateForAudit.skip}
                        take={dataStateForAudit.take}
                        filterable={true}
                        sortable={true}
                        resizable={true}
                        {...dataStateForAudit}
                        pageable={{
                          pageSizes: [25, 50, 100],
                          info: true,
                          previousNext: true,
                          buttonCount: 2
                        }}
                        data={dataResultForAudit.data.map((item) => ({
                          ...item,
                          [SELECTED_FIELD]: selectedState[idGetter(item)]
                        }))}
                        dataItemKey={DATA_ITEM_KEY}
                        selectedField={SELECTED_FIELD}
                        selectable={{
                          enabled: true,
                          mode: selectionMode
                        }}
                        onSelectionChange={onSelectionChange}
                        onKeyDown={onKeyDown}
                        onDataStateChange={dataStateChangeForAudit}
                        onRowClick={(e) => auditrowClickHandler(e.dataItem)}
                      >
                        <GridColumn field="username" title="User ID" width="325px" />
                        <GridColumn
                          field="mtime_modified"
                          title="DateTime"
                          width="325px"
                          filterable={true}
                        />
                        <GridColumn field="action" title="Action" width="325px" />
                        <GridColumn field="table_name" title="Table Name" width="325px" />
                      </Grid>
                    </div>

                    <div className="audit_log_container_second">
                      {
                        auditLogData.data.length > 0 && Object.keys(selectedAuditLog).length > 0 ? (
                          <table className="audit_log_normal_table">
                            <thead>
                              <tr>
                                <th className="audit_log_th">Field</th>
                                {selectedAuditLog.action === "Insert" || selectedAuditLog.action === "Update" ? <th className="audit_log_th">New Value</th> : <th className="audit_log_th">Old Value</th>}
                                {selectedAuditLog.action === "Delete" ? <th className="audit_log_th">New Value</th> : <th className="audit_log_th">Old Value</th>}
                              </tr>
                            </thead>
                            <tbody>
                              {
                                selectedAuditLog.action === "Update" ? (
                                  Object.keys(selectedAuditLog.row_data).map((item, idx) => (
                                    <tr key={idx}>
                                      <td className="audit_log_td">{item}</td>
                                      <td className="audit_log_td">{convertDateTimeFromISO(selectedAuditLog.row_data[item]?.new_value)}</td>
                                      <td className="audit_log_td">{convertDateTimeFromISO(selectedAuditLog.row_data[item]?.old_value)}</td>
                                    </tr>
                                  ))
                                ) : selectedAuditLog.action === "Insert" ? (
                                  Object.entries(selectedAuditLog.row_data).map(([item, value], idx) => (
                                    <tr key={idx}>
                                      <td className="audit_log_td">{item}</td>
                                      <td className="audit_log_td">{convertDateTimeFromISO(value)}</td>
                                      <td className="audit_log_td">No Data</td>
                                    </tr>
                                  ))
                                ) : (
                                  Object.entries(selectedAuditLog.row_data).map(([item, value], idx) => (
                                    <tr key={idx}>
                                      <td className="audit_log_td">{item}</td>
                                      <td className="audit_log_td">{convertDateTimeFromISO(value)}</td>
                                      <td className="audit_log_td">No Data</td>
                                    </tr>
                                  ))
                                )}
                            </tbody>
                          </table>
                        ) : null
                      }
                    </div>
                  </>) : (<div>
                    <ProgressBar animated now={100} />
                    <div className='middle loader--text1'> </div>
                  </div>)
              }
            </div>
          </Typography>
        </Box>
      </MUIModal>
    </div>
  )
});

export default AuditLogModal;