import Modal from "react-bootstrap/Modal";
import { Form, Formik } from "formik";
import { Grid as MGRID, TextField } from "@mui/material";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { makeStyles } from "@mui/styles";
// import ProgressBar from "react-bootstrap/ProgressBar";
import * as yup from "yup";
import { Button } from "@mui/material";
import {useEffect, useState, useContext } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import {getAllDefaultLoadUnload, updateLUTRule} from "../../services/loadunloadruleService";
import { ContextData } from "../../components/appsession";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Table = (props) => {
  const [userData, setuserData] = useContext(ContextData);

  const permission = userData?.roles?.rolespermission?.find(loadunloaddurationpermission =>
    loadunloaddurationpermission?.permissiondetail.code === "LoadingUnloadingDurations")

  const {
    tablelist,
    setModalShow,
    modalShow,
    setTablelist,
    isDataloaded,
    parentCallBackForDuration,
  } = props;
  const [editdata, setEditdata] = useState({});
  const [hide, setHide] = useState(false);

   const [isDisabled, setIsDisabled] = useState(false)
 
   const useStyles = makeStyles((theme) => ({
    statuscolor: {
      textAlign: "center!important",
      fontWeight: "bold",
      fontSize: 15
    },
  }));
  const classes = useStyles();
  const [dataState, setDataState] = useState({
    skip: 0,
    take: 10,
    filter: {
      logic: "and",
      filters: [],
    },
    sort: [
      {
        field: "duration",
        dir: "asc",
      },
    ],
  });
  const [dataResult, setDataResult] = useState(process(tablelist, dataState));
  
  const dataStateChange = (event) => {
    setDataResult(process(tablelist, event.dataState));
    setDataState(event.dataState);
  };



  useEffect(() => {
    setDataResult(process(tablelist, dataState));
  }, [tablelist]);


  const thstyle = {
    color: "#fff",
  };

  const getObjectById = (id) => {
    return tablelist.find((obj) => obj.id == id);
  };

  const editButton = (id) => {
    setModalShow(true);
    let dataobj= tablelist.find((item) => item.id === id);
    setEditdata(dataobj);
  };

  const updateDuration = async (values) => {
    try {  
      if (!values || !values.duration || !values.id) {
        console.error("Invalid values object");
        return;
      } 
      const payload = {
        "duration": values.duration,
      }; 
      const UpdatedRes = await updateLUTRule(values.id, payload); 
      if (UpdatedRes.status === "Success") {
        const data = await getAllDefaultLoadUnload(); 
        setModalShow(false);
        setTablelist(data);
        // NotificationManager.success('Load Unload rules updated successfully', 'Success');
        toast.success("Load Unload rules updated successfully", "Success");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  

  return (
    <>
    <ToastContainer position="top-right" theme="colored" />
            <div className="table-responsive">
              {!isDataloaded ? 
            (
              <div>
              <ProgressBar animated now={100} />
                    <div className="loader">
                      <div className="loader--text"></div>
              </div>
            </div>
            ) : (
              tablelist?.length >0 ? (
                <Grid
                  filter={dataState.filter}
                  sort={dataState.sort}
                  sortable={true}
                  filterable={true}
                  pageable={{
                    pageSizes: [5, 10, 20, 25, 50, 100],
                    info: true,
                    previousNext: true,
                    buttonCount : 10
                  }}
                  resizable={true}
                  skip={dataState.skip}
                  take={dataState.take}
                  data={dataResult}
                  onDataStateChange={dataStateChange}
                >
                  <GridColumn
                      field="description"
                      title="Description"
                      width="300px"
                      filterable={true}
                      cell={(e) => {
                       
                        return (
                          <td>
                          {e.dataItem.description?e.dataItem.description:""}
                        </td>
                        );
                      }}
                    />
                      <GridColumn
                      field="stop_type"
                      title="StopType"
                      width="280px"
                      filterable={true}
                      cell={(e) => {
                      
                        return (
                          <td>
                           {e.dataItem.stop_type?e.dataItem.stop_type:""}
                          </td>
                        );
                      }}
                    />
                    <GridColumn
                      field="driver_load_flag"
                      title="DriverLoad/Unload Flag"
                      width="280px"
                      filterable={true}
                      cell={(e) => {
                       
                        return (
                          <td>
                           {e.dataItem.driver_load_flag?e.dataItem.driver_load_flag:""}
                          </td>
                        );
                      }}
                    />
                    <GridColumn
                      field="duration"
                      title="	Duration (min)"
                      width="270px"
                      filterable={true}
                      cell={(e) => {
                       
                        return (
                          <td>
                           {e.dataItem.duration?e.dataItem.duration:""}
                          </td>
                        );
                      }}
                    />  
                     <GridColumn
                     field="id"
                      title="Action"
                      width="250px"
                      filterable={false}
                      cell={(e) => {
                        return (
                          <td>
                    {permission?.can_edit?(
                  <button
                  className="btn_blue_sm btn-blue ml_10"
                  onClick={() => editButton(e.dataItem.id)}
                >
                  Edit
                </button>
                    ):(
                      <button
                      className="btn_blue_sm btn-blue ml_10"                  
                      disabled
                    >
                      Edit
                    </button>
                    )
                  }
                    
                  </td>
                        );
                      }}
                    />
                </Grid>
              ) : (
                <div>
                  No data found
                </div>
              )
            )
            }
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Duration
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              duration: editdata.duration,
              driverloadflag: editdata?.driver_load_flag,
              stop_type: editdata?.stop_type,
              id: editdata?.id,
            }}
            validationSchema={yup.object().shape({
              duration: yup.string().required("Duration is required"),
            })}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              updateDuration(values);
              setSubmitting(false);
            }}
          >
            {({ errors, handleChange, touched, values, setFieldValue }) => (
              <Form>
                <Modal.Body>
                  <MGRID container spacing={2}>
                    {hide ? (
                      <>
                        <MGRID item xs={12}>
                          <TextField
                            id="id"
                            name="id"
                            label="id"
                            type="number"
                            fullWidth
                            value={values.id}
                            onChange={handleChange}
                            error={errors.id && touched.id}
                            helperText={errors.id && touched.id}
                          />
                        </MGRID>
                      </>
                    ) : (
                      ""
                    )}
                    <label for="typeHeadCommodityGroup">Duration Mins</label>
                    <TextField
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      name="duration"
                      placeholder="e.g. 30 mins"
                      size="small"
                      value={values.duration}
                      onChange={handleChange}
                      error={touched.duration && Boolean(errors.duration)}
                      helperText={touched.duration && errors.duration}
                    />
                    <label for="typeHeaddriverloadflag">Driver load flag</label>
                    <TextField
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      name="driverloadflag"
                      placeholder="e.g. Yes or No"
                      size="small"
                      value={values.driverloadflag}
                      onChange={handleChange}
                      error={
                        touched.driverloadflag && Boolean(errors.driverloadflag)
                      }
                      helperText={
                        touched.driverloadflag && errors.driverloadflag
                      }
                      disabled={true}
                    />
                    <label for="typeHeaddriverloadflag">Stop Type</label>
                    <TextField
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      name="actiontype"
                      placeholder="PU"
                      size="small"
                      value={values.stop_type}
                      onChange={handleChange}
                      error={touched.stop_type && Boolean(errors.stop_type)}
                      helperText={touched.stop_type && errors.stop_type}
                      disabled={true}
                    />
                  </MGRID>
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={() => setModalShow(false)}>Close</Button>
                  {/* <Button onClick={(e) => updateDuration(e)}>Save</Button> */}
                  <Button
                type='submit'
                onClick={(e) => updateDuration(e)}
              >
                Save
              </Button>
                 {/* <Button type="sumbit">Save</Button> */}
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
    </>
  );
};

export default Table;
