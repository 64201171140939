import React, { useContext, useEffect, useState } from "react";
import { DateTime } from "luxon";
import TankwashDataBar from "../tankwashDataBar/tankwashDataBar";
import { tankWashDragContext } from "../../pages/tankWash/tankWash";
import {
  getAllActiveTrailer, getAllActiveTractor,getAllwashbaylocationByid, getWashCode, getTrailerWashWorkOrders, createTrailerWash, getWashLocationForVendorPayable, updateTrailerWash
} from "../../services/tankWashService";
import Modal from "react-bootstrap/Modal";
import { Autocomplete } from "@mui/lab";
import { TextField } from "@mui/material";
import IntraCompany from "./IntraCompany";
import OutsideCarrier from "./OutsideCarrier";
import VendorPayable from "./VendorPayable";
import { ContextData } from "../../components/appsession";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";
import DateFnsUtils from "@date-io/date-fns";
import Stack from "@mui/material/Stack";
import Skeleton from '@mui/material/Skeleton';
import { ProgressBar } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const TankwashdraggbleMainpage = (props) => {
  const {
    hourArr,
    dragset,
    selectedDateInToMilliSeconds,
    hoursOfOperation,
    selectedLocation,
    alllocationnamelist,
    orderClicked,
    modalShowEdit,
    setModalShowEdit,
    calenderDropdownSelectHandler,
    dateChangeThroughArrows,
    selectedDate,
    setcalenderDrpdownClicked,
    calenderDrpdownClicked,
    washDeatilsById,
    setorderClicked,
    washDetails
  } = props;

  const [timezone, setTimezone] = useState("");
  const [userData, setUserData] = useContext(ContextData);
  const userName = userData?.email?.split('@')[0];
  const companyName = userData?.email?.split('@')[1]?.split(".")[0].slice(0, 3).toUpperCase();
  const loggedUserData = { userName, companyName }

  const tankwashDropDownOptions = [
    { id: "1", name: "Intra Company", code: "I" },
    { id: "2", name: "Outside Carrier", code: "O" },
    { id: "3", name: "Vendor Payable", code: "P" }
  ];
  const [dropDownSelection, setDropDownSelection] = useState({ id: "1", name: "Intra Company" });
  const [allActiveTrailersData, setAllActiveTrailersData] = useState([]);
  const [allActiveTractorsData, setAllActiveTractorsData] = useState([]);
  const [allWashCodes, setAllWashCodes] = useState([]);
  const [washLocationForVendorPayable, setWashLocationForVendorPayable] = useState([]);
  const [tankwashLocation, setTankwashLocation] = useState([]);

  const [selectedChargeType, setselectedChargeType] = useState({})
  const [isLoading, setIsLoading] = useState(props.tankwashLoading);
  const [modalShow, setModalShow] = useState(washDetails.trailer_id?true:false);
 // const [tankwashLoading, setTankwashLoading] = useState(false);
 const [isDisabled,setIsDisabled]=useState(false);
 const checkAccess = () => {
  const permission = userData?.roles?.rolespermission.find(permission => permission?.permissiondetail?.code === "Locations")
  if(permission?.can_edit === false)
        setIsDisabled(true)
}
useEffect(()=>{
  checkAccess()
})
  useEffect(() => {
    // Tankwash location modification for Intra Company and Outside carrier 
    const tankwashLoc = alllocationnamelist.filter((loc) => (loc.is_terminal === true & loc.is_wash === true));
    setTankwashLocation(tankwashLoc);
  }, [alllocationnamelist]);

  useEffect(async () => {
    // Getting wash codes
    const allWashCodeData = await getWashCode();
    setAllWashCodes(allWashCodeData);

    // Getting Active Trailer
    const allActiveTrailerData = await getAllActiveTrailer();
    setAllActiveTrailersData(allActiveTrailerData);

    // Getting Active Tractor
    const allActiveTractorData = await getAllActiveTractor();
    setAllActiveTractorsData(allActiveTractorData);

    // Get Wash Locartion for outside carrier
    const washLoactionForVendor = await getWashLocationForVendorPayable();
    setWashLocationForVendorPayable(washLoactionForVendor);
  }, []);

  useEffect(() => {
    alllocationnamelist.map(item => { setTimezone(item.timezone) });

  })

  const context = React.useContext(tankWashDragContext);

  const getTimeZone = () => {
    var tzone = selectedLocation?.timezone;
    const localDateTime = DateTime.local().setZone(tzone);
    return localDateTime.isValid ? localDateTime.toFormat("ZZZZ") : tzone;
  }

  const dropDownTypeHandler = (e, type) => {

    setDropDownSelection(type);
  }

  const createTankWash = async (payload) => {
    try {
      const response = await createTrailerWash(payload);
      if (response.status === 201) {
        NotificationManager.success("Tankwash order created successfully", "Success", 2500);
        setModalShow(false);
        context.setdatarefresh(!context.datarefresh);
      }
    } catch (err) {
      NotificationManager.error("Tankwash work order updation failed.", "Error");
    }
  };

  const updateTankWash = async (payload, id) => {
    const response = await updateTrailerWash(payload, id);
    context.setdatarefresh(!context.datarefresh);
       
    if (response.status === 200) {
      NotificationManager.success("Tankwash order updated successfully", "Success", 2500);
    } else {
      if (response.data.message === "value too long for type character varying(8)") {
        NotificationManager.error(`The "Trailer/Tractor" ID entered is too long!`, "Error");
      } else {
        NotificationManager.error("Tankwash work order updation failed.", "Error");
      }
    }
    return response;
  }

  const handleDateChange = (e) => {
    setIsLoading(true); 
    props.callback(true);

    calenderDropdownSelectHandler(e);
    setTimeout(() => {
      setIsLoading(false); // hide the loading indicator after 2 seconds
      props.callback(false);

    }, 2000);

  };

  const prevDateHandler = () => {
    setIsLoading(true); 
    props.callback(true);
    let prevDateTs = selectedDate - 86400000;
    dateChangeThroughArrows(prevDateTs);
    setTimeout(() => {
      setIsLoading(false); // hide the loading indicator after 2 seconds
      props.callback(false);

    }, 2000);
  };

  const nextDateHandler = () => {
    setIsLoading(true); 
    props.callback(true);
    let nextDate = selectedDate + 86400000;
    dateChangeThroughArrows(nextDate);
    setTimeout(() => {
      setIsLoading(false); // hide the loading indicator after 2 seconds
      props.callback(false);

    }, 2000);
  };

  const dropDownTypeHandlerforEdit = (e, type) => {
    setselectedChargeType(type)
  }

  useEffect(() => {
    if (washDeatilsById) {
      setselectedChargeType(tankwashDropDownOptions.filter((e) => e.code === washDeatilsById.charge_type)[0])

    }

  }, [washDeatilsById])

  const handleRefresh = async () => {
    if(selectedLocation?.code){
      setIsLoading(true); 
      props.callback(true);
      const needed_date_utc = DateTime.fromJSDate(selectedDate).toMillis();
      const wash_location_id = selectedLocation.code;
    // (await getAllwashbaylocationByid(selectedLocation.id))
    //       .no_of_wash_bays 
         
       await getTrailerWashWorkOrders({ needed_date_utc, wash_location_id })
       props.callback(false);
      setTimeout(() => {
        setIsLoading(false); 
      }, 2000);
    }
    else{
      return toast.error("Please select a location before refreshing.", "Error");
    }

  };

  const modalCloseHandler = () => {
    setModalShow(false);
  };

  
  return (
    <>
      {/* <ToastContainer position='top-right' autoClose={2000} theme='colored' /> */}
      <div className='row mt_30'>
        <div className='col-xl-12'>
          <div className='card card_shadow'>
            {isLoading || props.tankwashLoading?
             (
              <ProgressBar animated now={100} />
            ):(
              <>
           <div className='card-body'>
              {/* Start of Modal */}
              <div>
                <Modal
                  show={modalShow}
                  onHide={() => modalCloseHandler()}
                  dialogClassName="tankwash_order_modal_section modal-half"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                      Add Work Order
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="dialog-item">
                      <label htmlFor="typeHeadCommodityGroup">
                        Type
                      </label>
                      <div className="meterial_autocomplete">
                        <Autocomplete
                          id="combo-box-demo"
                          options={tankwashDropDownOptions}
                          getOptionLabel={(option) => `${option?.name}`}
                          defaultValue={tankwashDropDownOptions[0]}
                          onChange={dropDownTypeHandler}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Choose a Trailer..."
                              variant="outlined"
                            />
                          )}
                        />
                      </div>
                    </div>

                    {/* Based on dropdown selection */}
                    {dropDownSelection?.name === "Intra Company" ? (
                      <IntraCompany
                        allWashLocation={tankwashLocation}
                        allActiveTractors={allActiveTractorsData}
                        allActiveTrailers={allActiveTrailersData}
                        allWashCodes={allWashCodes}
                        userData={userData}
                        loggedUserData={loggedUserData}
                        createTankWash={createTankWash}
                        dropDownSelection={dropDownSelection}
                        washDetails={washDetails}                       

                      />
                    ) : dropDownSelection?.name === "Outside Carrier" ? (
                      <OutsideCarrier
                        allWashLocation={tankwashLocation}
                        allActiveTractors={allActiveTractorsData}
                        allActiveTrailers={allActiveTrailersData}
                        allWashCodes={allWashCodes}
                        userData={userData}
                        loggedUserData={loggedUserData}
                        createTankWash={createTankWash}
                        dropDownSelection={dropDownSelection}
                        washDetails={washDetails}



                      />
                    ) : dropDownSelection?.name === "Vendor Payable" ? (
                      <VendorPayable
                        allWashLocation={washLocationForVendorPayable}
                        allActiveTractors={allActiveTractorsData}
                        allActiveTrailers={allActiveTrailersData}
                        allWashCodes={allWashCodes}
                        userData={userData}
                        loggedUserData={loggedUserData}
                        createTankWash={createTankWash}
                        dropDownSelection={dropDownSelection}
                        washDetails={washDetails}


                      />
                    ) : (<></>)
                    }

                  </Modal.Body>
                </Modal>
              </div>
              {/* End of Modal */}

              {/* Start of Edit Modal */}
              {orderClicked ? (
                <>
                  <div className="">
                    <Modal
                      show={modalShowEdit}
                      onHide={() => setModalShowEdit(false)}
                      dialogClassName="tankwash_order_modal_section modal-half"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                    >
                      <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                          Edit Work Order
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="dialog-item">
                          <label htmlFor="typeHeadCommodityGroup">
                            Type
                          </label>
                          <div className="meterial_autocomplete">
                            <Autocomplete
                              id="combo-box-demo"
                              options={tankwashDropDownOptions}
                              getOptionLabel={(option) => `${option?.name}`}
                              value={selectedChargeType}
                              onChange={dropDownTypeHandlerforEdit}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Choose a Trailer..."
                                  variant="outlined"
                                />
                              )}
                            />
                          </div>
                        </div>

                        {/* Based on dropdown selection */}
                        {selectedChargeType?.name === "Intra Company" ? (
                          <IntraCompany
                            allWashLocation={alllocationnamelist}
                            allActiveTractors={allActiveTractorsData}
                            allActiveTrailers={allActiveTrailersData}
                            allWashCodes={allWashCodes}
                            userData={userData}
                            loggedUserData={loggedUserData}
                            createTankWash={createTankWash}
                            washDeatilsById={washDeatilsById}
                            updateTankWash={updateTankWash}
                            setorderClicked={setorderClicked}
                          />
                        ) : selectedChargeType?.name === "Outside Carrier" ? (
                          <OutsideCarrier
                            allWashLocation={alllocationnamelist}
                            allActiveTractors={allActiveTractorsData}
                            allActiveTrailers={allActiveTrailersData}
                            allWashCodes={allWashCodes}
                            userData={userData}
                            loggedUserData={loggedUserData}
                            createTankWash={createTankWash}
                            washDeatilsById={washDeatilsById}
                            updateTankWash={updateTankWash}
                            setorderClicked={setorderClicked}
                          />
                        ) : selectedChargeType?.name === "Vendor Payable" ? (
                          <VendorPayable
                            allWashLocation={washLocationForVendorPayable}
                            allActiveTractors={allActiveTractorsData}
                            allActiveTrailers={allActiveTrailersData}
                            allWashCodes={allWashCodes}
                            userData={userData}
                            loggedUserData={loggedUserData}
                            createTankWash={createTankWash}
                            washDeatilsById={washDeatilsById}
                            updateTankWash={updateTankWash}
                            setorderClicked={setorderClicked}

                          />
                        ) : (<></>)
                        }

                      </Modal.Body>
                    </Modal>
                  </div>
                </>
              ) : ""}
              {/* End of Edit Modal */}
              <div className='tankwash_topdate1'>
                <div className="blank_space_tankwash"></div>

                <div className="date_container text-center">
                  <div className="tankwash_topdate_text">
                    <div className="planner_board_top_arrow">
                      <ArrowBackIosNew onClick={prevDateHandler} />
                    </div>
                    <div className="MuiFormControl-root MuiTextField-root css-i44wyl">
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <DatePicker
                          className="date_picker"
                          size="small"
                          disableToolbar
                          inputVariant="outlined"
                          format="MM/dd/yyyy"
                          margin="normal"
                          id="date-picker-inline"
                          value={DateTime.fromMillis(selectedDate)}
                          onChange={handleDateChange}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </LocalizationProvider>
                    </div>
                    <div className="planner_board_top_arrow">
                      <ArrowForwardIos onClick={nextDateHandler} />
                    </div>
                  </div>

                  <div className="planner_top_date">
                    <div className="planner_dates_text1">
                      {getTimeZone()}
                    </div>
                  </div>
               
                </div>


                <div className='planning_board_button_container'>
                    <div className="planning_board_refresh_button">
                      <button type="button"
                        className="btn_blue btn-blue mb_0 access_save"
                        onClick={handleRefresh}
                      >Refresh</button>
                    </div>
                    <button
                  type="button"
                  onClick={() => setModalShow(true)}
                  className="btn_blue btn-blue mb_0 access_save"
                  disabled={isDisabled}
                >
                  Add Work Order
                </button>
                  </div>
              </div>

              <div className='tankwash_main_container'>
                {hourArr.map((item, index) => (
                  <div className='tankwash_main_inner_container' key={index}>
                    <div className='hrs_wrapper'>
                      <p className='hrs_wrapper_time_text'>
                        {item == "0"
                          ? `12  A.M`
                          : item == "12"
                            ? `12  P.M`
                            : item > 11
                              ? `${item - 12}  P.M`
                              : `${item}  A.M`}
                      </p>
                      <div className='tankwash_day_time_inner'>
                        <div className='vl_plannertankwash'></div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="scroll_tankwash_inner">
                {context.baseData.map((it, idx) => (
                  <div key={idx}>
                    <div className='tankwash_dragable_container_label' key={idx}>
                      Bay {idx + 1}
                    </div>

                    <div className='tankwash_dragable_container'>
                      {dragset.map((it2, index) => (
                        <div
                        key={index}
                          onDrop={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            context.setDropData({ bay: idx, index: index });
                          }}
                          onDragOver={(e) => {
                            e.preventDefault();
                          }}
                          className={
                            hoursOfOperation.includes(index) ? `tankwash_dragable_inner` : `tankwash_dragable_inner_dark`
                          }
                          style={
                            index == Math.floor(hoursOfOperation[0]) &&
                              !Number.isInteger(hoursOfOperation[0]) ?
                              {
                                width: "2.0833333333333335%",
                                background: `linear-gradient( to right, #545b71 0%, #545b71 ${Math.trunc(100 - (hoursOfOperation[1] - hoursOfOperation[0]) * 100)}%, #d1ddfa ${Math.trunc(100-(hoursOfOperation[1] - hoursOfOperation[0]) * 100)}%, #d1ddfa 100% )`
                              }
                              :
                              index == Math.floor(hoursOfOperation[hoursOfOperation.length - 1]) &&
                                !Number.isInteger(hoursOfOperation[hoursOfOperation.length - 1]) ?
                                {
                                  width: "2.0833333333333335%",
                                  border: "none",
                                  background: `linear-gradient( to left, #545b71 0%, #545b71 ${Math.trunc((hoursOfOperation[1] - hoursOfOperation[0]) * 100)}%, #d1ddfa ${Math.trunc((hoursOfOperation[1] - hoursOfOperation[0]) * 100)}%, #d1ddfa 100% )`
                                }
                                : {
                                  width: "2.0833333333333335%",
                                }
                          }

                        ></div>
                      ))}
                                          
                      <>
                        {it?.map((item, itemIndex) => {
                          return (
                            <>
                              {item?.isLoading ? (
                                <Stack spacing={1}>
                                  <div className='loader_skeleton'>
                                    <Skeleton variant='rectangular' />
                                  </div>
                                </Stack>
                              ) : <></>}
                            </>
                          )
                        })}

                        {it?.map((item, itemIndex) => (
                          <TankwashDataBar
                            barData={item}
                            bay={idx}
                            index={itemIndex}
                            key={itemIndex}
                            selectedLocation={selectedLocation}
                            selectedDateInToMilliSeconds={selectedDateInToMilliSeconds}
                          />
                        ))}
                      </>                    
                    </div>
                  </div>
                ))}
              </div>
            </div>
              </>
            ) }
           
          </div>
        </div>
      </div >
      <NotificationContainer />
    </>
  );
};

export default TankwashdraggbleMainpage;
