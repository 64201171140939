
import DedicatedTrailer from "../models/dedicatedtrailerModel";
import Driver from "../models/driverModel";
import axios from 'axios';

// Application Specific
import Location from "../models/locationModel";
import ShipperPool from "../models/shipperpoolModel";
import { BaseConfig } from "../baseConfig";
import * as fflate from 'fflate';
//import zlib from zlib;

export const getLocation = async (locationId) => {
  const location = new Location();
  let locationObject = [];
  try {
    const url = BaseConfig.ApiEndPoint + "/locations/" + locationId;
    const locationApiData = await axios.get(url);
    locationObject = location.parseApiLocationObject(
      locationApiData.data.data
    );
  } catch (err) {
    return Promise.resolve(
      "There is a problem on retrieving terminal data. Please try again!"
    );
  }

  return Promise.resolve(locationObject);
}

export const getAllLocations = async () => {
  const location = new Location();
  let locationObject = [];
  try {
    const url = BaseConfig.ApiEndPoint + "/locations";
    let locationApiData = await axios.get(url);

    //locationApiData = zlib.inflateSync(Buffer.from(locationApiData.data, 'base64')).toString();
    const strData = atob(locationApiData.data);
    // Convert binary string to character-number array
    const charData = strData.split("").map((x) => { return x.charCodeAt(0); });
    // Turn number array into byte-array
    const binData = new Uint8Array(charData);
    // Use this Code to Decode the String

    //Uncompress API Call//
    let locationApiDataUnCompressed = fflate.unzlibSync(binData);
    let locationApiDataUnCompressedString = fflate.strFromU8(locationApiDataUnCompressed)
    let locationIntermediateObject = JSON.parse(locationApiDataUnCompressedString)
    locationObject = locationIntermediateObject.data.map((data) =>
      location.parseApiLocationObject(data)
    );
  } catch (err) {
    return Promise.resolve(
      "There is a problem on retrieving terminal data. Please try again!"
    );
  }
  return Promise.resolve(locationObject);
}

export const getActivelocations = async () => {
  const location = new Location();
  let locationObject = [];
  try {
    const url = BaseConfig.ApiEndPoint + "/getactivelocations";
    let locationApiData = await axios.get(url);

    //locationApiData = zlib.inflateSync(Buffer.from(locationApiData.data, 'base64')).toString();
    const strData = atob(locationApiData.data);
    // Convert binary string to character-number array
    const charData = strData.split("").map((x) => { return x.charCodeAt(0); });
    // Turn number array into byte-array
    const binData = new Uint8Array(charData);
    // Use this Code to Decode the String

    //Uncompress API Call//
    let locationApiDataUnCompressed = fflate.unzlibSync(binData);
    let locationApiDataUnCompressedString = fflate.strFromU8(locationApiDataUnCompressed)
    let locationIntermediateObject = JSON.parse(locationApiDataUnCompressedString)
    locationObject = locationIntermediateObject.data.map((data) =>
      location.parseApiLocationObject(data)
    );
  } catch (err) {
    return Promise.resolve(
      "There is a problem on retrieving terminal data. Please try again!"
    );
  }
  return Promise.resolve(locationObject);
}
export const filterLocation = async (filterData) => {

  const location = new Location();
  let locationObject = [];
  const url = BaseConfig.ApiEndPoint + "/filterlocationconsigneeshipper";
  const data = {
    search: filterData.search,
    terminalId: filterData.terminalId,
    relayPoint: filterData.relayPoint,
    dropYard: filterData.dropYard,
    distCenter: filterData.distCenter,
    truckStop: filterData.truckStop,
    outsideShop: filterData.outsideShop,
    TW: filterData.TW,
    BRATSLoc: filterData.BRATSLoc,
    region: filterData.region,
    state: filterData.state,
  };
  const locationApiData = await axios.post(url, data);

  locationObject = locationApiData.data.data.map((data) =>
    location.parseApiLocationObject(data)
  );

  return Promise.resolve(locationObject);
}

export const getDriversByLocationID = async (locationId, searchData, isShipperOrConsignee) => {
  let allDrivers = [];
  const driver = new Driver();
  try {
    let data = {
      locationId: locationId,
      search: searchData,
      blocktype: isShipperOrConsignee,
    };
    const url = BaseConfig.ApiEndPoint + "/getdriverbylocationid";
    const driverApiData = await axios.post(url, data);
    allDrivers = driverApiData.data.data.map((data) =>
      driver.parseApiDriverObject(data)
    );
  } catch (error) { }
  return Promise.resolve(allDrivers);
}
// get  and innner serach
export const getShipperPoolByLocationID = async (locationId) => {
  let sPools = [];
  const sPool = new ShipperPool();
  let data = {
    locationid: locationId,
  };
  const url = BaseConfig.ApiEndPoint + "/shipperpoolsbylocationid";
  const spApiData = await axios.post(url, data);
  // sPools = spApiData.data.data.map((data) =>
  //   sPool.parseApiShipperPoolObject(data)
  // );
  var getshiperpool = spApiData.data.data;
  sPools = getshiperpool;
  return Promise.resolve(sPools);
}

export const createShipperPool = async (data) => {
  let sPools = [];
  const sPool = new ShipperPool();

  const url = BaseConfig.ApiEndPoint + "/shipperpools";
  const spApiData = await axios.post(url, data);
  // sPools = spApiData.data.data.map((data) =>
  //   sPool.parseApiShipperPoolObject(data)
  // );
  let addShiperpool = spApiData.data.data
  sPools = addShiperpool
  return Promise.resolve(sPools);
}
export const saveShipperPool = async (data) => {
  let sPools = [];
  const sPool = new ShipperPool();
  let payLoad = {
    id: data.id,
    locationid: data.locationid,
    commodityid: data.commodityid,
    effectivedate: data.effectivedate,
    expirationdate: data.expirationdate,
    targetcount: data.targetcount,
  };
  const url = BaseConfig.ApiEndPoint + "/updateShipperPool";
  const spApiData = await axios.post(url, payLoad);

  var updateshipperpool = spApiData.data.data
  sPools = updateshipperpool
  return Promise.resolve(sPools);

}
export const deleteShipperPool = async (data) => {
  let sPools = [];
  const sPool = new ShipperPool();
  try {
    let payLoad = {
      id: data.id,
    };
    const url = BaseConfig.ApiEndPoint + "/deleteShipperPool/";
    const spApiData = await axios.post(url, payLoad);
    sPools = spApiData.data.data;
  } catch (error) {
    return Promise.reject("failed");
  }
  return Promise.resolve(sPools);
}


export const getDedicatedTrailersByLocationID = async (locationId, searchData) => {
  let dedicatedTrailers = [];
  const dTrailer = new DedicatedTrailer();
  try {
    let data = {
      location_id: locationId,
    };
    const url = BaseConfig.ApiEndPoint + "/getdedicatedtrailersbylocation";
    const dtApiData = await axios.post(url, data);
    dedicatedTrailers = dtApiData.data.data;
  } catch (error) {
    return Promise.reject("Error: Unable to retirve the dedicated trailers.");
  }
  return Promise.resolve(dedicatedTrailers);
}

export const getShipperPoolsById = async (shipperpoolId) => {
  try {
    let data = {
      shipperpoolId: shipperpoolId,
    };
    const url = BaseConfig.ApiEndPoint + "/getshipperpooltrailers";
    const shipperpoolTrailers = await axios.post(url, data);
    return Promise.resolve(shipperpoolTrailers.data.data);
  } catch (error) {
    return Promise.reject("Error: Unable to retirve the Shipper Pool Trailers.");
  }

}

export const getDedicatedFilterTrailersByLocationID = async (locationId, searchData) => {
  let dedicatedTrailers = [];
  const dTrailer = new DedicatedTrailer();
  try {
    let data = {
      locationid: locationId,
      search: searchData,
    };
    const url = BaseConfig.ApiEndPoint + "/filterdedicatedtrailer";
    const dtApiData = await axios.post(url, data);


    dedicatedTrailers = dtApiData.data.data.map((data) =>
      dTrailer.parseApiDedicatedTrailerObject(data)
    );
  } catch (error) {
    return Promise.reject("Error: Unable to retirve the dedicated trailers.");
  }
  return Promise.resolve(dedicatedTrailers);
}

export const addDedicatedTrailer = async (data) => {
  let dedicatedTrailers = [];
  const dTrailer = new DedicatedTrailer();
  try {
    const url = BaseConfig.ApiEndPoint + "/assigndedicatedtrailer";
    const dtApiData = await axios.post(url, data);
    dedicatedTrailers = dtApiData.data.data.map((data) =>
      dTrailer.parseApiDedicatedTrailerObject(data)
    );
  } catch (error) {
    return Promise.reject("Error: Unable to add the dedicated trailer.");
  }
  return Promise.resolve(dedicatedTrailers);
}

export const saveDedicatedTrailer = async (data) => {
  let dedicatedTrailers = [];
  const dTrailer = new DedicatedTrailer();
  try {
    const url = BaseConfig.ApiEndPoint + "/updatededicatedtrailer";
    const dtApiData = await axios.post(url, data);
    dedicatedTrailers = dtApiData.data.data.map((data) =>
      dTrailer.parseApiDedicatedTrailerObject(data)
    );
  } catch (error) {
    return Promise.reject("Error: Unable to update the dedicated trailer.");
  }
  return Promise.resolve(dedicatedTrailers);
}
export const saveHoursOfOperationByLocationId = async (id, payload) => {
  let res = {}

  try {

    const url = BaseConfig.ApiEndPoint + "/updatehoursofoperation/" + id;
    const dtApiData = await axios.put(url, payload);
    res = dtApiData



  } catch (error) {
    return Promise.reject("Error: Unable to update the dedicated trailer.");
  }
  return Promise.resolve(res);
}

export const removeDedicatedTrailer = async (data) => {
  let dedicatedTrailers = [];
  const dTrailer = new DedicatedTrailer();
  try {
    const payload = {
      id: data.id,
    };
    const url = BaseConfig.ApiEndPoint + "/removededicatedtrailer";
    const dtApiData = await axios.post(url, payload);
    dedicatedTrailers = dtApiData.data.data;
  } catch (error) {
    return Promise.reject("failed");
  }
  return Promise.resolve(dedicatedTrailers);
}
export const dateFormatter = async (date) => {
  var today = date;
  var dd = String(today?.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();

  today = yyyy + '-' + mm + '-' + dd;
  return today;
}
export const updateWashBays = async (data) => {
  let washBays = [];
  try {
    const url = BaseConfig.ApiEndPoint + "/updatenoofwashbaysbylocationid/" + data.location_id ;
    const dtApiData = await axios.put(url, data);
    washBays = dtApiData.data;

  } catch (error) {
    return Promise.reject("Error: Unable to add the dedicated trailer.");
  }
  return Promise.resolve(washBays);
}

