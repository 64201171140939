import React, { useCallback,useEffect, useState, useContext,useRef } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import ProgressBar from "react-bootstrap/ProgressBar";
import TextField from "@mui/material/TextField";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Autocomplete } from "@mui/lab";
import { ToastContainer, toast } from "react-toastify";
import { ContextData } from "../../components/appsession";
import { getAllTerminals } from "../../services/terminalService";
import { DateTime } from "luxon";
import { addDriverPayConfig, deleteDriverPayConfig, getAllDriverPayConfiguration, updateDriverPayConfig,searchDriverpayKendo } from "../../services/driverPayServer";
import { error } from "jquery";
import { getalldriverspaybase } from "../../services/driverPayCodesMasterService.js";
import filterOperators from '../../utils/filterOperators.js'
import debounce from 'lodash/debounce';
import AuditLogModalComponent from "./auditLogModal";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


const DriverPayConfigTable = (props) => {
  const { accessDisabled,isEditDisable } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [allTerminal, setAllTerminal] = useState([]);
  const [terminalId, setTerminalId] = useState({});
  const [driverType, setDriverType] = useState(null);
  const [payCode, setPayCode] = useState(null);
  const [payRate, setPayRate] = useState("");
  const [effectiveDate, setEffectiveDate] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [driverPayData, setDriverPayData] = useState(null)
  const [userData, setUserData] = useContext(ContextData);
  const [dataId, setDataId] = useState(null);
  const [expirationDate, setExpirationDate] = useState(null)
  const [driverPayConfigData, setDriverPayConfigData] = useState();

  // DEFAULT OPTIONS
  const [driverTypeOptions, setDriverTypeOptions] = useState({
          "*DEFAULT": "ALL DRIVER TYPES",
          FTSH: "FULL TIME SHUTTLE DRIVER",
          FTLH: "FULL TIME LINEHAUL DRIVER",
          FT44: "FULL TIME 4X4 DRIVER"
        });
  const [driverTypeData, setDriverTypeData] = useState([]);

  const [takeNumberOfResult, setTakeNumberOfResult] = useState(25);
  const [skipNumberOfResult, setSkipNumberOfResult] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalResult, setTotalResult] = useState(0);
  const [searchTerms, setSearchTerms] = useState([]);
  const [sortData,setSortData] = useState([{field: "id",dir: "asc"}]);

  const [dataState, setDataState] = useState({
    skip: skipNumberOfResult,
    take: takeNumberOfResult,
    filter: {
      logic: "and",
      filters: [],
    },
    sort: sortData,
  });
  const [dataResult, setDataResult] = useState([]);

 
  const fieldsToField = [
    "id",
    "terminal_fullname",
    "driver_pay_code",
    "driver_type",
    "pay_rate"
];

const fieldsToUpdate = [
  "effective_date",
  "expiry_date",
];

  useEffect(async() => {
      const allDriverPaycodeData = await Promise.all([getalldriverspaybase(),getAllTerminals()]);
      setDriverPayConfigData(allDriverPaycodeData[0]);
      setAllTerminal(allDriverPaycodeData[1]);
      setDriverTypeData(Object.keys(driverTypeOptions)?.map((el)=>`${el} - ${driverTypeOptions[`${el}`]}`))
  }, [])

  // For opening add modal
  const openAddModal = () => {
    setIsAddModalOpen(true)
  }

  // Clear the value of the state variables
  const clearStateValues = () => {
    setTerminalId();
    setDriverType();
    setPayCode();
    setPayRate();
    setEffectiveDate(null);
    setDataId();
    setExpirationDate(null);
  }

  // For closing edit modal
  const handleEditClose = () => {
    setIsEditModalOpen(false);
    clearStateValues();
  };

  // For closing add modal
  const handleAddClose = () => {
    setIsAddModalOpen(false);
    clearStateValues();
  }

  // For closing delete modal
  const handleDeleteClose = () => {
    setIsDeleteModalOpen(false);
    clearStateValues();
  }

  // row click handler
  const rowClickHandler = (item) => {
    // For opening modal
    setIsEditModalOpen(true);
    setDataId(item.id);
    setTerminalId(allTerminal.find(element => element.code === item.terminal_id));
   
    setDriverType(`${item.driver_type} - ${driverTypeOptions[`${item.driver_type}`]}`);
    const selectedPayCode = driverPayConfigData.find(option => option.pay_code === item.pay_code);
    selectedPayCode && setPayCode(selectedPayCode);
    setPayRate(item.pay_rate);
    setEffectiveDate(DateTime.fromISO(item.effective_date));
    setExpirationDate(DateTime.fromISO(item.expiry_date));
  }

  const driverTypeChangeHanler = (e, option) => {
    setDriverType(option);
  }

  const payCodeChangeHandler = (e , option) => {
    setPayCode(option);
    
  };

  const terminalIdChangeHandler = (e, option) => {
    setTerminalId(option);
  }

  // Edit driver pay configuration
  const editDriverPayConfigHandler = async () => {
    const payload = {
      terminal_id: terminalId?.code || "",
      driver_type: driverType?.split('-')[0]?.trim() || "",
      pay_code: payCode.pay_code || "",
      pay_rate: payRate ? parseFloat(payRate) : "",
      effective_date: effectiveDate? DateTime.fromISO(effectiveDate)?.toFormat('yyyy-LL-dd'):DateTime.now().toFormat('yyyy-MM-dd'),
      updated_on: DateTime.now().toMillis(),
      updated_by: userData?.email.split('@')[0],
    };

    if (expirationDate) {
      payload.expiry_date = expirationDate ? DateTime.fromISO(expirationDate)?.toFormat('yyyy-LL-dd') : null;
    }

    if (payload.terminal_id && payload.driver_type && (payload.pay_rate || payload.pay_rate==0) && payload.pay_code) {
      try {
        const response = await updateDriverPayConfig(dataId, payload);
        if (response.status === 200) {
          toast.success("Driver pay configuration has been updated successfully.", "Success");
          handleRefresh();
          setIsEditModalOpen(false);
          clearStateValues();

        } else {
          toast.error("Something went wrong, please try again later.", "Error");
        }
      } catch (error) {
        toast.error("Something went wrong, please try again later.", "Error");
      }
    } else {
      toast.error("Please fill all fields.", "Error");
    }
  }

  // Delete driver pay configuration
  const deleteDriverPayConfigHandler = async () => {
    try {
      const deleteResponse = await deleteDriverPayConfig(dataId);

      if (deleteResponse.status === 200) {
        toast.success("Driver pay configuration has been deleted successfully.", "Success");
        handleRefresh();
        setIsDeleteModalOpen(false);
        clearStateValues();
      }
    } catch (error) {
      setIsDeleteModalOpen(false);
    }
  }
  
  // For creating driver pay configuration
  const addDriverPayConfigHandler = async (e) => {
    const payload = {
      terminal_id: terminalId?.code || "",
      driver_type: driverType?.split('-')[0]?.trim() || "",
      pay_code: payCode?.pay_code || "",
      pay_rate: payRate ? parseFloat(payRate) : undefined,
      effective_date: effectiveDate? DateTime.fromISO(effectiveDate)?.toFormat('yyyy-LL-dd'):DateTime.now().toFormat('yyyy-MM-dd'),
      created_on: DateTime.now().toMillis(),
      created_by: userData?.email.split('@')[0],
    };
    
    console.log('payCode',driverType,driverType?.split('-')[0]?.trim(),payload);
    if (expirationDate) {
      payload.expiry_date = DateTime.fromISO(expirationDate)?.toFormat('yyyy-LL-dd');
    }

    if (payload.terminal_id && payload.driver_type && (payload.pay_rate || payload.pay_rate==0) && payload.pay_code) {
      try {
        const driverAddResponse = await addDriverPayConfig(payload);
        if (driverAddResponse.status === 200) {
          toast.success("Driver Pay Config has been created successfully", "Success");
          handleRefresh();
          setIsAddModalOpen(false);
          clearStateValues();
        } else {
          toast.error("Please fill all fields.", "Error");
        }     
      } catch (err) {
        toast.error("Something went Wrong, Please try again later.", "Error");
      }
    } else {
      toast.error("Please fill all fields.", "Error");
    }
  }

  const handleDateChange = (date) => {
    setEffectiveDate(date);
  }

  const convertdate = (duration) => {
    if(duration){
      return DateTime.fromISO(duration).toFormat("MM/dd/yyyy").toString();
    }else{
      return "No Data";
    }
  };

  useEffect(() => {
    setDataResult(driverPayData);
  }, [driverPayData]);

  const dataStateChange = (event) => {
    const filters = event?.dataState?.filter?.filters || [];
    const sortTerms=event?.dataState?.sort || [];
    const terms = filters && filters.length > 0 ? filters.map(({ field, value, operator }) => ({ field, value, operator })) : [];
    const sorts= sortTerms && sortTerms.length>0 ? sortTerms.map(({ field, dir })=>({ field, dir })) : [];
    setSearchTerms(terms);
    setSortData(sorts);
    const take = event?.dataState?.take;
    const skip = event?.dataState?.skip;
    const page = Math.floor(skip / take) + 1;
    setTakeNumberOfResult(take);
    setSkipNumberOfResult(skip);
    setPageNumber(page);
    setDataState(event.dataState);
  };


  //this useeffect will run on adding something in filter on removing in filter
 useEffect(() => {
  // it will run when there is no filter in kendo
  if (searchTerms.length <= 0 && sortData.length<=0) {
      let newsearchTerms = [];
      let newSort=[];
      debouncedSearch(newsearchTerms, newSort, pageNumber, takeNumberOfResult);
  }else if (searchTerms.length <= 0 && sortData.length>0) {
    let newsearchTerms = [];
    debouncedSearch(newsearchTerms, sortData, pageNumber, takeNumberOfResult);
}

  // it will run when there is filter in kendo
  if (searchTerms.length > 0) {

      let searchTerm;
      // remove null undefined values of fields
      searchTerm = searchTerms.filter(obj => {
          if (
              fieldsToField.includes(obj.field) &&
              (obj.value === null || obj.value === undefined || obj.value === "")
          ) {
              return false; // remove the object
          }
          return true; // keep the object
      });

      searchTerm = searchTerm.filter(obj => {
        if (
            fieldsToUpdate.includes(obj.field) &&
            (obj.value === null || obj.value === undefined || obj.value === "")
        ) {
            return false; // remove the object
        }
        return true; // keep the object
    });
      
      setDataState({
          skip: skipNumberOfResult,
          take: takeNumberOfResult,
          filter: {
              logic: "and",
              filters: searchTerm
          },
          sort: sortData,
      })
      debouncedSearch(searchTerm,sortData, pageNumber, takeNumberOfResult);
  }

}, [searchTerms, pageNumber, takeNumberOfResult]);

  const debouncedSearch = useCallback(
    debounce(async (searchTerms, sortData, page, size) => {
         let arr=searchTerms?.map((el)=>{
          let obj={};
          obj[el?.field]=el?.value
          obj.operator=el?.operator;
          return obj
         })
         let tableName="vw_driver_pay_config"
         const search = await searchDriverpayKendo(tableName,arr,sortData, page, size,);
        if (search.data.length <= 0) {
            setDriverPayData(search.data);
            setTotalResult(Number(search?.count[0]?.count));
        }
        if (search.data.length > 0) {
            setDriverPayData(search.data);
        }
        setTotalResult(Number(search?.count[0]?.count));
    }, 1000),
    []
  );

   // when we edit or add this function will fetch all latest data
    const handleRefresh=()=>{
      debouncedSearch(dataState?.filter?.filters ?? [],dataState.sort ?? [], pageNumber, takeNumberOfResult);
     }

     const auditLogComponentRef = useRef();

  // Audit log modal open handler
  const auditLogModalOpenHandler = (props) => {
    // Access the child component's function using the ref
    auditLogComponentRef.current.fetchAllAuditLogData(props.id, "driver_pay_config");
  };

  return (
    <>
      <ToastContainer
        position='top-right'
        theme='colored'
        autoClose={1000}
      />
      <div className='row special_row_flex'>
        <div className='col-xl-12'>
          <div className='card card_shadow'>
            <div className='card-body'>
              <div className='row'>
                <div className='col-md-5 textadjust_terminal'>
                </div>
                <div className='col-md-7 textadjust_terminaladd_button '>
                  <div className='add_icon'>
                    <button
                      type='button'
                      className='btn_blue btn-blue mr_10 mb-20 '
                      onClick={() => openAddModal()}
                      disabled={isEditDisable}
                    >
                      ADD
                    </button>
                  </div>
                </div>
              </div>

              <div className='table-responsive'>
                {driverPayData?.length >= 0 ? (
                  <Grid
                    filter={dataState.filter}
                    filterable={true}
                    filterOperators={filterOperators}
                    sort={dataState.sort}
                    sortable={true}
                    pageable={{
                      pageSizes: [25, 50, 100],
                      info: true,
                      previousNext: true,
                      buttonCount: 10,
                    }}
                    resizable={true}
                    skip={dataState.skip}
                    take={dataState.take}
                    data={dataResult}
                    onDataStateChange={dataStateChange}
                    onRowClick={(e) => rowClickHandler(e.dataItem)}
                    expandField="expanded"
                    total={totalResult}
                  >
                    <GridColumn
                    field='id'
                    title='ID'
                    filterable={true}
                    width="150px"
                    filter="numeric"
                    cell={(e) => {
                      return (
                        <td>
                          {e.dataItem.id
                            ? e.dataItem.id
                            : ""}
                        </td>
                      );
                    }}
                  />
                    <GridColumn
                      field='terminal_fullname'
                      title='Terminal'
                      filterable={true}
                      width="200px"
                      cell={(e) => {
                        return (
                          <td>
                            {e?.dataItem?.terminal_fullname
                              ? e?.dataItem?.terminal_fullname
                              : ""}
                          </td>
                        );
                      }}
                    />
                    <GridColumn
                      field='driver_pay_code'
                      title='Pay Code'
                      filterable={true}
                      width="200px"
                      cell={(e) => {
                        return (
                          <td>
                            {e.dataItem.driver_pay_code ? e.dataItem.driver_pay_code : ""}
                          </td>
                        );
                      }}
                    />
                    <GridColumn
                      field='driver_type'
                      title='Driver Type'
                      filterable={true}
                      width="200px"
                      cell={(e) => {
                        // const driverTypeOption = driverTypeOptions.find(
                        //   option => option.value === e.dataItem.driver_type
                        // );
                        // const driverTypeLabel = driverTypeOption ? driverTypeOption.label : "";
                        // return <td>{driverTypeLabel}</td>;
                        return (
                          <td>
                            {e.dataItem.driver_type ? `${e.dataItem.driver_type} - ${driverTypeOptions[`${e.dataItem.driver_type}`]}` : ""}
                          </td>
                        );
                      }}
                    />
                    <GridColumn
                      field='pay_rate'
                      title='Amount($)'
                      filterable={true}
                      width="150px"
                      filter="numeric"
                      cell={(e) => {
                        return (
                          <td>
                            {e.dataItem.pay_rate ? Number(e.dataItem.pay_rate) : "No Data"}
                          </td>
                        );
                      }}
                    />
                    <GridColumn
                      field='effective_date'
                      title='Effective Date'
                      filterable={true}
                      filter='date'
                      width="200px"
                      format="{MM/dd/yyyy}"
                      cell={(e) => {
                        return (
                          <td>
                            { e.dataItem.effective_date ?
                               convertdate(e.dataItem.effective_date) : "No Data"
                            }
                          </td>
                        );
                      }}
                    />
                    <GridColumn
                      field='expiry_date'
                      title='Expiration Date'
                      filterable={true}
                      filter='date'
                      format="{MM/dd/yyyy}"
                      width="200px"
                      cell={(e) => {
                        return (
                          <td>
                            { e.dataItem.expiry_date ?  convertdate(e.dataItem.expiry_date) : ""}
                          </td>
                        );
                      }}
                    />
                     <GridColumn
                         field='Action'
                         title='Action'
                         width='150px'
                         cell={(e) => {
                           return(
                             <td>
                               <button
                                 type="button"
                                 className="btn-blue_schedule "
                                 onClick={(i) => auditLogModalOpenHandler(e.dataItem)}
                               >
                                 Audit Log
                               </button>
                             </td>
                           );
                         }}
                        />

                  </Grid>

                ) : (
                  <div>
                    <ProgressBar animated now={100} />
                    <div className='middle loader--text1'> </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <AuditLogModalComponent ref={auditLogComponentRef} />


      {/* Add Driver Pay Config Modal */}
      <Modal
        show={isAddModalOpen}
        onHide={handleAddClose}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Add Driver Pay
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='mt_10'>
            <div className="meterial_autocomplete">
              <label htmlFor="typeHeadAddStatus">Terminal Id*</label>
              <div className='mt_10'>
                <Autocomplete
                  id="combo-box-demo"
                  options={allTerminal}
                  getOptionLabel={(option) => `${option.terminal_id} - ${option.name}`}
                  onChange={terminalIdChangeHandler}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Choose Terminal Id"
                      variant="outlined"
                    />
                  )}
                />
              </div>
            </div>
            <div className='mt_10'>
              <div className="meterial_autocomplete">
                <label htmlFor="typeHeadAddStatus">Driver Type*</label>
                <div className='mt_10'>
                  <Autocomplete
                    id="combo-box-demo"
                    options={driverTypeData}
                    value={driverType}
                    onChange={driverTypeChangeHanler}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Choose Driver Type"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="meterial_autocomplete">
              <label htmlFor="typeHeadAddStatus">Pay Code*</label>
              <Autocomplete
                id="combo-box-demo"
                options={driverPayConfigData}
                getOptionLabel={(option) => 
                  `${option.pay_code} - ${option.pay_description}`
                }
                value={payCode}
                onChange={payCodeChangeHandler}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Choose Pay Code"
                    variant="outlined"
                  />
                )}
              />
            </div>
            <div className='mt_10'>
              <label htmlFor='tankwashcode'>Pay Rate* ($)</label>
              <div className='meterial_autocomplete'>
                <TextField
                  fullWidth
                  variant='outlined'
                  value={payRate}
                  type='number'
                  name='minutes'
                  placeholder='Enter Pay Rate'
                  size='small'
                  onChange={(e) => { setPayRate(e.target.value) }}
                />
              </div>
            </div>
            <div className='mt_10'>
              <label htmlFor='datetime'>Effective Date</label>
              <div className='meterial_autocomplete'>
               <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <DatePicker
                   slotProps={{
                    textField: {
                        error: false,
                        fullWidth: true
                    }
                }}
                    inputVariant="outlined"
                    format="MM/dd/yyyy"
                    id="date-picker-inline"
                    value={effectiveDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </LocalizationProvider>
                </div>
            </div>
            <div className='mt_10'>
              <label htmlFor='datetime'>Expiration Date</label>
              <div className='meterial_autocomplete'>
               <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <DatePicker
                   slotProps={{
                    textField: {
                        error: false,
                        fullWidth: true
                    }
                }}
                    inputVariant="outlined"
                    format="MM/dd/yyyy"
                    id="date-picker-inline"
                    value={expirationDate}
                    onChange={date => setExpirationDate(date)}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </LocalizationProvider>
                </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleAddClose}>Close</Button>
          <Button
            type='submit'
            onClick={addDriverPayConfigHandler}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>


      {/* Edit Driver Pay Config */}
      <Modal
        show={isEditModalOpen}
        onHide={handleEditClose}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Edit Driver Pay
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='mt_10'>
            <div className="meterial_autocomplete">
              <label htmlFor="typeHeadAddStatus">Terminal Id*</label>
              <div className='mt_10'>
                <Autocomplete
                  id="combo-box-demo"
                  options={allTerminal}
                  getOptionLabel={(option) => `${option.terminal_id} - ${option.name}`}
                  value={terminalId}
                  onChange={terminalIdChangeHandler}
                  disabled={isEditDisable}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Choose Terminal Id"
                      variant="outlined"
                      disabled={isEditDisable}
                    />
                  )}
                />
              </div>
            </div>
            <div className='mt_10'>
              <div className="meterial_autocomplete">
                <label htmlFor="typeHeadAddStatus">Driver Type*</label>
                <div className='mt_10'>
                  <Autocomplete
                    id="combo-box-demo"
                    options={driverTypeData}
                    onChange={driverTypeChangeHanler}
                    value={driverType}
                    disabled={isEditDisable}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled={isEditDisable}
                        placeholder="Choose Driver Type"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="meterial_autocomplete">
              <label htmlFor="typeHeadAddStatus">Pay Code*</label>
              <Autocomplete
                id="combo-box-demo"
                options={driverPayConfigData}
                getOptionLabel={(option) => 
                  `${option.pay_code} - ${option.pay_description}`
                }
                onChange={payCodeChangeHandler}
                value={payCode}
                disabled={isEditDisable}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    type="text"

                    placeholder="Choose Pay Code"
                    variant="outlined"
                    disabled={isEditDisable}
                  />
                )}
              />
            </div>
            <div className='mt_10'>
              <label htmlFor='tankwashcode'>Pay Rate* ($)</label>
              <div className='meterial_autocomplete'>
                <TextField
                  fullWidth
                  variant='outlined'
                  value={payRate}
                  type='number'
                  name='minutes'
                  placeholder='Enter Pay Rate'
                  size='small'
                  onChange={(e) => { setPayRate(e.target.value) }}
                  disabled={isEditDisable}
                />
              </div>
            </div>
            <div className='mt_10'>
              <label htmlFor='datetime'>Effective Date</label>
              <div className='meterial_autocomplete'>
               <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <DatePicker
                   slotProps={{
                    textField: {
                        error: false,
                        fullWidth: true
                    }
                }}
                    inputVariant="outlined"
                    format="MM/dd/yyyy"
                    id="date-picker-inline"
                    value={effectiveDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    disabled={isEditDisable}
                  />
                </LocalizationProvider>
                </div>
            </div>
            <div className='mt_10'>
              <label htmlFor='datetime'>Expiration Date</label>
              <div className='meterial_autocomplete'>
               <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <DatePicker
                   slotProps={{
                    textField: {
                        error: false,
                        fullWidth: true
                    }
                }}
                    inputVariant="outlined"
                    format="MM/dd/yyyy"
                    id="date-picker-inline"
                    value={expirationDate}
                    onChange={date => setExpirationDate(date)}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    disabled={isEditDisable}
                  />
                </LocalizationProvider>
                </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleEditClose}>Close</Button>
          <Button
            type='submit'
            onClick={editDriverPayConfigHandler}
            disabled={isEditDisable}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Driver Pay Config */}
      <Modal
        show={isDeleteModalOpen}
        onHide={handleDeleteClose}
        size='sm'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure want to delete driver pay ?</p>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={handleDeleteClose}>Close</Button>
          <Button
            type='submit'
            onClick={deleteDriverPayConfigHandler}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  );
};

export default DriverPayConfigTable;
