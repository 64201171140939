
import React, { useEffect, useState, useContext } from "react";
import "./driverPayCodesMasterTable.css";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import ProgressBar from "react-bootstrap/ProgressBar";
import {
  getalldriverspaybase, createdriverspay, updateDriverPayCodesMaster, deleteDriverPayCodesMaster
} from "../../services/driverPayCodesMasterService.js";
import TextField from "@mui/material/TextField";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Autocomplete } from "@mui/lab";
import { ToastContainer, toast } from "react-toastify";
import { DateTime } from "luxon";
import { ContextData } from "../../components/appsession";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Grid as MuiGrid } from "@mui/material";


const DriverPayCodesMasterTable = (props) => {
  const { accessDisabled ,isEditDisable, setEditDisable} = props;
  const [userData, setUserData] = useContext(ContextData);
  const [driverPayCodesData, setDriverPayCodesData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [id, setId] = useState("");
  const [payDescription, setPayDescription] = useState("");
  const [approverNone, setApproverNone] = useState("");
  const [approverPlanner, setApproverPlanner] = useState("");
  const [approverTerminal, setApproverTerminal] = useState("");
  const [authorizationNone, setAuthorizationNone] = useState("");
  const [authorizationPlanner, setAuthorizationPlanner] = useState("");
  const [authorizationTerminal, setAuthorizationTerminal] = useState("");
  const [creatorAuto, setCreatorAuto] = useState("");
  const [creatorPlanner, setCreatorPlanner] = useState("");
  const [creatorTerminal, setCreatorTerminal] = useState("");
  const [firstRowActive, setFirstRowActive] = useState("");
  const [firstSecondActive, setSecondRowActive] = useState("");
  const [movementRequired, setMovementRequired] = useState(false);
  const [active, setActive] = useState("");
  const [payCode, setPayCode] = useState("");
  const [payRate, setPayRate] = useState("");
  const [payRateEditable, setPayRateEditable] = useState("");
  const [payrollBatch, setPayRollBatch] = useState("");
  const [payrollEarningCode, setPayRollEarningCode] = useState("");
  const [payUomEditable, setPayUomEditable] = useState("");
  const [unitType, setUnitType] = useState("");
  const unitTypeOptions = ["FLAT", "MILE", "HOUR"];
  const [itemSelected, setitemSelected] = useState("");
  const [driverPayCodesMaster, setDriverPayCodesMaster] = useState(false);
  const [isManual, setIsManual] = useState(false);
  const [isAuthorizationRequired, setIsAuthorizationRequired] = useState(false);
  const [isApprovalRequired, setIsApprovalRequired] = useState(false)

  const customStyles = {
    closeButton: {
      padding:"10px",
      border: "none",
      background: "none",
      cursor: "pointer",
    },
  };

  const [dataState, setDataState] = useState({
    skip: 0,
    take: 25,
    filter: {
      logic: "and",
      filters: [],
    },
    sort: [
      {
        field: "",
        dir: "desc",
      },
    ],
  });

  const [dataResult, setDataResult] = useState(
    process(driverPayCodesData, dataState)
  );

  const dataStateChange = (event) => {
    setDataResult(process(driverPayCodesData, event.dataState));
    setDataState(event.dataState);
  };

  useEffect(() => {
    setDataResult(process(driverPayCodesData, dataState));
  }, [driverPayCodesData]);

  useEffect(async () => {
    setIsLoading(true);
    const driverPayCodesMasterData = await getalldriverspaybase();

    setDriverPayCodesData(driverPayCodesMasterData);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    try {
      if (userData?.roles?.rolespermission) {

        let permissionList = userData?.roles?.rolespermission
        permissionList.forEach(permission => {

          if (permission.permissiondetail.code.match("DriverpayCodesMaster")) {
            if (permission.can_edit === false) {
              setDriverPayCodesMaster(true)
            }
          }
        })

      }
    }
    catch (err) {

    }

    setitemSelected(window.location.pathname)
  }, [userData])

  const clearStateValues = () => {
    setId("");
    setActive("");
    setPayCode("");
    setPayDescription("")
    setPayRate("");
    setPayRateEditable("");
    setPayUomEditable("");   
    setCreatorAuto("");
    setCreatorPlanner("");
    setCreatorTerminal("");
    setAuthorizationTerminal("");
    setAuthorizationPlanner("");
    setAuthorizationNone("");
    setApproverNone("");
    setApproverPlanner("");
    setApproverTerminal("");
    setUnitType("");
    setIsApprovalRequired(false);
    setMovementRequired(false)
    setIsManual(false)
    setIsAuthorizationRequired(false);
    setPayRollBatch("");
    setPayRollEarningCode("");
  }

  const unitChangeHandler = (e, value) => {
    if (value !== (undefined || null || "") && value === "FLAT") {
      setUnitType("FLAT");
    } else if (value !== (undefined || null || "") && value === "MILE") {
      setUnitType("MILE");
    } else if (value !== (undefined || null || "") && value === "HOUR") {
      setUnitType("HOUR");
    }
    else if (value !== (undefined || null || "") && value === null) {
      setUnitType(null);
    }
  };

  // For row click handler
  const rowClickHandler = (item) => {
    setEditModalShow(true);
    setId(item.id);
    setActive(item.active);
    setPayCode(item.pay_code);
    setPayDescription(item.pay_description);
    setPayRate(parseInt(item.pay_rate));
    setPayRateEditable(item.pay_rate_editable);
    setPayUomEditable(item.pay_uom_editable);
    setMovementRequired(item.movement_required);
    setCreatorAuto(item.creator_auto);
    setCreatorPlanner(item.creator_planner);
    setCreatorTerminal(item.creator_terminal);
    setAuthorizationTerminal(item.authorization_terminal);
    setAuthorizationPlanner(item.authorization_planner);
    setAuthorizationNone(item.authorization_none);
    setApproverNone(item.approver_none);
    setApproverPlanner(item.approver_planner);
    setApproverTerminal(item.approver_terminal);
    setIsApprovalRequired(item.is_approval_required);
    setIsManual(item.allow_manual_entry);
    setIsAuthorizationRequired(item.is_authorization_required);
    setPayRollBatch(item.payroll_batch);
    setPayRollEarningCode(item.payroll_earning_code);
    setUnitType(
      item.pay_uom === "FLAT"
        ? "FLAT"
        : item.pay_uom === "MILE"
        ? "MILE"
        : item.pay_uom === "HOUR"
        ? "HOUR"
        : ""
    );
  };

  // For closing edit modal
  const handleClose = () => {
    setEditModalShow(false);
    // For clearing the states values after successfully updation
    clearStateValues();
  };

  // For handling save button in edit modal
  const updateHandler = async () => {
    const payload = {
      "pay_code": payCode !== (undefined || null || "") ? payCode : "",
      "pay_description": payDescription !== (undefined || null || "") ? payDescription : "",
      "pay_uom": unitType !== (undefined || null || "") ? unitType : "",
      "pay_uom_editable": payUomEditable !== (undefined || null || "") ? payUomEditable : "",
      "pay_rate": payRate !== (undefined || null || "") ? payRate : "",
      "pay_rate_editable": payRateEditable !== (undefined || null || "") ? payRateEditable : false,
      // "effective_date": "2022-12-22",
      "movement_required": movementRequired !== (undefined || null || "") ? movementRequired : false,
      "creator_auto": creatorAuto !== (undefined || null || "") ? creatorAuto : false,
      "creator_planner": creatorPlanner !== (undefined || null || "") ? creatorPlanner : false,
      "creator_terminal": creatorTerminal !== (undefined || null || "") ? creatorTerminal : false,
      "authorization_none": authorizationNone !== (undefined || null || "") ? authorizationNone : false,
      "authorization_planner": authorizationPlanner !== (undefined || null || "") ? authorizationPlanner : false,
      "authorization_terminal": authorizationTerminal !== (undefined || null || "") ? authorizationTerminal : false,
      "approver_none": approverNone !== (undefined || null || "") ? approverNone : false,
      "approver_planner": approverPlanner !== (undefined || null || "") ? approverPlanner : false,
      "approver_terminal": approverTerminal !== (undefined || null || "") ? approverTerminal : false,
      "active": active !== (undefined || null || "") ? active : false,
      "is_authorization_required": isAuthorizationRequired,
      "payroll_batch": payrollBatch,
      "payroll_earning_code": payrollEarningCode,
      "is_approval_required": isApprovalRequired,
      "allow_manual_entry": isManual,
      "updated_by": userData?.email.split('@')[0],
      "updated_on": DateTime.now().toMillis(),
      // "created_by": "postgres",
      // "created_on": "2023-03-07T16:24:50.711Z",
    };
    if(!payCode){
      return  toast.error("Pay Code is required", "Error");
     }
     if(!payDescription){
      return  toast.error("Description is required", "Error");
     }
     if(!payrollBatch){
      return  toast.error("Payroll system batch is required", "Error");
     }
     if(!payrollEarningCode){
      return  toast.error("Payroll system unit code is required", "Error");
     }
     if(!payRate){
      return  toast.error("Rate is required", "Error");
     }
     if(!unitType  ){
      return  toast.error("Unit is required", "Error");
     }
     else{
      try {
        const data = await updateDriverPayCodesMaster(id, payload);
        toast.success("Fastpay updated successfully", "Success");
        setEditModalShow(false);
        if (data.includes(1)) {
          setIsLoading(true);
          setDriverPayCodesData([]);
          const driverPayCodesMasterData = await getalldriverspaybase();
          setDriverPayCodesData(driverPayCodesMasterData);
          setIsLoading(false);
          setEditModalShow(false);
  
          // For clearing the states values after successfully updation
          clearStateValues();
  
        }
      } catch (e) {
        toast.error("Fastpay not updated successfully", "Error");
      }
     }
   
  }

  ////////////////////////////  ADD HANDLERS ////////////////////////
  // For opening add modal
  const openAddModal = () => {
    setModalShow(true);
  }

  // For handling save button in add modal
  const createHandler =async () => {
    const payload = {
      "pay_code": payCode !== (undefined || null || "") ? payCode : "",
      "pay_description": payDescription !== (undefined || null || "") ? payDescription : "",
      "pay_uom": unitType !== (undefined || null || "") ? unitType : "",
      "pay_uom_editable": payUomEditable !== (undefined || null || "") ? payUomEditable : false,
      "pay_rate": payRate !== (undefined || null || "") ? payRate : "",
      "pay_rate_editable": payRateEditable !== (undefined || null || "") ? payRateEditable : false,
      "effective_date": DateTime.now(),
      "movement_required": movementRequired !== (undefined || null || "") ? movementRequired : false,
      "creator_auto": creatorAuto !== (undefined || null || "") ? creatorAuto : false,
      "creator_planner": creatorPlanner !== (undefined || null || "") ? creatorPlanner : false,
      "creator_terminal": creatorTerminal !== (undefined || null || "") ? creatorTerminal : false,
      "authorization_none": authorizationNone !== (undefined || null || "") ? authorizationNone : false,
      "authorization_planner": authorizationPlanner !== (undefined || null || "") ? authorizationPlanner : false,
      "authorization_terminal": authorizationTerminal !== (undefined || null || "") ? authorizationTerminal : false,
      "approver_none": approverNone !== (undefined || null || "") ? approverNone : false,
      "approver_planner": approverPlanner !== (undefined || null || "") ? approverPlanner : false,
      "approver_terminal": approverTerminal !== (undefined || null || "") ? approverTerminal : false,
      "active": active !== (undefined || null || "") ? active : false,
    
      "is_authorization_required": isAuthorizationRequired,
      "payroll_batch": payrollBatch,
      "payroll_earning_code": payrollEarningCode,
      "is_approval_required": isApprovalRequired,
      "allow_manual_entry": isManual,
      "updated_by": userData?.email.split('@')[0],
      "updated_on": DateTime.now(),
      "created_by":userData?.email.split('@')[0],
      "created_on": DateTime.utc(),
    };
    if(!payCode){
      return  toast.error("Pay Code is required", "Error");
     }
     if(!payDescription){
      return  toast.error("Description is required", "Error");
     }
     if(!payrollBatch){
      return  toast.error("Payroll system batch is required", "Error");
     }
     if(!payrollEarningCode){
      return  toast.error("Payroll system unit code is required", "Error");
     }
     if(!payRate){
      return  toast.error("Rate is required", "Error");
     }
     if(!unitType  ){
      return  toast.error("Unit is required", "Error");
     }
     else{
      try {
        const data = await createdriverspay(payload);
        toast.success("Fastpay created successfully", "Success");
        setModalShow(false);
        if (data) {
          setIsLoading(true);
          setDriverPayCodesData([]);
          const driverPayCodesMasterData = await getalldriverspaybase();
  
          setDriverPayCodesData(driverPayCodesMasterData);
          setIsLoading(false);
        };
  
        clearStateValues();
      } catch (err) {
        toast.error("Fastpay not created!", "Error");
      }
     }
   

  };

  // For closing add modal
  const closeHandler = () => {
    setModalShow(false);
    clearStateValues();
  }

  const convertdatetime = (duration) => {
    if (duration) {
      let date;
      if (isNaN(duration)) {
        date = Date.parse(duration);
      } else {
        date = parseInt(duration);
      }
      return DateTime.fromMillis(parseInt(date)).toFormat("MM/dd/yyyy").toString();
    } else {
      return "No Data";
    }
  };


  return (
    <>
      <ToastContainer
        position='top-right'
        theme='colored'
        autoClose={1000}
      />
      <div className='row special_row_flex'>
        <div className='col-xl-12'>
          <div className='card card_shadow'>
            <div className='card-body'>
              <div className='row'>
                <div className='col-md-5 textadjust_terminal'>
                </div>
                <div className='col-md-7 textadjust_terminaladd_button '>
                  <div className='add_icon'>

                    <button
                      type='button'
                      className='btn_blue btn-blue mr_10 mb-20 '
                      onClick={() => openAddModal()}
                      disabled={isEditDisable}
                    >
                      ADD
                    </button>

                  </div>
                </div>
              </div>

              <div className='table-responsive'>
                {driverPayCodesData?.length > 0 ? (
                  <Grid
                    filter={dataState.filter}
                    filterable={true}
                    scrollable={true}
                    sort={dataState.sort}
                    sortable={true}
                    pageable={{
                      pageSizes: [5, 10, 20, 25, 50, 100],
                      info: true,
                      previousNext: true,
                      buttonCount: 10,
                    }}
                    resizable={true}
                    skip={dataState.skip}
                    take={dataState.take}
                    data={dataResult}
                    onDataStateChange={dataStateChange}
                    onRowClick={(e) => rowClickHandler(e.dataItem)}
                  >

                    <GridColumn
                      field='pay_code'
                      title='Pay Code'
                      filterable={true}
                      width="150px"
                    />
                    <GridColumn
                      field='pay_description'
                      title='Pay Description'
                      width="150px"
                      filterable={true}
                    />
                    <GridColumn
                      field='movement_required'
                      title='Movement Required'
                      width="180px"
                      cell={(e) => {
                        return (
                          <td
                            style={{
                              color: e.dataItem.movement_required
                                ? "#259125"
                                : "#FF0000",
                            }}
                          >
                            {e.dataItem.movement_required!==null ? e.dataItem.movement_required ? "True" : "False" : ""}
                          </td>
                        );
                      }}
                      filterable={true}
                      filter={"boolean"}
                    />
                    <GridColumn
                      field='pay_uom'
                      title='Pay Uom'
                      width="150px"
                      filterable={true}
                    />
                    <GridColumn
                      field='pay_uom_editable'
                      title='Pay Uom Editable'
                      width="180px"
                      cell={(e) => {
                        return (
                          <td
                            style={{
                              color: e.dataItem.pay_uom_editable
                                ? "#259125"
                                : "#FF0000",
                            }}
                          >
                            {e.dataItem.pay_uom_editable!==null ? e.dataItem.pay_uom_editable ? "True" : "False" : ""}
                          </td>
                        );
                      }}
                      filterable={true}
                      filter={"boolean"}
                    />
                    <GridColumn
                    field='payroll_batch'
                    title='Payroll Batch'
                    width="180px"
                    filterable={true}
                    />
                    <GridColumn
                     field='payroll_earning_code'
                     title='Payroll Earning Code'
                     width="200px"
                     filterable={true}
                    />

                    <GridColumn
                      field='pay_rate'
                      title='Pay Rate'
                      width="150px"
                      filterable={true}
                    />
                    <GridColumn
                      field='pay_rate_editable'
                      title='Pay Rate Editable'
                      width="180px"
                      cell={(e) => {
                        return (
                          <td
                            style={{
                              color: e.dataItem.pay_rate_editable
                                ? "#259125"
                                : "#FF0000",
                            }}
                          >
                            {e.dataItem.pay_rate_editable!==null ? e.dataItem.pay_rate_editable ? "True" : "False" : ""}
                          </td>
                        );
                      }}
                      filterable={true}
                      filter={"boolean"}
                    />
                    <GridColumn
                    field="allow_manual_entry"
                    title="Allow Manual Entry"
                    width="200px"
                    cell={(e) => {
                      return (
                        <td
                          style={{
                            color: e.dataItem.allow_manual_entry
                              ? "#259125"
                              : "#FF0000",
                          }}
                        >
                          {e.dataItem.allow_manual_entry!==null ? e.dataItem.allow_manual_entry ? "True" : "False" : ""}
                        </td>
                      );
                    }}
                    filterable={true}
                    filter={"boolean"}
                    />
                    <GridColumn
                    field="is_authorization_required"
                    title="Is Authorization Required"
                    width="200px"
                    cell={(e) => {
                      return (
                        <td
                          style={{
                            color: e.dataItem.is_authorization_required
                              ? "#259125"
                              : "#FF0000",
                          }}
                        >
                          {e.dataItem.is_authorization_required!==null ? e.dataItem.is_authorization_required ? "True" : "False" : ""}
                        </td>
                      );
                    }}
                    filterable={true}
                    filter={"boolean"}
                    />
                    <GridColumn
                    field="is_approval_required"
                    title="Is Aprroval Required"
                    width="200px"
                    cell={(e) => {
                      return (
                        <td
                          style={{
                            color: e.dataItem.is_approval_required
                              ? "#259125"
                              : "#FF0000",
                          }}
                        >
                          {e.dataItem.is_approval_required!==null ? e.dataItem.is_approval_required ? "True" : "False" : ""}
                        </td>
                      );
                    }}
                    filterable={true}
                    filter={"boolean"}
                    />
                    
                    {/* <GridColumn
                      field='creator_auto'
                      title='Creator Auto'
                      width="150px"
                      filterable={true}
                    />
                    <GridColumn
                      field='creator_planner'
                      title='Creator Planner'
                      width="150px"
                      filterable={true}
                    />
                    <GridColumn
                      field='creator_terminal'
                      title='Creator Terminal'
                      width="150px"
                      filterable={true}
                    />
                    <GridColumn
                      field='authorization_terminal'
                      title='Authorization Terminal'
                      width="180px"
                      filterable={true}
                    />
                    <GridColumn
                      field='authorization_planner'
                      title='Authorization Planner'
                      width="180px"
                      filterable={true}
                    />
                    <GridColumn
                      field='authorization_none'
                      title='Authorization None'
                      width="180px"
                      filterable={true}
                    />
                    <GridColumn
                      field='approver_none'
                      title='Approver None'
                      width="150px"
                      filterable={true}
                    />
                    <GridColumn
                      field='approver_planner'
                      title='Approver Planner'
                      width="150px"
                      filterable={true}
                    />
                    <GridColumn
                      field='approver_terminal'
                      title='Approver Terminal'
                      width="150px"
                      filterable={true}
                    /> */}
                    <GridColumn
                      field='effective_date'
                      title='Effective Date'
                      width="150px"
                      filterable={true}
                      cell={(e) => {
                        return (
                          <td>
                            {e.dataItem.effective_date ?
                             convertdatetime(e.dataItem.effective_date)
                              : "No Data"
                            }
                          </td>
                        );
                      }}
                    />
                    <GridColumn
                      field='created_by'
                      title='Created By'
                      width="150px"
                      filterable={true}
                    />
                    <GridColumn
                      field='created_on'
                      title='Created On'
                      width="150px"
                      filterable={true}
                      cell={(e) => {
                        return (
                          <td>
                            {e.dataItem.created_on ? convertdatetime(e.dataItem.created_on)
                              : "No Data"
                            }
                          </td>
                        );
                      }}
                    />
                    <GridColumn
                      field='updated_by'
                      title='Updated By'
                      width="150px"
                      filterable={true}
                    />
                    <GridColumn
                      field='updated_on'
                      title='Updated On'
                      width="150px"
                      filterable={true}
                      cell={(e) => {
                        return (
                          <td>
                            {e.dataItem.updated_on ? convertdatetime(e.dataItem.updated_on)
                              : "No Data"
                            }
                          </td>
                        );
                      }}
                    />
                  </Grid>

                ) : isLoading ? (
                  <div>
                    <ProgressBar animated now={100} />
                    <div className='middle loader--text1'> </div>
                  </div>
                ) : (
                  <div className='text-center'>No data found</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* MODAL FOR ADD */}
      <Modal
        show={modalShow}
        onHide={closeHandler}
        dialogClassName="driverPayEdit_modal_width"
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton  style={customStyles.closeButton}>
          <Modal.Title id='contained-modal-title-vcenter'>
            Driver Pay Code Configuration
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MuiGrid container direction="column">
            <MuiGrid container direction="row" alignItems="center" spacing={3}>
              <MuiGrid item xs={5} >
                <label>Pay Code*</label>
                <div className="meterial_autocomplete_driver">
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Enter Pay Code"
                    value={payCode}
                    onChange={(e) => { setPayCode(e.target.value) }}                   
                  />
                </div>
              </MuiGrid>
              <MuiGrid item xs={5}>
                <label>Description*</label>
                <div className="meterial_autocomplete_driver">
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    value={payDescription}
                    onChange={(e) => { setPayDescription(e.target.value) }}
                    placeholder="Description"
                  />
                </div>
              </MuiGrid>
              <MuiGrid item xs={2}>
                <label>&nbsp;</label>
                <div className="div_width">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={active}
                          onChange={(e) => setActive(e.target.checked)}
                          size="small"
                        />
                      } label={
                        <span className="checkbox_span">
                          Active
                        </span>
                      }
                    />
                  </FormGroup>
                </div>
              </MuiGrid>
            </MuiGrid>
            <MuiGrid container direction="row" alignItems="center" spacing={3}>
              <MuiGrid item xs={5}>
                <label>Payroll System Batch*</label>
                <div className="meterial_autocomplete_driver">
                  <TextField id="outlined-basic" variant="outlined" placeholder="Enter Payroll System Batch" value={payrollBatch} onChange={(e)=>{setPayRollBatch(e.target.value)}} />
                </div>
              </MuiGrid>
              <MuiGrid item xs={5}>
                <label>Payroll System Earnings Code*</label>
                <div className="meterial_autocomplete_driver">
                  <TextField id="outlined-basic" variant="outlined" placeholder="Enter Payroll System Earnings..." value={payrollEarningCode} onChange={(e)=>{setPayRollEarningCode(e.target.value)}}/>
                </div>
              </MuiGrid>  
            </MuiGrid>

            <MuiGrid container direction="row" alignItems="center" spacing={3}>
              <MuiGrid item xs={5} sm={5} lg={5} md={5} xl={5}>
                <label>Unit*</label>
                <div className="meterial_autocomplete_driver">
                  <Autocomplete
                    id="combo-box-demo"
                    options={unitTypeOptions}
                    getOptionLabel={(option) => `${option}`}
                    value={unitType}
                    onChange={unitChangeHandler}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Choose Unit Type"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
              </MuiGrid>             
              <MuiGrid item xs={2}>
                <label>&nbsp;</label>
                <div className="div_width">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={payUomEditable}
                          onChange={(e) => setPayUomEditable(e.target.checked)}
                          size="small"
                        />
                      } label={
                        <span className="checkbox_span">
                          Editable
                        </span>}
                    />
                  </FormGroup>
                </div>
              </MuiGrid>
            </MuiGrid>

            <MuiGrid container direction="row" alignItems="center" spacing={3}>            
              <MuiGrid item xs={5}>
                <label>Rate*</label>
                <div className="meterial_autocomplete_driver">
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Enter Rate"
                    type="number"
                    value={payRate}
                    onChange={(e) => { setPayRate(e.target.value) }}

                  />
                </div>
              </MuiGrid>    
              <MuiGrid item xs={2}>
                <label>&nbsp;</label>
                <div className="div_width">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={payRateEditable}
                          onChange={(e) => setPayRateEditable(e.target.checked)}
                          size="small"
                        />
                      } label={
                        <span className="checkbox_span">
                          Editable
                        </span>}
                    />
                  </FormGroup>
                </div>
              </MuiGrid>
            </MuiGrid>

            <MuiGrid container direction="row" alignItems="center" spacing={3}>
              <MuiGrid item xs={5}>
                <div className="editModalContainer">
                  <FormGroup>
                    <div>
                      <label htmlFor="movement"> <span className="span_dark"> Movements Required</span></label>

                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={movementRequired}
                            onChange={(e) => setMovementRequired(e.target.checked)}
                            size="small"
                            id="movement"
                          />
                        }
                      />

                    </div>
                  </FormGroup>
                </div>
              </MuiGrid>
              <MuiGrid item xs={5}>
                <div className="editModalContainer">
                  <FormGroup>
                    <div>
                      <label htmlFor="manual"> <span className="span_dark"> Allow Manual Entry</span></label>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isManual}
                            onChange={(e) => setIsManual(e.target.checked)}
                            size="small"
                            id="manual"
                          />
                        }
                      />
                    </div>

                  </FormGroup>
                </div>
              </MuiGrid>
            </MuiGrid>
            <MuiGrid container direction="row" alignItems="center" spacing={3}>
              <MuiGrid item xs={5}>
                <div className="editModalContainer">
                  <FormGroup>
                    <div>
                      <label htmlFor="authorization" className="span_dark"><span className="span_dark">
                        Requires Authorization
                      </span></label>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isAuthorizationRequired}
                            onChange={(e) => setIsAuthorizationRequired(e.target.checked)}
                            size="small"
                            id="authorization"
                          />
                        }
                      />
                    </div>

                  </FormGroup>
                </div>
              </MuiGrid>
              <MuiGrid item xs={5}>
                <div className="editModalContainer">

                  <FormGroup>
                    <div>
                      <label htmlFor="approval" className="span_dark"><span className="span_dark">
                        Requires Approval
                      </span></label>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isApprovalRequired}
                            onChange={(e) => setIsApprovalRequired(e.target.checked)}
                            size="small"
                            id="approval"
                          />
                        }
                      />
                    </div>

                  </FormGroup>
                </div>
              </MuiGrid>
            </MuiGrid>


            {/* <MuiGrid conatiner direction="column">
              <MuiGrid item xs={12}>
                <label className="span_dark">Creators</label>
              </MuiGrid>
              <MuiGrid container direction="row">
                <FormGroup className="div_width">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={creatorAuto}
                        onChange={(e) => setCreatorAuto(e.target.checked)}
                        size="small"
                      />
                    } label={
                      <span className="checkbox_span">
                        Auto
                      </span>}
                  />
                </FormGroup>
                <FormGroup className="div_width div_width--1">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={creatorPlanner}
                        onChange={(e) => setCreatorPlanner(e.target.checked)}
                        size="small"
                      />
                    } label={
                      <span className="checkbox_span">
                        Planner
                      </span>}
                  />
                </FormGroup>
                <FormGroup className="div_width">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={creatorTerminal}
                        onChange={(e) => setCreatorTerminal(e.target.checked)}
                        size="small"
                      />
                    } label={
                      <span className="checkbox_span">
                        Terminal
                      </span>}
                  />
                </FormGroup>
              </MuiGrid>
            </MuiGrid> */}

            {/* Fifth column */}
            {/* <MuiGrid direction="column">
              <MuiGrid item xs={12}>
                <label className="span_dark">Authorizers</label>
              </MuiGrid>
              <MuiGrid container direction="row">
                <FormGroup className="div_width">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={authorizationNone}
                        onChange={(e) => setAuthorizationNone(e.target.checked)}
                        size="small"
                      />
                    } label={
                      <span className="checkbox_span">
                        None
                      </span>}
                  />
                </FormGroup>
                <FormGroup className="div_width div_width--1">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={authorizationPlanner}
                        onChange={(e) => setAuthorizationPlanner(e.target.checked)}
                        size="small"
                      />
                    } label={
                      <span className="checkbox_span">
                        Planner
                      </span>}
                  />
                </FormGroup>
                <FormGroup className="div_width">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={authorizationTerminal}
                        onChange={(e) => setAuthorizationTerminal(e.target.checked)}
                        size="small"
                      />
                    } label={
                      <span className="checkbox_span">
                        Terminal
                      </span>}
                  />
                </FormGroup>
              </MuiGrid>
            </MuiGrid> */}

            {/* Sixth column */}

            {/* <MuiGrid container direction="column">
              <MuiGrid item xs={12}>
                <label className="span_dark">Approvers</label>
              </MuiGrid>
              <MuiGrid container direction="row">
                <FormGroup className="div_width">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={approverNone}
                        onChange={(e) => setApproverNone(e.target.checked)}
                        size="small"
                      />
                    } label={
                      <span className="checkbox_span">
                        None
                      </span>}
                  />
                </FormGroup>
                <FormGroup className="div_width div_width--1">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={approverPlanner}
                        onChange={(e) => setApproverPlanner(e.target.checked)}
                        size="small"
                      />
                    } label={
                      <span className="checkbox_span">
                        Planner
                      </span>}
                  />
                </FormGroup>
                <FormGroup className="div_width">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={approverTerminal}
                        onChange={(e) => setApproverTerminal(e.target.checked)}
                        size="small"
                      />
                    } label={
                      <span className="checkbox_span">
                        Terminal
                      </span>}
                  />
                </FormGroup>
              </MuiGrid>
            </MuiGrid> */}

          </MuiGrid>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeHandler}>Close</Button>
          <Button
            type='submit'
            onClick={createHandler}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* MODAL FOR EDIT */}
      <Modal
        show={editModalShow}
        onHide={handleClose}
        dialogClassName="driverPayEdit_modal_width"
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton style={customStyles.closeButton}>
          <Modal.Title id='contained-modal-title-vcenter'>
            Driver Pay Code Configuration
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MuiGrid container direction="column">
            <MuiGrid container direction="row" alignItems="center" spacing={3}>
              <MuiGrid item xs={5} >
                <label>Pay Code*</label>
                <div className="meterial_autocomplete_driver">
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    value={payCode}
                    onChange={(e) => { setPayCode(e.target.value) }}
                    placeholder="Enter Pay Code"
                    disabled={isEditDisable}    
                  />
                </div>
              </MuiGrid>
              <MuiGrid item xs={5}>
                <label>Description*</label>
                <div className="meterial_autocomplete_driver">
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    value={payDescription}
                    onChange={(e) => { setPayDescription(e.target.value) }}
                    placeholder="Description"
                    disabled={isEditDisable}    
                  />
                </div>
              </MuiGrid>
              <MuiGrid item xs={2}>
                <label>&nbsp;</label>
                <div className="div_width">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={active}
                          onChange={(e) => setActive(e.target.checked)}
                          size="small"
                          disabled={isEditDisable}
                        />
                      } label={
                        <span className="checkbox_span">
                          Active
                        </span>
                      }
                    />
                  </FormGroup>
                </div>
              </MuiGrid>
            </MuiGrid>
            <MuiGrid container direction="row" alignItems="center" spacing={3}>
              <MuiGrid item xs={5}>
                <label>Payroll System Batch*</label>
                <div className="meterial_autocomplete_driver">
                  <TextField id="outlined-basic"
                    variant="outlined"
                    placeholder="Enter Payroll System Batch"
                    value={payrollBatch}
                    onChange={(e) => { setPayRollBatch(e.target.value) }}
                    disabled={isEditDisable}    
                  />
                </div>
              </MuiGrid>
              <MuiGrid item xs={5}>
                <label>Payroll System Earnings Code*</label>
                <div className="meterial_autocomplete_driver">
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Enter Payroll System Earnings..."
                    value={payrollEarningCode}
                    onChange={(e) => { setPayRollEarningCode(e.target.value) }}
                    disabled={isEditDisable}    
                  />
                </div>
              </MuiGrid>          
            </MuiGrid>

            <MuiGrid container direction="row" alignItems="center" spacing={3}>
              <MuiGrid item xs={5} sm={5} lg={5} md={5} xl={5}>
                <label>Unit*</label>
                <div className="meterial_autocomplete_driver">
                  <Autocomplete
                    id="combo-box-demo"
                    options={unitTypeOptions}
                    getOptionLabel={(option) => `${option}`}
                    value={unitType}
                    onChange={unitChangeHandler}
                    disabled={isEditDisable}    
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Choose Unit Type"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
              </MuiGrid>
              <MuiGrid item xs={2}>
                <label>&nbsp;</label>
                <div className="div_width">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={payUomEditable}
                          onChange={(e) => setPayUomEditable(e.target.checked)}
                          size="small"
                          disabled={isEditDisable}
                        />
                      } label={
                        <span className="checkbox_span">
                          Editable
                        </span>}
                    />
                  </FormGroup>
                </div>
              </MuiGrid>
            </MuiGrid>

            <MuiGrid container direction="row" alignItems="center" spacing={3}>
              <MuiGrid item xs={5}>
                <label>Rate*</label>
                <div className="meterial_autocomplete_driver">
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Enter Rate"
                    type="number"
                    value={payRate}
                    onChange={(e) => { setPayRate(e.target.value) }}
                    disabled={isEditDisable}    
                  />
                </div>
              </MuiGrid>
              <MuiGrid item xs={2}>
                <label>&nbsp;</label>
                <div className="div_width">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={payRateEditable}
                          onChange={(e) => setPayRateEditable(e.target.checked)}
                          size="small"
                          disabled={isEditDisable}
                        />
                      } label={
                        <span className="checkbox_span">
                          Editable
                        </span>}
                    />
                  </FormGroup>
                </div>
              </MuiGrid>
            </MuiGrid>

            <MuiGrid container direction="row" alignItems="center" spacing={3}>
              <MuiGrid item xs={5}>
                <div className="editModalContainer">
                  <FormGroup>
                    <div>
                      <label htmlFor="movement"> <span className="span_dark"> Movements Required</span></label>

                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={movementRequired}
                            onChange={(e) => setMovementRequired(e.target.checked)}
                            size="small"
                            id="movement"
                            disabled={isEditDisable}
                          />
                        }
                      />

                    </div>
                  </FormGroup>
                </div>
              </MuiGrid>
              <MuiGrid item xs={5}>
                <div className="editModalContainer">
                  <FormGroup>
                    <div>
                      <label htmlFor="manual"> <span className="span_dark"> Allow Manual Entry</span></label>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isManual}
                            onChange={(e) => setIsManual(e.target.checked)}
                            size="small"
                            id="manual"
                            disabled={isEditDisable}
                          />
                        }
                      />
                    </div>

                  </FormGroup>
                </div>
              </MuiGrid>
            </MuiGrid>
            <MuiGrid container direction="row" alignItems="center" spacing={3}>
              <MuiGrid item xs={5}>
                <div className="editModalContainer">
                  <FormGroup>
                    <div>
                      <label htmlFor="authorization" className="span_dark"><span className="span_dark">
                        Requires Authorization
                      </span></label>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isAuthorizationRequired}
                            onChange={(e) => setIsAuthorizationRequired(e.target.checked)}
                            size="small"
                            id="authorization"
                            disabled={isEditDisable}
                          />
                        }
                      />
                    </div>

                  </FormGroup>
                </div>
              </MuiGrid>
              <MuiGrid item xs={5}>
                <div className="editModalContainer">

                  <FormGroup>
                    <div>
                      <label htmlFor="aprroval" className="span_dark"><span className="span_dark">
                        Requires Approval
                      </span></label>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isApprovalRequired}
                            onChange={(e) => setIsApprovalRequired(e.target.checked)}
                            size="small"
                            id="aprroval"
                            disabled={isEditDisable}
                          />
                        }
                      />
                    </div>

                  </FormGroup>
                </div>
              </MuiGrid>
            </MuiGrid>

          </MuiGrid>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose}>Close</Button>
          <Button
            type='submit'
            onClick={updateHandler}
            disabled={isEditDisable}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DriverPayCodesMasterTable;
