
import { BaseConfig } from "../baseConfig";
import axios from 'axios';

export const getAppFilterData = async (reload) => {
    let appFilterObject = {}

    try {
        if (reload) {
            // API object call.
            const url = BaseConfig.ApiEndPoint + "/appfilter/app";
            const appFilterData = await axios.get(url);

            // Put the object into storage
            localStorage.setItem('AppFilterObject', JSON.stringify(appFilterData.data.data));
            appFilterObject = appFilterData.data.data;
        }

        // Retrieve the object from storage
        const retrievedPlannerObject = localStorage.getItem('AppFilterObject');
        appFilterObject = JSON.parse(retrievedPlannerObject);


    } catch (err) {
        return Promise.reject("There is a problem on retrieving app filter data. Please try again!");
    }

    return Promise.resolve(appFilterObject);
}

export const getAppFilter = async () => {
    let appFilterObject = {}

    try {
        // Retrieve the object from storage
        const retrievedPlannerObject = localStorage.getItem('AppFilterObject');
        appFilterObject = JSON.parse(retrievedPlannerObject);


    } catch (err) {
        return appFilterObject;
    }

    return appFilterObject;
}

export const getUserFilterData = async (userId, reload) => {
    let userFilterObject = {}

    try {
        if (reload) {
            // API object call.                
            const url = BaseConfig.ApiEndPoint + "/appfilter/" + userId;
            const appFilterData = await axios.get(url);

            // Put the object into storage
            localStorage.setItem('UserFilterObject', JSON.stringify(appFilterData.data.data));
            userFilterObject = appFilterData.data.data;
        }

        // Retrieve the object from storage
        const retrievedPlannerObject = localStorage.getItem('UserFilterObject');
        userFilterObject = JSON.parse(retrievedPlannerObject);

    } catch (err) {
        return Promise.reject("There is a problem on retrieving app filter data. Please try again!");
    }

    return Promise.resolve(userFilterObject);
}

export const getUserFilter = async () => {
    let userFilterObject = {}

    try {

        // Retrieve the object from storage
        const retrievedPlannerObject = localStorage.getItem('UserFilterObject');
        userFilterObject = JSON.parse(retrievedPlannerObject);

    } catch (err) {
        return userFilterObject;
    }

    return userFilterObject;
}