import { Autocomplete, TextField } from '@mui/material';
import { useState, useContext, useEffect } from 'react';
import { Modal, Button } from "react-bootstrap";
import {
  addTrailer,
  changePlanTimebystartshift,
  getNearByLocation,
  scheduleSingleOrder,
  scheduleStackedOrders,
  editStops,
  addOrderComments
} from "../services/planningBoardService";
import { DropContext } from "../pages/planner/planner";
import CircularProgress from '@mui/material/CircularProgress';
import IntraCompany from './tankwashdraggbleMainpage/IntraCompany';
import OutsideCarrier from './tankwashdraggbleMainpage/OutsideCarrier';
import VendorPayable from './tankwashdraggbleMainpage/VendorPayable';
import { getAllActiveTrailer, getAllActiveTractor, getWashCode, createTrailerWash, getWashLocationForVendorPayable, getAlllocationsbyterminaliswash } from "../services/tankWashService";
import { ContextData } from "./appsession";
import {
  NotificationManager,
} from "react-notifications";
import { BaseConfig } from "../baseConfig";
import { getTerminalbylatlong } from "../services/planningBoardService";
import { getAllDataByGenericAPI } from "../services/driverPayServer";
import TrimbleMaps from "@trimblemaps/trimblemaps-js";
import Skeleton from '@mui/material/Skeleton';
import Stack from "@mui/material/Stack";
import { toast } from 'react-toastify';

const WarningModal = (props) => {
  // Contexts
  const context = useContext(DropContext);
  const [userData, setUserData] = useContext(ContextData);
  const miles = [10, 15, 20];
  const userName = userData?.email?.split('@')[0];
  const companyName = userData?.email?.split('@')[1]?.split(".")[0].slice(0, 3).toUpperCase();
  const loggedUserData = { userName, companyName };
  const tankwashDropDownOptions = [
    { id: "1", name: "Intra Company", code: "I" },
    { id: "2", name: "Outside Carrier", code: "O" },
    { id: "3", name: "Vendor Payable", code: "P" }
  ];

  // Props
  const { setWarningModalOpen, warningMasterData, payload, driver, ApiName, parentCallback, planId, setSuccessResponseMssg, setFailureResponseMssg, errormssg, pickupAndDelivery } = props;

  // States
  const [warningDropdownopen, setWarningDropdownopen] = useState(true);
  const [warningMasterOptions, setWarningMasterOption] = useState(warningMasterData);
  const [warningMessage, setWarningMessage] = useState(errormssg)
  const [showReasonCodeModal, setShowReasonCodeModal] = useState(false);
  const [WarningModalcnfbtn, setWarningModalCnfBtn] = useState(false);
  const [tankwashModalShow, setTankwashModalShow] = useState(false);
  const [showTrailerMapModal, setShowTrailerMapModal] = useState(false);
  const [isLocationLoading, setIsLocationLoading] = useState(false);
  const [tankwashLocation, setTankwashLocation] = useState([]);
  const [allActiveTrailersData, setAllActiveTrailersData] = useState([]);
  const [allActiveTractorsData, setAllActiveTractorsData] = useState([]);
  const [allWashCodes, setAllWashCodes] = useState([]);
  const [washLocationForVendorPayable, setWashLocationForVendorPayable] = useState([]);
  const [allTankwashReasonCodes, setAllTankwashReasonCodes] = useState([]);
  const [selectedTankwashReasonCode, setSelectedTankwashReasonCode] = useState({});
  const [comments, setComments] = useState("");
  const [trailerId, setTrailerId] = useState({});
  const [selectedWashLocation, setSelectedWashLocation] = useState({});
  const [dropDownSelection, setDropDownSelection] = useState({ id: "1", name: "Intra Company" });
  const [addTrailerPayload, setAddTrailerPayload] = useState({});
  const [singleOrderPayload, setSingleOrderPayload] = useState({});
  const [stackOrderPayload, setStackOrderPayload] = useState({});
  const [changeShiftPayload, setChangeShiftPayload] = useState({});
  const [searchLocationData, setSearchLocationData] = useState("");
  const [selectedLocationLatitude, setSelectedLocationLatitude] = useState("");
  const [selectedLocationLongitude, setSelectedLocationLongitude] = useState("");
  const [locationsDataFromMap, setLocationsDataFromMap] = useState([]);
  const [selectedDistance, setSelectedDistance] = useState(5);
  const [loadedMovement, setLoadedMovement] = useState({});
  const [emptyMovement, setEmptyMovement] = useState({});
  const [tankWahEditPermission,setTankWashEditPermission] = useState(null);
  const [movewithoutwashcnfbtn,setMoveWithoutWashCnfBtn] = useState(false);
  const [payloadWarningModal,setPayloadWarningModal] = useState(payload);

useEffect(()=>{
     if(userData){
      userData?.roles?.rolespermission?.forEach((item)=>{
        if(item?.permissiondetail.code === "TankwashRuleOverride"){
          setTankWashEditPermission(item?.can_edit)
        }
      })
     }
},[])

  const callTankwashApis = async () => {
    try {
      const apiCalls = await Promise.all([getAlllocationsbyterminaliswash(), getWashCode(), getAllActiveTrailer(), getAllActiveTractor(), getWashLocationForVendorPayable()]);

      // Tankwash location modification for Intra Company and Outside carrier 
      const tankwashLoc = apiCalls[0].filter((loc) => (loc.is_terminal === true & loc.is_wash === true));
      setTankwashLocation(tankwashLoc);

      // Getting wash codes
      setAllWashCodes(apiCalls[1]);

      // Getting Active Trailer
      setAllActiveTrailersData(apiCalls[2]);

      // Getting Active Tractor
      setAllActiveTractorsData(apiCalls[3]);

      // Get Wash Locartion for outside carrier
      setWashLocationForVendorPayable(apiCalls[4]);
    } catch (err) {
      NotificationManager.error("Something went wrong.", "Error");
    }
  };

  const resetStatesAfterAction = () => {
    setWarningModalCnfBtn(false);
    setWarningModalOpen(null);
    setWarningDropdownopen(false);
    setShowTrailerMapModal(false);
    setMoveWithoutWashCnfBtn(false);
    context.refreshDataFromAPI(context.payloadForRefreshData);
  };

  // Move without wash
  const moveWithoutWashSubmitBtnHandler = async () => {
    if (Object.keys(selectedTankwashReasonCode).length === 0) {
      NotificationManager.error("Please select a reason code.", "Error");
      return;
    };

    if (comments.length === 0) {
      NotificationManager.error("Please add a comment.", "Error");
      return;
    };
    setMoveWithoutWashCnfBtn(true)
    if(warningMasterOptions.length>0 && warningMasterOptions[0]?.data?.show_cancel_button==false){
      let payload_comment={
        order_id : warningMasterOptions[0]?.data?.order_id,
        comment_type : 'BD',
        messsage : comments,
        created_by : userData?.email?.split('@')[0]
       }
      let response=await addOrderComments(payload_comment);
      resetStatesAfterAction();
      return;
    }

    if (ApiName && ApiName === 'scheduleSingleOrder') {
      try {
        const payload = {
          ...singleOrderPayload,
          reason_code: selectedTankwashReasonCode?.value,
          comments: comments
        };

        let res = await scheduleSingleOrder(driver.driverId, payload);

        if(res?.data?.status=='warning'){
          let str=res?.data?.errors?.map((el)=>el?.message)?.join(",") ?? "";
          setWarningMessage(str)
          setWarningMasterOption(res?.data?.options)
          setWarningModalCnfBtn(false);
          setShowReasonCodeModal(false);
          setMoveWithoutWashCnfBtn(false);
          return
        }else if (res?.data?.status === "success") {
          setSuccessResponseMssg("Order scheduled successfully.");
        } else if (res?.data?.status === 'failure') {
          setFailureResponseMssg(res?.data?.errors);
        }
        resetStatesAfterAction();
      } catch (err) {
        if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
          setFailureResponseMssg(err?.response?.data?.errors)
        }
        resetStatesAfterAction();
      }
    } else if (ApiName && ApiName === 'scheduleStackedOrders') {
      try {
        const payload = {
          ...stackOrderPayload,
          reason_code: selectedTankwashReasonCode?.value,
          comments: comments
        };

        let res = await scheduleStackedOrders(driver.driverId, planId, payload);

        if(res?.data?.status=='warning'){
          let str=res?.data?.errors?.map((el)=>el?.message)?.join(",") ?? "";
          setWarningMessage(str)
          setWarningMasterOption(res?.data?.options)
          setWarningModalCnfBtn(false);
          setShowReasonCodeModal(false);
          setMoveWithoutWashCnfBtn(false);
          return
        }else if (res?.data?.status === "success") {
          setSuccessResponseMssg("Order scheduled successfully.");
        } else if (res?.data?.status === 'failure') {
          setFailureResponseMssg(res?.data?.errors);
        }
        resetStatesAfterAction();
      } catch (err) {
        if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
          setFailureResponseMssg(err?.response?.data?.errors)
        }
        resetStatesAfterAction();
      }

    } else if (ApiName && ApiName === 'changePlanTimebystartshift') {
      try {
        const payload = {
          option: changeShiftPayload.option,
          reason_code: selectedTankwashReasonCode?.value,
          comments: comments
        };

        let res = await changePlanTimebystartshift(changeShiftPayload.payload, planId, payload);
        if(res?.data?.status=='warning'){
          let str=res?.data?.errors?.map((el)=>el?.message)?.join(",") ?? "";
          setWarningMessage(str)
          setWarningMasterOption(res?.data?.options)
          setWarningModalCnfBtn(false);
          setShowReasonCodeModal(false);
          setMoveWithoutWashCnfBtn(false);
          return
        }else if (res?.data?.status === "success") {
          setSuccessResponseMssg("Changed Plan Time successfully.");
        } else if (res?.data?.status === 'failure') {
          setFailureResponseMssg(res?.data?.errors);
        }
        resetStatesAfterAction();
      }
      catch (err) {
        if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
          setFailureResponseMssg(err?.response?.data?.errors)
        }
        resetStatesAfterAction();
      }

    } else if (ApiName && ApiName === 'addTrailer') {
      try {
        const payload = {
          ...addTrailerPayload,
          reason_code: selectedTankwashReasonCode?.value,
          comments: comments
        };

        let res = await addTrailer(driver.driverId, planId, payload);

        if(res?.data?.status=='warning'){
          let str=res?.data?.errors?.map((el)=>el?.message)?.join(",") ?? "";
          setWarningMessage(str)
          setWarningMasterOption(res?.data?.options)
          setWarningModalCnfBtn(false);
          setShowReasonCodeModal(false);
          setMoveWithoutWashCnfBtn(false);
          return;
        }else if (res?.data?.status === "success") {
          setSuccessResponseMssg("Trailer assigned successfully.");
        } else if (res?.data?.status === 'failure') {
          setFailureResponseMssg(res?.data?.errors);
        }
        resetStatesAfterAction();
      } catch (err) {
        if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
          setFailureResponseMssg(err?.response?.data?.errors);
        }
        resetStatesAfterAction();
      }

    }
  }

  const optionsCheck = async (newValue) => {
    if (newValue === "Enter a wash") {
      // For showing tank wash work order modal
      setTankwashModalShow(true);

      return false;
    } else if (newValue === "Move forward without a wash") {
      try {
        const payload = { domain_name: "TANKWASH_OVERRIDE" };
        const data = await getAllDataByGenericAPI('lookups', payload);

        setAllTankwashReasonCodes(data);

        // For showing tankwash reason code modal
        setShowReasonCodeModal(true);
      } catch (err) {
        NotificationManager.error("There is a problem in fetching tankwash reason codes.", "Error");
      }
    } else if (newValue === "Change trailer destination") {
      // For showing triler change desination modal
      setShowTrailerMapModal(true);
    }
  };

  const handleSubmit = async (newValue) => {
    setWarningModalCnfBtn(true);
    if (newValue === "Enter a wash") {
      await callTankwashApis();
    }

    let optionName = newValue === "Enter a wash" ? "Enter a wash" : newValue === "Move forward without a wash" ? "Move forward without a wash" : "Change trailer destination";

    let data1 = warningMasterData.find((el) => el.name === optionName);
    let data = data1?.data;
    setTrailerId({
      addTrailerApi: true,
      data
    });


    if (parentCallback) {
      parentCallback(payloadWarningModal, newValue);
      setWarningModalCnfBtn(false);
      setWarningModalOpen(null);
      setWarningDropdownopen(false);

    } else if (ApiName && ApiName === 'scheduleSingleOrder') {
      setSingleOrderPayload({ ...payload, ['option']: singleOrderPayload?.option?.length>0 ? [...singleOrderPayload?.option,newValue] : [newValue] });

      if (newValue === "Enter a wash" || newValue === "Move forward without a wash" || newValue === "Change trailer destination") {
        optionsCheck(newValue);
      } else {
        let payload1 = { ...payload, ['option']:  singleOrderPayload?.option?.length>0 ? [...singleOrderPayload?.option,newValue] : [newValue]  };
        try {
          let res = await scheduleSingleOrder(driver.driverId, payload1);
          
          if(res?.data?.status=='warning'){
            let str=res?.data?.errors?.map((el)=>el?.message)?.join(",") ?? "";
            setWarningMessage(str)
            setWarningMasterOption(res?.data?.options)
            setWarningModalCnfBtn(false);
            setMoveWithoutWashCnfBtn(false);
            return
          }else if (res?.data?.status === "success") {
            setSuccessResponseMssg("Order scheduled successfully.");
          } else if (res?.data?.status === 'failure') {
            setFailureResponseMssg( res?.data?.errors);
          }
          resetStatesAfterAction();
        }
        catch (err) {
          if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
            setFailureResponseMssg(err?.response?.data?.errors)
          }
          resetStatesAfterAction();
        }
      }
    } else if (ApiName && ApiName === 'scheduleStackedOrders') {
      setStackOrderPayload({ ...payload, ['option']:  stackOrderPayload?.option?.length>0 ? [...stackOrderPayload?.option,newValue] : [newValue]  });

      if (newValue === "Enter a wash" || newValue === "Move forward without a wash" || newValue === "Change trailer destination") {
        optionsCheck(newValue);
      } else {
        let payload1 = { ...payload, ['option']:  stackOrderPayload?.option?.length>0 ? [...stackOrderPayload?.option,newValue] : [newValue]  };
        try {
          let res = await scheduleStackedOrders(driver.driverId, planId, payload1);

           
          if(res?.data?.status=='warning'){
            let str=res?.data?.errors?.map((el)=>el?.message)?.join(",") ?? "";
            setWarningMessage(str)
            setWarningMasterOption(res?.data?.options)
            setWarningModalCnfBtn(false);
            setMoveWithoutWashCnfBtn(false);
            return
          }else if (res?.data?.status === "success") {
            setSuccessResponseMssg("Order scheduled successfully.");
          } else if (res?.data?.status === 'failure') {
            setFailureResponseMssg(res?.data?.errors);
          }
          resetStatesAfterAction();
        }
        catch (err) {
          if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
            setFailureResponseMssg(err?.response?.data?.errors)
          }
          resetStatesAfterAction();
        }

      }
    } else if (ApiName && ApiName === 'changePlanTimebystartshift') {
      setChangeShiftPayload({
        payload,
        option:  changeShiftPayload?.option?.length>0 ? [...changeShiftPayload?.option,newValue] : [newValue] 
      });

      if (newValue === "Enter a wash" || newValue === "Move forward without a wash" || newValue === "Change trailer destination") {
        optionsCheck(newValue);
      } else {
        try {
          let res = await changePlanTimebystartshift(payload, planId, { "option":  changeShiftPayload?.option?.length>0 ? [...changeShiftPayload?.option,newValue] : [newValue]  });
           
          if(res?.data?.status=='warning'){
            let str=res?.data?.errors?.map((el)=>el?.message)?.join(",") ?? "";
            setWarningMessage(str)
            setWarningMasterOption(res?.data?.options)
            setWarningModalCnfBtn(false);
            setMoveWithoutWashCnfBtn(false);
            return
          }else if (res?.data?.status === "success") {
            setSuccessResponseMssg("Changed Plan Time successfully.");
          } else if (res?.data?.status === 'failure') {
            setFailureResponseMssg(res?.data?.errors);
          }
          resetStatesAfterAction();
        }
        catch (err) {
          if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
            setFailureResponseMssg(err?.response?.data?.errors)
          }
          resetStatesAfterAction();
        }

      }
    } else if (ApiName && ApiName === 'addTrailer') {
      setAddTrailerPayload({
        ...payload,
        option:  addTrailerPayload?.option?.length>0 ? [...addTrailerPayload?.option,newValue] : [newValue] 
      });

      if (newValue === "Enter a wash" || newValue === "Move forward without a wash" || newValue === "Change trailer destination") {
        optionsCheck(newValue);
      } else {
        try {
          let payload1 = { ...payload, ['option']:  addTrailerPayload?.option?.length>0 ? [...addTrailerPayload?.option,newValue] : [newValue]  };

          let res = await addTrailer(driver.driverId, planId, payload1);

           
          if(res?.data?.status=='warning'){
            let str=res?.data?.errors?.map((el)=>el?.message)?.join(",") ?? "";
            setWarningMessage(str)
            setWarningMasterOption(res?.data?.options)
            setWarningModalCnfBtn(false);
            setMoveWithoutWashCnfBtn(false);
            return
          }else if (res?.data?.status === "success") {
            setSuccessResponseMssg("Trailer assigned successfully.");
          } else if (res?.data?.status === 'failure') {
            let str = res?.data?.errors?.map((el) => `${el?.message}`);
            setFailureResponseMssg(res?.data?.errors);
          }
          resetStatesAfterAction();
        } catch (err) {
          if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
            setFailureResponseMssg(err?.response?.data?.errors)
          }
          resetStatesAfterAction();
        }
      }
    }
  };

  const handleClose = () => {
    if(warningMasterOptions.length>0 && warningMasterOptions[0]?.data?.show_cancel_button==false){
      toast.error("Please Select An Option.")
      return
    }
    context.refreshDataFromAPI(context.payloadForRefreshData);
    setWarningModalOpen(null);
    setWarningDropdownopen(false);
  };

  const tankWashTypeHandler = (e, type) => {
    setDropDownSelection(type);
  };

  const tankwashModalCloseHandler = () => {
    setTankwashModalShow(false);
    handleClose();
  };

  // First create tankwash order then assign trailer
  const createTankWash = async (payload) => {
    try {
      const response = await createTrailerWash(payload);
      
      if (response.status === 201) {
        NotificationManager.success("Tankwash order created successfully", "Success", 2500);
        if (warningMasterOptions.length > 0 && warningMasterOptions[0]?.data?.show_cancel_button == false) {
          try {
            let res = await editStops({ location_id: payload.wash_location_id }, warningMasterOptions[0]?.data?.stop_id);
            if (res?.data?.status === 'failure') {
              let str = res?.data?.errors?.map((el) => `${el?.message}`);
              setFailureResponseMssg(err?.response?.data?.errors);
            }
          } catch (err) {
            if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
              let str = err?.response?.data?.errors?.map((el) => `${el?.message}`);
              setFailureResponseMssg(err?.response?.data?.errors)
            }
          }
          resetStatesAfterAction();
          return;
        }
        setTankwashModalShow(false);

        if (ApiName && ApiName === 'scheduleSingleOrder') {
          try {
            const payload = {
              ...singleOrderPayload,
              tankwash_movement_id: trailerId.data.movement_id,
              tankwash_destination_Location: selectedWashLocation?.code

            };

            let res = await scheduleSingleOrder(driver.driverId, payload);

            if(res?.data?.status=='warning'){
              let str=res?.data?.errors?.map((el)=>el?.message)?.join(",") ?? "";
              setWarningMessage(str)
              setWarningMasterOption(res?.data?.options)
              setWarningModalCnfBtn(false);
              setTankwashModalShow(false);
              setMoveWithoutWashCnfBtn(false);
              return
            }else if (res?.data?.status === "success") {
              setSuccessResponseMssg("Order scheduled successfully.");
            } else if (res?.data?.status === 'failure') {
              let str = res?.data?.errors?.map((el) => `${el?.message}`);
              setFailureResponseMssg(err?.response?.data?.errors);
            }
            resetStatesAfterAction();
          } catch (err) {
            if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
              let str = err?.response?.data?.errors?.map((el) => `${el?.message}`);
              setFailureResponseMssg(err?.response?.data?.errors)
            }
            resetStatesAfterAction();
          }

        } else if (ApiName && ApiName === 'scheduleStackedOrders') {
          try {
            const payload = {
              ...stackOrderPayload,
              tankwash_movement_id: trailerId.data.movement_id,
              tankwash_destination_Location: selectedWashLocation?.code
            };

            let res = await scheduleStackedOrders(driver.driverId, planId, payload);

            if(res?.data?.status=='warning'){
              let str=res?.data?.errors?.map((el)=>el?.message)?.join(",") ?? "";
              setWarningMessage(str)
              setWarningMasterOption(res?.data?.options)
              setWarningModalCnfBtn(false);
              setTankwashModalShow(false);
              setMoveWithoutWashCnfBtn(false);
              return
            }else if (res?.data?.status === "success") {
              setSuccessResponseMssg("Order scheduled successfully.");
            } else if (res?.data?.status === 'failure') {
              setFailureResponseMssg(res?.data?.errors);
            }
            resetStatesAfterAction();
          } catch (err) {
            if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
              setFailureResponseMssg(err?.response?.data?.errors)
            }
            resetStatesAfterAction();
          }

        } else if (ApiName && ApiName === 'changePlanTimebystartshift') {
          try {
            let res = await changePlanTimebystartshift(changeShiftPayload.payload, planId, { "option": changeShiftPayload.option, tankwash_destination_Location: selectedWashLocation?.code, tankwash_movement_id: trailerId.data.movement_id });

            if(res?.data?.status=='warning'){
              let str=res?.data?.errors?.map((el)=>el?.message)?.join(",") ?? "";
              setWarningMessage(str)
              setWarningMasterOption(res?.data?.options)
              setWarningModalCnfBtn(false);
              setTankwashModalShow(false);
              setMoveWithoutWashCnfBtn(false);
              return
            }else if (res?.data?.status === "success") {
              setSuccessResponseMssg("Changed Plan Time successfully.");
            } else if (res?.data?.status === 'failure') {
              setFailureResponseMssg( res?.data?.errors);
            }
            resetStatesAfterAction();
          }
          catch (err) {
            if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
              setFailureResponseMssg(err?.response?.data?.errors)
            }
            resetStatesAfterAction();
          }

        } else if (ApiName && ApiName === 'addTrailer') {
          try {
            const payload = {
              ...addTrailerPayload,
              tankwash_movement_id: trailerId.data.movement_id,
              tankwash_destination_Location: selectedWashLocation?.code
            };

            let res = await addTrailer(driver.driverId, planId, payload);

            if(res?.data?.status=='warning'){
              let str=res?.data?.errors?.map((el)=>el?.message)?.join(",") ?? "";
              setWarningMessage(str)
              setWarningMasterOption(res?.data?.options)
              setWarningModalCnfBtn(false);
              setTankwashModalShow(false);
              setMoveWithoutWashCnfBtn(false);
              return
            }else if (res?.data?.status === "success") {
              setSuccessResponseMssg("Trailer assigned successfully.");
            } else if (res?.data?.status === 'failure') {
              setFailureResponseMssg(res?.data?.errors);
            }
            resetStatesAfterAction();
          } catch (err) {
            if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
              setFailureResponseMssg(err?.response?.data?.errors)
            }
            resetStatesAfterAction();
          }

        }
      }
    } catch (err) {
      NotificationManager.error("Tankwash work order didn't create successfully.", "Error");
    }
  };

  let errormessageDisplay=(arr)=>{
    let divelemnt
    if(arr?.length>0 && arr?.map((el)=>el.message)?.join(",")?.split(" ")?.length>15){
       divelemnt= errormessageTable(arr)
      toast.error(divelemnt, { autoClose: false, position: "top-center"});
    }else if(arr?.length>0){
      if(arr?.filter((el)=>el?.data && el?.data !==undefined)?.length>0){
        divelemnt= errormessageTable(arr)
        toast.error(divelemnt, { autoClose: false, position: "top-center"});
      }else{
        toast.error(`${arr?.map((el)=>el.message)?.join(",")}`, { autoClose: false } );
      }
    }else{
      toast.error('Something Went Wrong, Please Try Again Later', { autoClose: false } );
    }
  }

  useEffect(() => {
    if (showTrailerMapModal) {
      (async () => {
        TrimbleMaps.APIKey = BaseConfig.Mapkey;

        let loadedMov = pickupAndDelivery.find((mov) => mov.movementData.movement_type === "L");
        let emptyMov = pickupAndDelivery.find((mov) => mov.movementData.movement_type === "E");

        setLoadedMovement(loadedMov);
        setEmptyMovement(emptyMov);

        const pickupLocation = loadedMov.movementData.pickup;
        const deliveryLocation = emptyMov.movementData.delivery;

        const map = new TrimbleMaps.Map({
          container: "map",
          style: TrimbleMaps.Common.Style.TRANSPORTATION,
          center: new TrimbleMaps.LngLat(
            pickupLocation?.longitude,
            pickupLocation?.latitude
          ),
          zoom: 12,
        });

        const myRoute = new TrimbleMaps.Route({
          container: "map",
          routeId: "myRoute",
          style: TrimbleMaps.Common.Style.TRANSPORTATION,
          stops: [
            new TrimbleMaps.LngLat(
              pickupLocation?.longitude,
              pickupLocation?.latitude
            ),
            new TrimbleMaps.LngLat(
              deliveryLocation?.longitude,
              deliveryLocation?.latitude
            ),
          ],
        });

        const marker = new TrimbleMaps.Marker({
          draggable: true,
        }).setLngLat([
          deliveryLocation?.longitude,
          deliveryLocation?.latitude
        ]).addTo(map);

        map.addControl(new TrimbleMaps.NavigationControl());
        const scale = new TrimbleMaps.ScaleControl({
          maxWidth: 80,
          unit: "imperial",
        });

        map.on("load", function () {
          myRoute.addTo(map);
        });

        map.addControl(scale);

        marker.on("dragend", async function (e) {
          const lngLat = e.target.getLngLat();
          setSelectedLocationLongitude(lngLat.lng.toFixed(4));
          setSelectedLocationLatitude(lngLat.lat.toFixed(4));

          let payload = {
            longitude: lngLat.lng.toFixed(4),
            latitude: lngLat.lat.toFixed(4),
            searchtext: searchLocationData,
            distance: selectedDistance,
          };
          let payLoadTrimblemap={
            longitude:lngLat.lng.toFixed(4),
            latitude:lngLat.lat.toFixed(4),
            radius:selectedDistance
          }
  
          try {
            setIsLocationLoading(true);
            const allLocationsBasedOnLatLong =await Promise.all([getTerminalbylatlong(payload),getNearByLocation(payLoadTrimblemap)]);
              let locationfromlocationTable=allLocationsBasedOnLatLong[0];
              let locationfromtrimblemap=[];
              let nearBylocations;
              if(allLocationsBasedOnLatLong[1]?.data?.status=='success' && allLocationsBasedOnLatLong[1]?.data?.data?.length>0) {
                 allLocationsBasedOnLatLong[1]?.data?.data?.forEach((item)=>{
                  let obj={};
                 obj.distance=Number(item?.DistanceFromCenter?.Value)?.toFixed(2);
                 obj.address1=item?.POILocation?.Address?.StreetAddress;
                 obj.city=item?.POILocation?.Address?.City;
                 obj.code=null;
                 obj.latitude=item?.POILocation?.Coords?.Lat;
                 obj.longitude=item?.POILocation?.Coords?.Lon;
                 obj.location_name=item?.POILocation?.PlaceName;
                 obj.state=item?.POILocation?.Address?.State;
                 obj.zip=item?.POILocation?.Address?.Zip;
                 obj.label="nearbysearch"
                 if( obj.location_name && obj.address1 && obj.city){
                  if(allLocationsBasedOnLatLong[0].length>0 && Object.keys(allLocationsBasedOnLatLong[0]?.find(({location_name})=>location_name==obj.location_name) ?? {})?.length==0){
                 locationfromtrimblemap.push(obj);
                 }else if(allLocationsBasedOnLatLong[0].length==0){
                  locationfromtrimblemap.push(obj);
                 }
                 }
                })
               }
               if(locationfromlocationTable.length>0 && locationfromtrimblemap.length>0){
                 nearBylocations=[...locationfromlocationTable,...locationfromtrimblemap]?.sort((a,b)=>Number(a.distance)-Number(b.distance))
               }else if(locationfromlocationTable.length>0){
                nearBylocations=[...locationfromlocationTable];
               }else if(locationfromtrimblemap.length>0){
                nearBylocations=[...locationfromtrimblemap]
               }else{
                nearBylocations=[]
               }     
              setIsLocationLoading(false);      
              setLocationsDataFromMap(nearBylocations);         
          } catch (error) {
            NotificationManager.error("There is a problem in retrieving the location data.", { autoClose: false });
          }
        });
      })();

    }

  }, [showTrailerMapModal]);

  const filterLocationByDist = async (e) => {
    const value = Number(e.target.value);
    setSelectedDistance(value);
    try {
      if (selectedLocationLongitude && selectedLocationLatitude) {
        setIsLocationLoading(true);
        let payload = {
          longitude: selectedLocationLongitude,
          latitude: selectedLocationLatitude,
          searchtext: searchLocationData,
          distance: value
        };

        const latlanglocation = await getTerminalbylatlong(payload);

        setLocationsDataFromMap(latlanglocation);
        setIsLocationLoading(false);
      }
    } catch (error) {
      NotificationManager.error("There is a problem in retrieving the location data.", "Error")
    }
  };

  // For closing trailer map modal
  const closeMapModal = () => {
    setShowTrailerMapModal(false);
    handleClose();
  };

  const closeReasonModal = () => {
    setShowReasonCodeModal(false);
    handleClose();
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      searchbylatlong(e);
    }
  };

  const handleSearchInput = (e) => {
    setSearchLocationData(e.target.value);
  };

  const searchbylatlong = async (e, searchText) => {
    e.preventDefault();
    try {
      if (searchText && selectedLocationLongitude && selectedLocationLatitude) {
        setIsLocationLoading(true);
        let payload = {
          longitude: selectedLocationLongitude,
          latitude: selectedLocationLatitude,
          searchtext: searchText,
          distance: selectedDistance
        };
        const latlanglocation = await getTerminalbylatlong(payload);

        setLocationsDataFromMap(latlanglocation);
        setIsLocationLoading(false);
      } else if (selectedLocationLongitude && selectedLocationLatitude) {
        setIsLocationLoading(true);
        let payload = {
          longitude: selectedLocationLongitude,
          latitude: selectedLocationLatitude,
          searchtext: searchLocationData,
          distance: selectedDistance
        };
        const latlanglocation = await getTerminalbylatlong(payload);
        setLocationsDataFromMap(latlanglocation);
        setIsLocationLoading(false);
      }

    } catch (error) {
      NotificationManager.error("There is a problem in retrieving the location data.", "Error");
    }
  };

  // Change trailer dropoff location
  const changeTrailerDropoffLocation = async (location, index) => {
    let location_data=location
    if(location?.label=='nearbysearch'){
      let response_location=await addNewLocation(location);
      if(response_location?.data?.status=='failure' && response_location?.data?.errors && response_location?.data?.errors?.length>0 ){
        errormessageDisplay(response_location?.data?.errors);
        setChangedestconfirmbtn(false)
        return
       }else if(response_location?.data?.status=='failure'){
        errormessageDisplay([{message:"Something Went Wrong While Adding New Location"}]);
        setChangedestconfirmbtn(false);
       return
       }else{
        location_data=response_location?.data?.data;
       }
    }
    if (warningMasterOptions.length>0 && warningMasterOptions[0]?.data?.show_cancel_button==false) {
      try {
        let res = await editStops({ location_id:location_data.code}, warningMasterOptions[0]?.data?.stop_id);
         if (res?.data?.status === 'failure') {
          let str = res?.data?.errors?.map((el) => `${el?.message}`);
          setFailureResponseMssg(err?.response?.data?.errors);
        }
        resetStatesAfterAction();
      } catch (err) {
        if (err?.response?.data && err?.response?.data?.errors?.length > 0) {
          let str = err?.response?.data?.errors?.map((el) => `${el?.message}`);
          setFailureResponseMssg(err?.response?.data?.errors)
          resetStatesAfterAction();
        }
      }
      return;
    }
  };

  return (
    <div>
      <Modal
        show={warningDropdownopen}
        onHide={() => handleClose()}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton={(warningMasterOptions.length>0 && warningMasterOptions[0]?.data?.show_cancel_button==false) ? false : true}>
          <Modal.Title id='contained-modal-title-vcenter'>
            <p className='textcolorchange'>WARNING!</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {warningMessage ?? ""} Please click a button to proceed.
          <div className='warningModal_options_container'>
            {
              !WarningModalcnfbtn ? warningMasterOptions?.map((el) => {
                let modifiedEl = typeof el === "string" ? el : el.name;
                return <>
                  {
                    modifiedEl !== "Move forward without a wash" ?
                      <Button
                        onClick={() => handleSubmit(
                          modifiedEl)}
                      >
                        {modifiedEl}
                      </Button>
                      : tankWahEditPermission ?
                        <Button
                          onClick={() => handleSubmit(
                            modifiedEl)}
                        >
                          {modifiedEl}
                        </Button>
                        : <></>
                  }
                </>
              }) :
                <div className="spinner_modal_footer" style={{ margin: "auto" }}>
                  <CircularProgress />
                </div>
            }
          </div>
        </Modal.Body>
        <Modal.Footer>
          {
            (warningMasterOptions.length>0 && warningMasterOptions[0]?.data?.show_cancel_button==false) ? "" :
          <Button onClick={() => handleClose()}>
            Cancel
          </Button>
          }
        </Modal.Footer>
      </Modal>

      {/* Tankwash Work Order Entry Modal*/}
      <Modal
        show={tankwashModalShow}
        onHide={() => tankwashModalCloseHandler()}
        dialogClassName="tankwash_order_modal_section modal-half"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Work Order
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="dialog-item">
            <label htmlFor="typeHeadCommodityGroup">
              Type
            </label>
            <div className="meterial_autocomplete">
              <Autocomplete
                id="combo-box-demo"
                options={tankwashDropDownOptions}
                getOptionLabel={(option) => `${option?.name}`}
                defaultValue={tankwashDropDownOptions[0]}
                onChange={tankWashTypeHandler}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Choose a Trailer..."
                    variant="outlined"
                  />
                )}
              />
            </div>
          </div>

          {/* Based on dropdown selection */}
          {dropDownSelection?.name === "Intra Company" ? (
            <IntraCompany
              allWashLocation={tankwashLocation}
              allActiveTractors={allActiveTractorsData}
              allActiveTrailers={allActiveTrailersData}
              allWashCodes={allWashCodes}
              userData={userData}
              loggedUserData={loggedUserData}
              createTankWash={createTankWash}
              dropDownSelection={dropDownSelection}
              washDetails={{ location_code: "", trailer_id: "" }}
              setSelectedWashLocation={setSelectedWashLocation}
              trailerId={trailerId}

            />
          ) : dropDownSelection?.name === "Outside Carrier" ? (
            <OutsideCarrier
              allWashLocation={tankwashLocation}
              allActiveTractors={allActiveTractorsData}
              allActiveTrailers={allActiveTrailersData}
              allWashCodes={allWashCodes}
              userData={userData}
              loggedUserData={loggedUserData}
              createTankWash={createTankWash}
              dropDownSelection={dropDownSelection}
              setSelectedWashLocation={setSelectedWashLocation}
              trailerId={trailerId}
            />
          ) : dropDownSelection?.name === "Vendor Payable" ? (
            <VendorPayable
              allWashLocation={washLocationForVendorPayable}
              allActiveTractors={allActiveTractorsData}
              allActiveTrailers={allActiveTrailersData}
              allWashCodes={allWashCodes}
              userData={userData}
              loggedUserData={loggedUserData}
              createTankWash={createTankWash}
              dropDownSelection={dropDownSelection}
              setSelectedWashLocation={setSelectedWashLocation}
              trailerId={trailerId}
            />
          ) : (<></>)
          }
        </Modal.Body>
      </Modal>

      {/* Reason Code Modal */}
      <Modal
        show={showReasonCodeModal}
        onHide={() => closeReasonModal()}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" />
        </Modal.Header>
        <Modal.Body>
          <div className="meterial_autocomplete">
            <label for="txtEffectiveDate" className="override-date-container__secondary-text">Reason Code *</label>
            <Autocomplete
              id='combo-box-demo'
              options={allTankwashReasonCodes}
              getOptionLabel={(option) => option?.display_text}
              onChange={(e, newValue) => setSelectedTankwashReasonCode(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder='Choose a Reason Code'
                  variant='outlined'
                  required={true}
                />
              )}
            />
          </div>
          <div className="tankwash_reason_code">
            <label for="comments" className="override-date-container__secondary-text">Comments *</label>
            <textarea
              id="comments"
              name="comments"
              placeholder="Write comments..."
              required
              rows="10"
              cols="49"
              resi
              onChange={(e) => setComments(e.target.value)}
            />
          </div>

        </Modal.Body>
        <Modal.Footer>
          {!movewithoutwashcnfbtn ?
          <Button onClick={() => moveWithoutWashSubmitBtnHandler()}>
            Submit
          </Button> :
          <div className="spinner_modal_footer_md">
          <CircularProgress />
          </div>
         } 
          <Button onClick={() => closeReasonModal()}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Change Trailer Destination */}
      <Modal
        show={showTrailerMapModal}
        onHide={() => closeMapModal()}
        size='lg'
        dialogClassName="modal-width"
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            <p className='tank_mapview_header'>Select Trailer Dropoff Location</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='split_view_container'>
            <div className='split_view_left'>
              <div className='search_section'>
                <div className='search_field_tankwash'>
                  <form class='example'>
                    <input
                      type='text'
                      placeholder='Search..'
                      name='search2'
                      onChange={(e) => handleSearchInput(e)}
                      onKeyUp={handleKeyPress}
                    />
                    <button onClick={(e) => searchbylatlong(e, searchLocationData)}>
                      <i class='fa fa-search'></i>
                    </button>
                  </form>
                </div>
                <div className='split_popup_dropdown'>
                  <form>
                    <select
                      name='cars'
                      id='cars'
                      value={selectedDistance}
                      onChange={(e) => filterLocationByDist(e)}
                    >
                      <option value='5'>
                        5
                      </option>
                      {miles.map((mile, k) => (
                        <option key={k}>{mile}</option>
                      ))}
                    </select>
                  </form>
                </div>
              </div>
              <p className='nearby_text'>
                Nearby Locations:{locationsDataFromMap.length}
              </p>
              <div className='location_section_wrapper'>
                {isLocationLoading ? (
                  <Stack spacing={1}>
                    <div className='loader_skeleton'>
                      <Skeleton variant='rectangular' />
                    </div>
                  </Stack>
                ) : (
                  <></>
                )}
                {locationsDataFromMap.map((location, index) => (
                  <div className={`location_section ${location?.label === "nearbysearch" ? "location_identifier": ""}`}>
                    <div className='location_section_left'>
                    <div className="split_nearby_location_container">
                       <div>
                      <div className='location_name'>
                      {`${location?.code ? location?.code + '-' : ''}`}{location?.location_name}
                        <br />
                        {location.address1}
                        <br />
                        {location.city},{location.state},{location.zip}
                      </div>
                      <div className='location_name'>
                        Distance: {location.distance} miles
                      </div>
                      <div className='location_section_right'></div>
                      </div>
                      <button
                        type='button'
                        class='btn-blue_schedule split_button'
                        onClick={(i) =>
                          changeTrailerDropoffLocation(location, index)
                        }
                      >
                        {location?.label == 'nearbysearch' ? 'Save Address & Select' : 'Select'}
                      </button>
                    </div>
                  </div>
                  </div>
                ))}
              </div>
            </div>
            <div className='split_view_right_map'>
              <div id='map' className="split_view_right_map__map" style={{ height: "600px", width: "100%" }}>
              </div>
            </div>
            <div className="split_view_right_map__guide">
              <p>To change trailer destination on the movement, drag the red pin and drop where you would like to change the location along the route. Then pick a location from the list for changing trailer location.</p>
              <div>
                <div className="split_pickup_location"  style={{ marginTop: "50px" }}>
                  <div className="split_pickup_location_icon"></div>
                  <div>Pickup</div>
                </div>
                <div className='location_name ml_20 split_pickup_delivery_label'>
                  {loadedMovement?.movementData?.pickup?.lm_location_id}-{loadedMovement?.movementData?.pickup?.lm_location_name}
                  <br />
                  {loadedMovement?.movementData?.pickup?.address}
                  <br />
                  {loadedMovement?.movementData?.pickup?.city}, {loadedMovement?.movementData?.pickup?.state}, {loadedMovement?.movementData?.pickup?.zip}
                </div>
                <div className="split_pickup_location">
                  <div className="split_delivery_location_icon"></div>
                  <div>Delivery</div>
                </div>
                <div className='location_name ml_20 split_pickup_delivery_label'>
                  {emptyMovement?.movementData?.delivery?.lm_location_id}-{emptyMovement?.movementData?.delivery?.lm_location_name}
                  <br />
                  {emptyMovement?.movementData?.delivery?.address}
                  <br />
                  {emptyMovement?.movementData?.delivery?.city}, {emptyMovement?.movementData?.delivery?.state}, {emptyMovement?.movementData?.delivery?.zip}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={(e) => closeMapModal()}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div >
  )
}

export default WarningModal;