import { initializeApp } from 'firebase/app';
import { getMessaging, onMessage, getToken } from "firebase/messaging";
const appConfig = require("./Config.json");

// Initialize Firebase App
initializeApp(appConfig.firebaseConfig);

const messaging = getMessaging();

export const requestForToken = async () => {
  try {
    const firebaseClientToken = await getToken(messaging, { vapidKey: appConfig.fbVapidKey});
    return firebaseClientToken;
  } catch (err) {
    console.log('An error occurred while retrieving token.', err);
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("🚀 ~ onMessage ~ payload:", payload)
      resolve(payload);
    });
  });