import React, { useState, useEffect, useContext, useRef } from "react";
// import { Button, Modal } from "react-bootstrap";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { DateTime } from "luxon";
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
// import Driverpaydata from "./DriverpayForDriverData.json"
// import DriverpayOrderdata from "./DriverpayOrder.json"
import "./DriverpayForDriver.css"
import { getDriverSummaryData, getDriverpayDetails, driverPayPdf } from "../../services/driverService";
import ProgressBar from "react-bootstrap/ProgressBar";
import { IconButton } from "@mui/material";
import { ClockIcon } from "@mui/x-date-pickers";
import PrintIcon from '@mui/icons-material/Print';
import { ToastContainer, toast } from "react-toastify";
// import  from "./orders.json";
const DriverBodyForDriverpay = ({ driver, accessDisabled }) => {
  const [Driverpaydata, setDriverpaydata] = useState([]);
  const [DriverpayOrderdata, setDriverpayOrderdata] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [driverdate, setdriverdata] = useState({});

  const style = {
    position: 'absolute',
    height: 'max-content',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "90%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const [dataState, setDataState] = useState({
    skip: 0,
    take: 25,
    filter: {
      logic: "and",
      filters: [],
    },
    sort: [
      {
        field: "pay_date_filtered",
        dir: "desc",
      },
    ],
  });

  const [dataStateModal, setdataStateModal] = useState({
    skip: 0,
    take: 25,
    filter: {
      logic: "and",
      filters: [],
    },
    sort: [
      {
        field: "",
        dir: "desc",
      },
    ],
  });
  const [dataResult, setDataResult] = useState(process(Driverpaydata, dataState));
  const [dataResultModal, setDataResultModal] = useState(process(DriverpayOrderdata, dataState));
  const [showModal, setShowModal] = useState(false);


  useEffect(() => {
    (async () => {
      setIsPageLoading(true);
      let response = [];
      response = await getDriverSummaryData(driver.driver_id);
      for (let i = 0; i < response.length; i++) {

        if (response[i].pay_date != null) {
          response[i].pay_date_filtered = DateTime.fromFormat(
            response[i].pay_date,
            'yyyy-MM-dd'
          ).toJSDate();
        }
      }
      setIsPageLoading(false);
      const filteredDataResult = response.filter(item => item.pay_date !== null);
      setDriverpaydata(filteredDataResult);
    })();
  }, [driver])


  const convertdatetime = (date) => {
    if (date) {
      const parsedDate = isNaN(date) ? Date.parse(date) : parseInt(date);
      const formattedDate = DateTime.fromMillis(parsedDate).toUTC().toFormat("MM/dd/yyyy").toString();
      return formattedDate
    } else {
      return "No Data";
    }
  };



 


  const Detaildriverpay = async (pay_date) => {

    setLoading(true);
    let response = await getDriverpayDetails(driver.driver_id, pay_date);
    let updatedDriverPayData = [];

    for (let i = 0; i < response.length; i++) {
      const item = response[i];
      console.log(typeof (item.transfer_on));
      item.payCodeDesc = `${item.pay_code} - ${item.pay_description}`;
      item.origin = `${item.movement.pickup.lm_location_id} - ${item.movement.pickup.lm_location_name}`;
      item.destination = `${item.movement.delivery.lm_location_id} - ${item.movement.pickup.lm_location_name}`;
      item.transaction_date_filtered = DateTime.fromFormat(
        item.transaction_date,
        'yyyy-MM-dd'
      ).toJSDate();
      if (item.transfer_on === null) {
        item.transfer_on = "NO Data";
      } else {
        item.transfer_on_filteres = DateTime.fromMillis(
          parseInt(item.transfer_on)
        )
          .toFormat("MM/dd/yyyy")
          .toString();
      }

      updatedDriverPayData.push(item);
    }
    setLoading(false);
    setDriverpayOrderdata(updatedDriverPayData);
  };


  useEffect(() => {
    setDataResultModal(process(DriverpayOrderdata, dataState))
  }, [DriverpayOrderdata])



  useEffect(() => {
    setDataResult(process(Driverpaydata, dataState))
  }, [Driverpaydata])


  const dataStateChange = (event) => {
    setDataResult(process(Driverpaydata, event.dataState));
    setDataState(event.dataState);
  };

  const rowClickHandler = (dataitem) => {
    setdriverdata(dataitem)
    Detaildriverpay(dataitem?.pay_date)
    setShowModal(true);
  }

  const handleCloseModal = () => {
    console.log("running")
    setShowModal(false);
    setDriverpayOrderdata([])
    setdataStateModal({
      skip: 0,
      take: 25,
      filter: {
        logic: "and",
        filters: [],
      },
      sort: [
        {
          field: "",
          dir: "desc",
        },
      ],
    })

  }


  // all filter and functions for Modal
  const dataStateChangeForNoal = (event) => {
    console.log(event.dataState)
    console.log(DriverpayOrderdata)
    setDataResultModal(process(DriverpayOrderdata, event.dataState));
    setdataStateModal(event.dataState);
  };

  const handlePdfdriverPay = async (date) => {

    try {
      const response = await driverPayPdf(driver.driver_id, date);
      let pdfdata = response.data.bytes
      const byteCharacters = atob(pdfdata);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });

      // Create a URL for the Blob
      const pdfURL = URL.createObjectURL(blob);
      window.open(pdfURL, "_blank");

    } catch (error) {
      toast.error("Something went wrong. Please try again later.", "Error");

      console.error("Error fetching PDF data:", error);
    }
  };

 

 
  return (
    <>
     <ToastContainer position='top-right' theme='colored' />
      {!isPageLoading ?
        (
          <div className="row special_row_flex">
            <div className="col-xl-12">
              <div className="card card_shadow">
                <div className="card-body drivercard">

                  <div className='table-responsive'>
                    <Grid
                      filter={dataState.filter}
                      filterable={true}
                      sort={dataState.sort}
                      sortable={true}
                      pageable={{
                        pageSizes: [25, 50, 100],
                        info: true,
                        previousNext: true,
                        buttonCount: 10
                      }}
                      data={dataResult}
                      resizable={true}
                      skip={dataState.skip}
                      take={dataState.take}
                      onDataStateChange={dataStateChange}
                      onRowClick={(e) => rowClickHandler(e.dataItem)}
                    >
                      <GridColumn
                        field='pay_date_filtered'
                        title='Pay Date'
                        filter='date'
                        format="{0:d}"
                        cell={(e) => {
                          return (
                            <td style={{ padding: '25px' }}>
                              {e.dataItem.pay_date ? convertdatetime(e.dataItem.pay_date) : "No Data"}
                            </td>
                          );
                        }}
                      />
                      <GridColumn
                        field='Paid_Miles'
                        title='Paid Miles'
                        filterable={true}
                        cell={(e) => {
                          return (
                            <td style={{ padding: '25px' }}>
                              {e.dataItem.Paid_Miles ? (e.dataItem.Paid_Miles) : "No Data"}
                            </td>
                          );
                        }}
                      />
                      <GridColumn
                        field='gross_earnings'
                        title='Gross Earnings'
                        filterable={true}
                        cell={(e) => {
                          return (
                            <td style={{ padding: '25px' }}>
                              {e.dataItem.gross_earnings ? `$${parseFloat(e.dataItem.gross_earnings).toFixed(2)}` : "No Data"}
                            </td>
                          );
                        }}
                      />
                      <GridColumn
                        field='order_pay'
                        title='Order Pay'
                        filterable={true}
                        cell={(e) => {
                          return (
                            <td style={{ padding: '25px' }}>
                              {e.dataItem.order_pay ? `$${parseFloat(e.dataItem.order_pay).toFixed(2)}` : "No Data"}
                            </td>
                          );
                        }}
                      />
                      <GridColumn
                        field='other_earnings'
                        title='Other Earnings'
                        filterable={true}

                        cell={(e) => {
                          return (
                            <td style={{ padding: '25px' }}>
                              {e.dataItem.other_earnings ? `$${parseFloat(e.dataItem.other_earnings).toFixed(2)}` : "No Data"}
                            </td>
                          );
                        }}
                      />
                      <GridColumn
                        field='action'
                        title='Action'
                        cell={(e) => {
                          return (
                            <td style={{ padding: '25px' }}>
                              <button type="button" className="btn_blue btn-blue ml_10" onClick={() => handlePdfdriverPay(e.dataItem.pay_date)}>
                                <PrintIcon /> Print
                              </button>
                            </td>
                          );
                        }}
                      />
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="card">
            <ProgressBar animated now={100} />
            <div className='middle loader--text1'> </div>
          </div>
        )}



      {/* Modal for driver pay */}

      <Modal
        open={showModal}
        onClose={() => {
          handleCloseModal();
        }}
        aria-labelledby="modal-modal-title"
        className="settlement_modal"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ display: 'flex', alignItems: 'center' }}>
         
            <div className="modal-title h4" id="contained-modal-title-vcenter">SETTLEMENT</div>

            <IconButton sx={{ ml: 'auto' }}>
              
             
              <CloseIcon onClick={() => handleCloseModal()} />
            </IconButton>
          </Typography>
          <Typography>
            <div className="df-print">
          <button type="button" className="btn_blue btn-blue mr" onClick={() => handlePdfdriverPay(driverdate.pay_date)}>
                <PrintIcon /> Print
              </button>
              </div>
            <div className="settlement_modal_header">
              <div className="settlement_modal_header_inner">
                <div className="col-md-4">

                  <div className="settlement_modal_header_dres">
                    <div className="settlement_modal_header_left_text">Pay Date:</div>
                    <div className="settlement_modal_header_left_text_dres">{driverdate?.pay_date}</div>
                  </div>
                  <div className="settlement_modal_header_dres">
                    <div className="settlement_modal_header_left_text">Paid Miles:</div>
                    <div className="settlement_modal_header_left_text_dres">{driverdate?.Paid_Miles}</div>
                  </div>
                </div>

                <div className="col-md-4">

                  <div className="settlement_modal_header_dres">
                    <div className="settlement_modal_header_left_text">Gross Earnings:</div>
                    <div className="settlement_modal_header_left_text_dres">${parseFloat(driverdate?.gross_earnings).toFixed(2)}</div>
                  </div>
                  <div className="settlement_modal_header_dres">
                    <div className="settlement_modal_header_left_text">Order Pay:</div>
                    <div className="settlement_modal_header_left_text_dres">${parseFloat(driverdate?.order_pay).toFixed(2)}</div>
                  </div>
                </div>

                <div className="col-md-4">

                  <div className="settlement_modal_header_dres">
                    <div className="settlement_modal_header_left_text">Other Earnings:</div>
                    <div className="settlement_modal_header_left_text_dres">${parseFloat(driverdate.other_earnings).toFixed(2)}</div>

                  </div>

                </div>

              </div>


            </div>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div className="kendo_container">
              {
                !loading ?
                  <Grid
                    filter={dataStateModal.filter}
                    filterable={true}
                    sort={dataStateModal.sort}
                    sortable={true}
                    pageable={{
                      pageSizes: [25, 50, 100],
                      info: true,
                      previousNext: true,
                      buttonCount: 10
                    }}
                    data={dataResultModal}
                    resizable={true}
                    skip={dataStateModal.skip}
                    take={dataStateModal.take}
                    onDataStateChange={dataStateChangeForNoal}
                    onRowClick={(e) => { }}
                  >

                    <GridColumn
                      field='transaction_date_filtered'
                      title='Transaction Date'
                      filter='date'
                      format="{0:d}"
                      width="250px"
                      cell={(e) => {
                        return (
                          <td>
                            {e.dataItem.transaction_date ? convertdatetime(e.dataItem.transaction_date) : "No Data"}
                          </td>
                        );
                      }}
                    />

                    <GridColumn
                      field='payCodeDesc'
                      title='Pay Type'
                      width="220px"
                      filterable={true}
                      cell={(e) => {
                        const payCodeDesc = `${e.dataItem.pay_code ? e.dataItem.pay_code : "No Data"} - ${e.dataItem.pay_description ? e.dataItem.pay_description.toUpperCase() : "No Data"}`
                        return (
                          <td>
                            {payCodeDesc}
                          </td>
                        );
                      }}
                    />
                    <GridColumn
                      field='pay_uom'
                      title='UOM'
                      filterable={true}
                      cell={(e) => {
                        return (
                          <td style={{ padding: '25px' }}>
                            {e.dataItem.pay_uom ? e.dataItem.pay_uom : "No Data"}
                          </td>
                        );
                      }}
                    />
                    <GridColumn
                      field='units'
                      title='Units'
                      filterable={true}
                      cell={(e) => {
                        return (
                          <td style={{ padding: '25px' }}>
                            {e.dataItem.units ? e.dataItem.units : "No Data"}
                          </td>
                        );
                      }}
                    />
                    <GridColumn
                      field='pay_rate'
                      title='Rate'
                      filterable={true}

                      cell={(e) => {
                        return (
                          <td style={{ padding: '25px' }}>
                            {e.dataItem.pay_rate ? `$${e.dataItem.pay_rate}` : "No Data"}
                          </td>
                        );
                      }}
                    />
                    <GridColumn
                      field='amount'
                      title='Amount'
                      filterable={true}

                      cell={(e) => {
                        return (
                          <td style={{ padding: '25px' }}>
                            {e.dataItem.amount ? `$${e.dataItem.amount}` : "No Data"}
                          </td>
                        );
                      }}
                    />

                    <GridColumn
                      field='status'
                      title='Status'
                      filterable={true}
                      cell={(e) => {
                        return (
                          <td style={{ padding: '25px' }}>
                            {e.dataItem.status ? e.dataItem.status : "No Data"}
                          </td>
                        );
                      }}
                    />
                    <GridColumn
                      field='transfer_on_filteres'
                      title='Transferred'
                      filter='date'
                      format="{0:d}"
                      cell={(e) => {
                        return (
                          <td style={{ padding: '25px' }}>
                            {e.dataItem.transfer_on_filteres ? e.dataItem.transfer_on_filteres : "No Data"}
                          </td>
                        );
                      }}
                    />
                    <GridColumn
                      field='movement_id'
                      title='Move'
                      filterable={true}
                      cell={(e) => {
                        return (
                          <td style={{ padding: '25px' }}>
                            {e.dataItem.movement_id ? e.dataItem.movement_id : "No Data"}
                          </td>
                        );
                      }}
                    />
                    <GridColumn
                      field='origin'
                      title='Origin'
                      filterable={true}
                      cell={(e) => {
                        return (
                          <td style={{ padding: '25px' }}>
                            {e.dataItem.origin !== 'null - null' ? e.dataItem.origin : "No Data"}
                          </td>
                        );
                      }}
                    />
                    <GridColumn
                      field='destination'
                      title='Destination'
                      filterable={true}
                      cell={(e) => {
                        return (
                          <td style={{ padding: '25px' }}>
                            {e.dataItem.destination !== 'null - null' ? e.dataItem.destination : "No Data"}
                          </td>
                        );
                      }}
                    />
                  </Grid> : <div>
                    <ProgressBar animated now={100} />
                    <div className='middle loader--text1'> </div>
                  </div>
              }
            </div>
          </Typography>
        </Box>
      </Modal>




    </>
  )
}

export default DriverBodyForDriverpay

