// External/NPM Library


// Application Specific
import Role from '../models/roleModel';
import { BaseConfig } from "../baseConfig";
import axios from 'axios';

export const getRole = async (roleId) => {
    const role = new Role();
    let roleObject = []
    try {
        const url = BaseConfig.ApiEndPoint + "/roles/" + roleId;
        const roleApiData = await axios.get(url);
        roleObject = role.parseApiRoleObject(roleApiData.data.data);
    } catch (err) {
        return Promise.resolve("There is a problem on retrieving role data. Please try again!");
    }
    return Promise.resolve(roleObject);
}

export const getAllRole = async () => {
    const role = new Role();
    let roleObject = [];

    try {
        const url = BaseConfig.ApiEndPoint + "/roles";
        const roleApiData = await axios.get(url);
        roleObject = roleApiData.data.data.map(data => role.parseApiRoleObject(data));
    } catch (err) {
        return Promise.reject("There is a problem on retrieving role data. Please try again!");
    }

    return Promise.resolve(roleObject);
}
export const getAllPRole = async () => {
    const role = new Role();
    let roleObject = [];

    try {
        const url = BaseConfig.ApiEndPoint + "/getallproles";
        const roleApiData = await axios.get(url);
        roleObject = roleApiData.data.data
    } catch (err) {
        return Promise.reject("There is a problem on retrieving role data. Please try again!");
    }

    return Promise.resolve(roleObject);
}

export const createRole = async (rl, permissions) => {
    const role = new Role();
    let roleObject = [];
    try {
        const url = BaseConfig.ApiEndPoint + "/createprole";
        const data = {
            "code": rl.toUpperCase(),
            "name": rl,
            // "permissions" : [{ "permission": "a0711f82-33b2-42a5-94be-566f93848559", "isView": false, "isEdit": false}]
            "permissions": "",
            "is_active":true,
            permission_access:permissions
        };
        const roleApiData = await axios.post(url, data);
        
        // roleObject = role.parseApiRoleObject(roleApiData.data.data);
       var roleResponse=roleApiData.data.data
    } catch (err) {

        return Promise.reject("There is a problem on creating role, please validate the data and try again!");
    }

    return Promise.resolve(roleResponse);
}

export const updateRole = async (rlData, perms, isActive) => {

    const role = new Role();
    let roleObject = [];


    try {
        const url = BaseConfig.ApiEndPoint + "/roles/" + rlData.roleId;
        const data = {
            "id": rlData.roleId,
            "code": rlData.roleId,
            "name": rlData.roleName,
            "permissions": perms,
            "isActive": isActive
        };
        const roleApiData = await axios.put(url, data);
        roleObject = role.parseApiRoleObject(roleApiData.data.data);
    } catch (err) {
        return Promise.reject("There is a problem on creating role, please validate the data and try again!");
    }

    return Promise.resolve(roleObject);
}
export const updateRolesAndPermission = async (payload) => {
    const role = new Role();
    let roleObject = [];
    try {
        const url = BaseConfig.ApiEndPoint + "/updaterolespermissionById"
        const roleApiData = await axios.post(url, payload);
        roleObject = roleApiData
    } catch (err) {
        return Promise.reject("There is a problem on creating role, please validate the data and try again!");
    }
    return Promise.resolve(roleObject);
}

export const filterRoles = async (filterData) => {
    const role = new Role();

    let roleObject = []
    // Call API using Await
    const url = BaseConfig.ApiEndPoint + "/filterroles";
    const data = {
        "id": "",
        "name": filterData,
        "permissions": []
    }
    const roleApiData = await axios.post(url, data);
    roleObject = roleApiData.data.data.map(data => role.parseApiRoleObject(data));
    return Promise.resolve(roleObject);
}